import React from "react";
// Customizable Area Start
import { Box, styled } from "@material-ui/core";
// Customizable Area End
import NewDashboardController, { Props } from "./NewDashboardController.web";
import Sidebar from "./Sidebar.web";
import Header from "./Header.web";
import Layout from "../../../components/src/Layout.web";

export default class NewDashboard extends NewDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        
        <Layout menuItem="dashboard" navigation={this.props.navigation} children={undefined}></Layout>
        
      </Box>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const LayoutCommonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "2px",
  boxShadow: "0px 4px 8px 0px #00000008",
  "& #container__header": {
    marginLeft: "120px"
  },
  [theme.breakpoints.down("xs")]: {
    "& #container__header": {
      marginLeft: "0"
    }
  }
}));
// Customizable Area End
