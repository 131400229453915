import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { styled } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "#CBD5E1",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
  checkedIcon: {
    backgroundColor: "#0099FF",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
});

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function CustomRadioButton({
  list,
  name,
  value,
  onRadioChange,
  disabled,
}: {
  list: { id: string; label: string }[];
  name: string;
  value: string;
  onRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}) {
  const FormControlStyled = styled(FormControl)({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "-10px",
      marginRight: "10px",
    },
    "& .MuiRadio-root": {
      padding: "0 10px",
    },
    "& .MuiTypography-root": {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26px",
      letterSpacing: "0em",
    },
  });

  return (
    <FormControlStyled>
      <RadioGroup
        aria-label="radio-group"
        name={name}
        value={value}
        onChange={(e) => onRadioChange(e)}
      >
        {list.map((radio) => (
          <FormControlLabel
            key={radio.id}
            value={radio.id}
            control={<StyledRadio />}
            label={radio.label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControlStyled>
  );
}
