// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  styled,
  InputBase,
  FormGroup,
  Select,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import AddIvrFlowController, {
  Props,
  images,
  configJSON,
} from "./AddIvrFlowController";
import Layout from "../../../components/src/Layout.web";
import { StyledCheckbox } from "../../groups2/src/Agents.web";
// Customizable Area End

export default class AddIvrFlow extends AddIvrFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout menuItem="ivrFlow" navigation={this.props.navigation}>
        <Box display="inline-flex" alignItems="center" gridGap={24}>
          <img src={images.ivrFlows} width={64} height={48} />
          <Typography style={styles.addIvrFlowLabelContainer} component="h2">
            {configJSON.ivrFlow}
          </Typography>
        </Box>
        <FormGroup
          style={{
            padding: "24px 40px",
            borderRadius: "8px",
            background: "#fff",
          }}
        >
          <AddIvrFlowInFormationContainer data-test-id="addIvrFlowInFormationContainer">
            <Box data-test-id="box">
              <InputLabel
                className="InputLabelCustom"
                error={!!this.state.formErrors.flowName}
                htmlFor="flowName"
              >
                {configJSON.flowName}*
              </InputLabel>
              <InputBase
                data-test-id="flowName"
                className="InputBaseCustom"
                id="flowName"
                name="flowName"
                type="text"
                value={this.state.formData.flowName}
                error={!!this.state.formErrors.flowName}
                onChange={this.handleChange}
              />
              {!!this.state.formErrors.flowName && (
                <Typography color="error" data-test-id="name-error">
                  {this.state.formErrors.flowName}
                </Typography>
              )}
            </Box>
            <Box>
              <InputLabel className="InputLabelCustom" htmlFor="selectFlowType">
                {configJSON.selectFlowType}*
              </InputLabel>
              <Select
                data-test-id="selectFlowType"
                className="SelectCustom"
                id="selectFlowType"
                name="selectFlowType"
                value={this.state.formData.selectFlowType}
                onChange={(event) => this.handleChange(event)}
                IconComponent={ExpandMoreRoundedIcon}
                MenuProps={{
                  className: "menuProps",
                }}
              >
                <MenuItem value={"none"} style={styles.menuItem} disabled>
                  {configJSON.flowType}
                </MenuItem>
                {AddIvrFlow.SELECT_FLOW_TYPE.map((mode) => (
                  <MenuItem
                    key={mode.id}
                    value={mode.id}
                    style={styles.menuItem}
                  >
                    {mode.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="checkBox">
              <InputLabel className="InputLabelCustom" htmlFor="custom">
                {configJSON.custom}
              </InputLabel>
              <StyledCheckbox
                data-test-id="custom"
                id="custom"
                name="custom"
                checked={this.state.formData.custom}
                onClick={(event) =>
                  this.handleChange(
                    event as unknown as React.ChangeEvent<{
                      name?: string;
                      value: unknown;
                    }>,
                    true,
                    !this.state.formData.custom
                  )
                }
              />
            </Box>
            <Box className="checkBox">
              <InputLabel className="InputLabelCustom" htmlFor="transfer">
                {configJSON.transfer}
              </InputLabel>
              <StyledCheckbox
                data-test-id="transfer"
                id="transfer"
                name="transfer"
                checked={this.state.formData.transfer}
                onClick={(event) =>
                  this.handleChange(
                    event as unknown as React.ChangeEvent<{
                      name?: string;
                      value: unknown;
                    }>,
                    true,
                    !this.state.formData.transfer
                  )
                }
              />
            </Box>
            <Box style={{ display: "none" }}>
              <InputLabel className="InputLabelCustom" htmlFor="appURL">
                {configJSON.appURL}*
              </InputLabel>
              <InputBase
                data-test-id="appURL"
                className="InputBaseCustom"
                id="appURL"
                name="appURL"
                type="text"
                value={this.state.formData.appURL}
                onChange={this.handleChange}
              />
            </Box>

            <Box>
              <Button
                data-test-id="saveButton"
                type="submit"
                className="ButtonCustom"
                disabled={this.isSaveBtnDisabled()}
                onClick={this.handleSave}
              >
                {configJSON.save}
              </Button>
            </Box>
          </AddIvrFlowInFormationContainer>
        </FormGroup>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  addIvrFlowLabelContainer: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#281D19",
  },
  menuItem: {
    fontSize: "14px",
  },
} as const;

export const AddIvrFlowInFormationContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gridRowGap: "40px",
  maxWidth: "100%",
  marginTop: "9px",
  "& .ButtonCustom": {
    maxHeight: "56px",
    maxWidth: "520px",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    padding: "16px",
    color: "#FFFFFF",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    marginTop: "10px",
    marginBottom: "93px",
    "&:disabled": {
      borderColor: "#CBD5E1",
      backgroundColor: "#E2E8F0",
      color: "#334155",
    },
    "&:hover": {
      backgroundColor: "#0099FF",
      color: "#FFFFFF",
      borderColor: "#0099FF",
    },
  },
  "& .InputLabelCustom": {
    fontWeight: 700,
    color: "#334155",
    fontSize: "18px",
    fontFamily: "Poppins",
    lineHeight: "26px",
  },
  "& .MuiFormLabel-root.Mui-error": {
    color: "#F87171",
  },
  "& .InputBaseCustom": {
    width: "100%",
    maxWidth: "520px",
    marginTop: "4px",
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        color: "#F87171",
        borderColor: "#F87171",
      },
    },
    "& .MuiInputBase-input": {
      height: "34px",
      maxWidth: "520px",
      borderRadius: 8,
      position: "relative",
      backgroundColor: "#FFFFFF",
      borderColor: "#CBD5E1",
      border: "1px solid",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
    },
  },
  "& .SelectCustom": {
    width: "100%",
    maxWidth: "520px",
    marginTop: "4px",
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
    "& .MuiInputBase-input": {
      height: "34px",
      maxWidth: "520px",
      borderRadius: 8,
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#CBD5E1",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-icon": {
      padding: "0 7px",
      right: 1,
      background: "white",
    },
  },
  "& .checkBox": {
    display: "flex",
    marginLeft: "24px",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "110px",
    width: "250px",
    height: "52px",
    "& .MuiSvgIcon-root": {
      height: "40px",
      width: "40px",
    },
  },
  "& .menuProps": {
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    padding: "12px",
    minWidth: "494px",
  },
}));
// Customizable Area End
