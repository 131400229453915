import React, { memo, useCallback, useState } from "react";
import {
  // Customizable Area Start
  Typography,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TableFooter,
  TablePagination,
  styled,
  Tooltip,
  ClickAwayListener,
  InputBase,
  InputAdornment,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";
import ManagerController, {
  Props, configJSON
} from "./ManagerController";

// Customizable Area Start
import Layout from "../../../components/src/Layout.web";
import {  clipboards, group,noResultFound } from "./assets";
import { withStyles } from "@material-ui/styles";
import Loader from "../../../components/src/Loader.web";
import { MoreVert } from "@material-ui/icons";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";
// Customizable Area End

export default class Manager extends ManagerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNoData() {
    return (
      <Box style={webStyle.dataContainer}>
        <Box style={webStyle.noDataInnerContainer}>
          <Image src={clipboards} />
          <Typography style={webStyle.noDataMainText}>{configJSON.noDataMainText}</Typography>
          <Typography style={webStyle.noDataSubText}>{configJSON.noDataSubText}</Typography>
        </Box>
      </Box>
    )
  }
  renderNoSearchData() {
    return (
      <Box style={webStyle.dataContainer}>
        <Box style={webStyle.noDataInnerContainer}>
          <Image src={noResultFound}  style={{marginBottom:0,height:"220px"}}/>
          <Typography style={webStyle.noSearchMainText}>No Result found</Typography>
          <Typography style={webStyle.noSearchSubText}>No Manager matched your Criteria. Try Searching for something else.</Typography>
        </Box>
      </Box>
    )
  }
  renderTable() {
    return (
      <TableComponent 
        data-test-id="customTable"
        getDepartments={this.getdepartments}
        handleDeleteConfirmationDialog={this.handleDeleteConfirmationDialog}
        handleEditManager={this.handleEditManager}
        handlePageChange={this.handlePageChange}
        handleRowsPerPageChange={this.handleRowPerPageChange}
        handleSelect={this.handleSelect}
        managerData={this.state.managerData}
        rowsPerPage={this.state.perPage}
        selectedMode={this.state.selectedMode}
        updateSelectedData={this.updateSelectedData}
      />
    )
  }
  renderview() {
    return (
      <>
        {
          this.state.managerData.managers.data.length === 0 ? this.renderNoDataView() :
            this.renderTable()
        }
      </>
    )
  }
  renderNoDataView(){
    return(
      <>
        {
          this.state.managerData.search? this.renderNoSearchData() :
          this.renderNoData()
        }
      </>
    )
  }

  renderList() {
    return (
      <Layout menuItem="manager" navigation={this.props.navigation}>
        <Box>

          <Box style={{...webStyle.header,background: this.state.selectedMode ? 'white' : 'transparent', padding: '5px' }}>

            <Box display="inline-flex" alignItems="center">
              <img src={group}
                width={64}
                height={48}
              />
              <Typography style={webStyle.headerTitle}>{"List Manager"}</Typography>


            </Box>

            <InputBox
              type="text"
              data-test-id={"searchtxt"}
              placeholder="Search"
              onChange={(event: { target: { value: string; }; }) => this.handleSearch(event.target.value)}
              startAdornment={(
                <InputAdornment position="start" style={{ zIndex: 1, marginRight: 0 }}>
                  <SearchRoundedIcon className="search" />
                </InputAdornment>
              )}
            ></InputBox>

{this.state.selectedMode?
              <Box style={{display:'flex'}}>
            <Box style={{ display: 'flex',fontFamily: "Poppins", cursor: 'pointer', alignItems: 'center' }}>

            <StyledCheckbox
                checked={this.state.selectAll}
                data-test-id={"select_all"}
                onChange={(event,checked)=>this.handleSelectAll(!checked)}
              ></StyledCheckbox>
              <Box data-test-id={"select_all_text"} onClick={()=>this.handleSelectAll(this.state.selectAll)}
                style={{
                  fontWeight: 700,
                  color: 'rgba(100, 116, 139, 1)',
                  fontSize: "16px",
                  cursor:'pointer',
                  paddingLeft:"7px"
                }}
              >
                {!this.state.selectAll?"Select all":"Unselect all"}
              </Box>
            </Box>
            {this.state.selectedId.length>0?
            <Box data-test-id="select_delete" onClick={()=>this.handleBulkDeleteConfirmationDialog()}style={{ display: 'flex', marginLeft:'20px', fontFamily: "Poppins", cursor: 'pointer', alignItems: 'center' }}>

                    <DeleteOutlineRoundedIcon style={{ color: 'rgba(220, 38, 38, 1)', marginRight: '5px' }}></DeleteOutlineRoundedIcon>
                    <Box
                      style={{
                        fontWeight: 700,
                        color: 'rgba(220, 38, 38, 1)',
                        fontSize: "16px",
                      }}
                    >
                      Delete
                    </Box>
                  </Box> : null}


                <Box data-test-id="cancel_all" style={{
                  display: 'flex', fontFamily: "Poppins", cursor: 'pointer',
                  fontWeight: 700, marginLeft: '20px',

                  color: 'rgba(217, 119, 6, 1)',
                  fontSize: "16px",
                }} onClick={() => this.handleSelect()}>
                  Cancel
                </Box>
              </Box> : null}
            <Button
              disabled={this.state.selectedMode}
              data-test-id="add-manager"
              variant="contained"
              style={{...webStyle.headerButton,background:this.state.selectedMode?"rgba(241, 245, 249, 1)":"#0099FF",color:this.state.selectedMode?"rgba(100, 116, 139, 1)":"white"}}
              startIcon={ <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>}
              onClick={this.handleAddAgent}
            >
              {"Add Manager"}
            </Button>
          </Box>

        </Box>
        {this.state.isLoading ? (<Loader loading={this.state.isLoading} />) : this.renderview()}
        <DeleteConfirmationDialog 
          open={this.state.bulkDeleteConfirmation}
          onDone={this.handleBulkDelete}
          onClose={this.handleBulkDeleteConfirmationDialog}
        />
        <DeleteConfirmationDialog 
          open={!!this.state.deleteConfirmationId}
          onDone={() => this.handleDeleteManager(this.state.deleteConfirmationId)}
          onClose={() => this.handleDeleteConfirmationDialog("")}
        />
      </Layout>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      this.renderList()
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const ActionButton = styled(IconButton)(() => ({
  padding: 8,
  "&:before": {
    content: '""',
    position: "absolute",
    zIndex: 1,
    width: "calc(100%)",
    height: "calc(100%)",
    opacity: 0,
    borderRadius: "50%",
  },
  "&:focus": {
    opacity: 1,
    "&:before": {
      opacity: 1,
    },
  },
}))
const StyledCheckbox = styled(Checkbox)({
  padding: "0",
  "&..MuiCheckbox-root":
  {
    border: "1px solid #9e9e9e", // Set border style for the checkbox
    borderRadius: "1px",
  },
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#0099FF",
  },
});
export const InputBox = styled(InputBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center', // Vertically center the content
  '&.Mui-error': {
    '& .MuiInputBase-input': {
      borderColor: '#F87171',
    },
  },
  '& .MuiInputBase-input': {
    borderRadius: '50px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid',
    borderColor: '#CBD5E1',
    fontSize: '16px',
    width: '100%',
    marginRight: '20px',
    marginBottom: '2px',
    paddingLeft: '40px', // Adjusted padding to accommodate the icon
    paddingRight: '10px',
    height: '30px', // Set height to auto
  },
  '& .search': {
    position: 'absolute',
    left: '14px',
    top: '50%',
    color: '#94A3B8',
    transform: 'translateY(-50%)',
    zIndex: 2, // Ensure the icon appears in front
  },
}));

export const Image = styled("img")(({ theme }) => ({
  marginBottom: 30,
  width: 238,
  height: 232,
}))

export const CustomTooltip = withStyles({
  tooltip: {
    width: 182,
    display: "flex",
    flexDirection: "column",
    rowGap: 2,
    marginTop: 20,
    marginRight: -2,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderStyle: "solid",
    borderRadius: 8,
    boxShadow: "0px 4px 8px 0px #00000008",
  },
})(Tooltip)

const webStyle = {
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
  },
  labelInput:
  {
    fontFamily: "Poppins",
    fontWeight: 700,
    // marginTop: 12,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#64748B",
    textAlign: "left"
  },

  headerTitle: {
    fontWeight: 700,
    fontSize: 24,
    marginLeft: 24,
    marginRight: 24,
  },
  headerButton: {
    fontWeight: 700,
    fontSize: 16,

    textTransform: "none",
    color: "white",
    marginLeft: 'auto',
    backgroundColor: "#0099FF",
    // borderColor: "#0099FF",
    borderRadius: 8,
    // marginTop:5,
    padding: "10px 16px",
  },
  tableHeader: {
    fontWeight: 700,
    fontSize: 14,
    maxWidth: "150px"
  },
  tableRow: {
    fontWeight: 400,
    fontSize: 14,
    maxWidth: "150px",
    wordBreak: "break-all",
  },
  dataContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 8,
  },
  noDataInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: 10,
    margin: 20,
  },
  noDataMainText: {
    fontWeight: 700,
    fontSize: 16,
  },
  noSearchSubText: {

    fontSize: 16,
  },
  noSearchMainText: {
    fontWeight: 700,
    fontSize: 25,
  },

  noDataSubText: {
    fontSize: 14,
  },
  tooltipButton: {
    textTransform: "none",
    justifyContent: "start",
    fontSize: 14,
    fontWeight: 400,
  },
  redText: {
    color: "red",
  },
} as const;

interface IManager {
  id: string
  type: string
  attributes: {
    id: number
    name: string
    phone_number: number
    alt_phone_number: number
    extension: string
    email: string
    isselected: boolean
    username: string
    password_digest: string
    departments: [{name: string}]
  }
}

interface ManagerData {
  managers: {
    data: IManager[];
  };
  current_page: number;
  total_pages: number;
  total_managers: number;
  search:boolean;
}

interface TableComponentProps {
  managerData: ManagerData;
  selectedMode: boolean;
  rowsPerPage: number;
  updateSelectedData: (id: string) => void;
  handleEditManager: (manager: IManager) => void;
  handleSelect: () => void;
  handleDeleteConfirmationDialog: (id: string) => void;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  getDepartments: (attributes: IManager["attributes"]) => string;
}

const TableComponent: React.FC<TableComponentProps> = memo((props) => {
  const {
    managerData,
    selectedMode,
    handleEditManager,
    handleSelect,
    handleDeleteConfirmationDialog,
    handlePageChange,
    handleRowsPerPageChange,
    getDepartments,
  } = props;
  const [openManagerId, setOpenManagerId] = useState<string | null>(null);

  const handleToggleAction = useCallback((toggleId: string) => {
    setOpenManagerId((previousOpenId) => (previousOpenId === toggleId ? null : toggleId));
  }, []);

  const handleCloseAction = useCallback(() => {
    setOpenManagerId(null);
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {selectedMode ? <TableCell>{""}</TableCell> : null}
            <TableCell style={webStyle.tableHeader}>{"Manager Name"}</TableCell>
            <TableCell style={webStyle.tableHeader}>{"User Name"}</TableCell>
            <TableCell style={webStyle.tableHeader}>
              {"Email Address"}
            </TableCell>
            <TableCell style={webStyle.tableHeader}>{"Departments"}</TableCell>
            <TableCell style={webStyle.tableHeader}>{"Extentions"}</TableCell>
            <TableCell style={webStyle.tableHeader}>
              {configJSON.actions}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {managerData.managers.data.map((manager, index) => (
            <TableRow key={manager.id}>
              {selectedMode ? (
                <TableCell component="th" scope="row">
                  <StyledCheckbox
                    onClick={() => props.updateSelectedData(manager.id)}
                    data-test-id={`checkbox-delete-${index}`}
                    checked={manager.attributes.isselected}
                  />
                </TableCell>
              ) : null}
              <TableCell component="th" scope="row">
                {manager.attributes.name}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                {manager.attributes.username}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                {manager.attributes.email}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                {manager.attributes.departments &&
                manager.attributes.departments.length
                  ? getDepartments(manager.attributes)
                  : configJSON.dash}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                {manager.attributes.extension}
              </TableCell>
              <TableCell style={webStyle.tableRow}>
                <ClickAwayListener
                  mouseEvent={openManagerId === manager.id ? "onClick" : false}
                  onClickAway={handleCloseAction}
                >
                  <CustomTooltip
                    interactive
                    open={manager.id === openManagerId}
                    placement="left-start"
                    title={
                      <>
                        <Button
                          data-test-id={`edit-manager-${index}`}
                          style={webStyle.tooltipButton}
                          onClick={() => handleEditManager(manager)}
                        >
                          Edit Manager
                        </Button>
                        <Button
                          data-test-id="select-manager"
                          style={webStyle.tooltipButton}
                          onClick={() => handleSelect()}
                        >
                          Select
                        </Button>
                        <Button
                          data-test-id={`delete-manager-${index}`}
                          style={{
                            ...webStyle.tooltipButton,
                            ...webStyle.redText,
                          }}
                          onClick={() =>
                            handleDeleteConfirmationDialog(manager.id)
                          }
                        >
                          Delete
                        </Button>
                      </>
                    }
                  >
                    <ActionButton
                      style={{
                        background:
                          manager.id === openManagerId ? "#0099FF" : "white",
                      }}
                      data-test-id={`action-${index}`}
                      name={manager.id.toString()}
                      size="small"
                      onClick={() => handleToggleAction(manager.id)}
                    >
                      <MoreVert
                        fontSize="inherit"
                        style={{
                          zIndex: 2,
                          color:
                            manager.id === openManagerId
                              ? "white"
                              : "rgba(0, 0, 0, 0.54)",
                        }}
                      />
                    </ActionButton>
                  </CustomTooltip>
                </ClickAwayListener>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={6}
              count={managerData.total_managers}
              rowsPerPage={props.rowsPerPage}
              page={managerData.current_page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
});
// Customizable Area End
