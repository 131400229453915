import React, { Fragment, memo } from "react";
import {
    Box,
    Button,
    Container,
    FormControl,
    Modal,
    Typography,
    styled,
    // Customizable Area Start
    List,
    Grid,
    TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,TablePagination,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    OutlinedInput,
    ClickAwayListener
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SettingScheduleController, { Break, BreakData, Props ,configJSON} from "./SettingScheduleController.web";
import {
    ControlPoint,
    MoreVert,
    Close
} from "@material-ui/icons";
import { holidayIcon, uploadIcon, } from "./assets";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";

import PermIdentity from '@material-ui/icons/PermIdentity';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AddBulkBreaks from "../../importexportdata/src/AddBulkBreaks.web";
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import PauseCircleOutlineRoundedIcon from '@material-ui/icons/PauseCircleOutlineRounded';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Loader from "../../../components/src/Loader";
import { ActionButton, CustomTooltip, StyledCheckbox } from "../../groups2/src/Agents.web";
import Layout from "../../../components/src/Layout.web";
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";

const menuItems = [{ "name": "My Details", icon: <PermIdentity /> },
{ "name": "Company Profile", icon: <BusinessRoundedIcon /> },
{ "name": "Password", icon: <LockOutlinedIcon /> },
{ "name": "Team", icon: <GroupOutlinedIcon /> },
{ "name": "Billing", icon: <CreditCardRoundedIcon /> },
{ "name": "Pause Breaks", icon: <PauseCircleOutlineRoundedIcon /> },
{ "name": "Notifications", icon: <NotificationsNoneRoundedIcon /> }];

// Customizable Area End

export default class SettingSchedule extends SettingScheduleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderLabelModal() {
        if (this.state.modalType === 'addNew') return "Add Break"
        if (this.state.modalType === 'addBulk') return "Add Breaks"
    }
    renderModalContent() {
        if (this.state.modalType === 'addNew') return <Fragment>
            <Typography className="short__description">
                Fill in below details to add a Break in your list
            </Typography>
            <SelectDateStyled>
                <FormControl fullWidth>
                    <Typography className="labelInput" component="h4" gutterBottom>
                        Break Name 
                    </Typography>
                    <OutlinedInput inputProps={{ maxLength: 40 }} placeholder="Enter the break name" id="titleInput" value={this.state.newbreakTitle} onChange={(e) => this.handleTitleField(e)}

                    />
                </FormControl>
                <FormControl fullWidth>
                    <Typography className="labelInput" component="h4" gutterBottom>
                        Break Timer (min) 
                    </Typography>
                    <OutlinedInput  inputProps={{ maxLength: 3 }}onKeyDown={(e)=>this.handleKeyDown(e)}
                    placeholder={"Select Max Time"}
                     id="labelInput" 
                     
                     value={this.state.selectedTime==0?"":this.state.selectedTime} 
                    onChange={(e) => this.handleTimeField(e)
                        
                    }
                        endAdornment={
                            <div>
                                <IconButton style={{ padding: 0 }} onClick={()=>this.handleIncrement()}><ArrowDropUpIcon></ArrowDropUpIcon></IconButton>

                                <IconButton style={{ padding: 0 }}onClick={()=>this.handleDecrement()} >  <ArrowDropDownIcon></ArrowDropDownIcon></IconButton>
                            </div>
                        }

                    />
                </FormControl>
                <FormControl fullWidth>
        <Typography className="labelInput" component="h4">
          Select Days
        </Typography>
        <Box className="calendar__box" id="calendar">
          <Box style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {this.state.days.map((item: any, index: any) => (
              <div key={index} style={{ padding: 5,  }} id={"selectday"+index} onClick={() => this.handleDayClick(item.name)}>
                <Typography  style={{
                  ...webStyle.daysstyle,
                  ...(item.selected ? webStyle.selectedday : {}),
                }}>{item.name}</Typography>
              </div>
            ))}
            <div style={{ padding: 5,  }} id={"selectAll"} onClick={() => this.handleAllDayClick(!this.state.days.every((item: { selected: boolean}) => item.selected))}>
                <Typography  style={{
                  ...webStyle.daysstyle,
                  ...(this.state.days.every((item: { selected: boolean}) => item.selected) ? webStyle.selectedday : {}),
                }}>All</Typography>
            </div>
          </Box>
        </Box>
      </FormControl>
            </SelectDateStyled>
        </Fragment>
        
    }

    renderNoData() {
        return (
          <Box style={webStyle.noDataInnerContainer}>
            <Image src={require("../../groups2/assets/clipboards.svg")}/>
            <Typography style={webStyle.noDataMainText}>{configJSON.noDataBreakMainText}</Typography>
            <Typography style={webStyle.noDataSubText}>{configJSON.noDataBreakSubText}</Typography>
          </Box>
        )
    }
    renderTable() {
        return (
          <TableComponent 
            data-test-id="tableComponent"
            handleCloseAction={this.handleCloseAction}
            handleDeleteConfirmationDialog={this.handleDeleteConfirmationDialog}
            handleEditClick={this.handleEditClick}
            handlePageChangeBreak={this.handlePageChangeBreak}
            handleRowPerPageChangeBreak={this.handleRowPerPageChangeBreak}
            handleSelectOption={this.handleSelectOption}
            handleToggleAction={this.handleToggleAction}
            listBreaks={this.state.listBreaks}
            openId={this.state.openId}
            perPage={this.state.perPage}
            selectedMode={this.state.selectedMode}
            updateSelectedBreakData={this.updateSelectedBreakData}
          />
        )
      }
    renderview()
    {
      return (
        <>
        {
  this.state.listBreaks.breaks.data?.length == 0 ? this.renderNoData():this.renderTable()        } 
        </>
      )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Layout menuItem="setting" navigation={this.props.navigation}>

                    <Container maxWidth="xl" style={{ padding: 0 }}>
                        <Grid container  >

                            {/* seeting menu row  */}


                            <Grid item xs={12} style={{ marginBottom: "18px" }} >
                                <Box className="header__year">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <img src={holidayIcon} alt="icon" width={62} />
                                        <Typography style={webStyle.labelContainer} component="h2">
                                            Settings
                                        </Typography>
                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item xs={12} md={3} style={{ backgroundColor: "white",}}>
                                <List style={{ padding: 0 }}>
                                    {menuItems.map((item, index) => (
                                        <>
                                            <ListItem button key={item.name} style={{ background: item.name == this.state.selectedmenu ? '#E5F5FF' : '', marginTop: 10 }} onClick={() => this.setState({ selectedmenu: item.name })}>
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={item.name} style={{ fontFamily: "Poppins" }} />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    ))}
                                </List>
                            </Grid>
                            

                            <Grid item xs={12} md={9} style={{ backgroundColor: "white",borderLeft:"20px solid #f9f9f9"}}>
                               
                               
                                <div >

                                    <Grid container  >
                                        <Grid item xs={12}>
                                            <div style={{
                                                flexDirection: 'row',
                                                display: 'flex',
                                                boxSizing: 'border-box',
                                                justifyContent: 'space-between',
                                                padding: "16px 16px 0",
                                                alignItems: "center",
                                                borderRadius: "8px",

                                            }}>
                                                <Typography style={{ ...webStyle.labelContainer, margin: 0, color: '#475569', fontSize:window.innerWidth <= 410? 18:20 }} component="h2">
                                                    Pause Break
                                                </Typography>                                                
                                                <ActionDataContainer>
                                                    <Box id="addNew"  className="buttonIcon" onClick={() => this.handleOpenModalAdd('addNew')}>
                                                        <ControlPoint />
                                                        <Typography component="p" className="textButton">
                                                            Add Break
                                                        </Typography>
                                                    </Box>
                                                    <Box id="uploadBulk" className="buttonIcon" onClick={() => this.handleOpenModalAdd('addBulk')}>
                                                        <img src={uploadIcon} alt="iconUpload" width={16} height={16} />
                                                        <Typography component="p" className="textButton">
                                                            Upload Bulk Breaks
                                                        </Typography>
                                                    </Box>
                                                </ActionDataContainer>
                                            </div>
                                            {this.state.selectedMode ? (
                                                    <Box style={{ display: "flex", padding: "16px 16px 0 32px", }}>
                                                        <Box
                                                        style={{
                                                            display: "flex",
                                                            fontFamily: "Poppins",
                                                            cursor: "pointer",
                                                            alignItems: "center",
                                                        }}
                                                        >
                                                        <StyledCheckbox
                                                            data-test-id={"select_all"}
                                                            checked={this.state.selectAll}
                                                            onChange={(event, checked) =>
                                                            this.handleSelectAll(!checked)
                                                            }
                                                        />
                                                        <Box
                                                            data-test-id={"select_all_text"}
                                                            onClick={() => this.handleSelectAll(this.state.selectAll)}
                                                            style={{
                                                            fontWeight: 700,
                                                            color: "rgba(100, 116, 139, 1)",
                                                            fontSize: "16px",
                                                            cursor: "pointer",
                                                            paddingLeft: "7px",
                                                            }}
                                                        >
                                                            {!this.state.selectAll
                                                            ? configJSON.selectAll
                                                            : configJSON.unselectAll}
                                                        </Box>
                                                        </Box>
                                                        {this.state.selectedIds.length > 0 ? (
                                                        <Box
                                                            data-test-id="select_delete"
                                                            onClick={this.handleBulkDeleteConfirmationDialog}
                                                            style={{
                                                            display: "flex",
                                                            marginLeft: "20px",
                                                            fontFamily: "Poppins",
                                                            cursor: "pointer",
                                                            alignItems: "center",
                                                            }}
                                                        >
                                                            <DeleteOutlineRoundedIcon
                                                            style={{
                                                                color: "rgba(220, 38, 38, 1)",
                                                                marginRight: "5px",
                                                            }}
                                                            />
                                                            <Box
                                                            style={{
                                                                fontWeight: 700,
                                                                color: "rgba(220, 38, 38, 1)",
                                                                fontSize: "16px",
                                                            }}
                                                            >
                                                            {configJSON.delete}
                                                            </Box>
                                                        </Box>
                                                        ) : null}
                                                        <Box
                                                        data-test-id="cancel_all"
                                                        style={{
                                                            display: "flex",
                                                            fontFamily: "Poppins",
                                                            cursor: "pointer",
                                                            fontWeight: 700,
                                                            marginLeft: "20px",
                                                            color: "rgba(217, 119, 6, 1)",
                                                            fontSize: "16px",
                                                        }}
                                                        onClick={() => this.handleSelectOption()}
                                                        >
                                                        {configJSON.cancel}
                                                        </Box>
                                                    </Box>
                                                    ) : null}
                                        </Grid>
                                        {}
                                        { this.state.isLoading ? (<Loader loading={this.state.isLoading}/> ) : this.renderview() }
 
                                      
                                    </Grid>
                                    
                                </div>




                            </Grid>

                        </Grid>
                    </Container>
                    {this.state.modalType === 'addBulk' ? <AddBulkBreaks handleCloseModalAdd={() => {this.handleCloseModalAdd() 
                     this.getListBreaks(this.state.token)}} fileUploadSuccessfully={() => {
                                    this.getListBreaks(this.state.token)
                                    this.handleCloseModalAdd()
                                }} /> :
                                <ModalContainer id="modal" open={Boolean(this.state.modalType)} onClose={() => this.handleCloseModalAdd()}>
                                    <Box className="modal__container">
                                        <Box className="modal__header">
                                            <Typography   component="h4">{this.state.editId ? "Edit Break" : this.renderLabelModal()}</Typography>
                                            <Button startIcon={<Close />} id="btnCloseModal" onClick={() => this.handleCloseModalAdd()} />
                                        </Box>
                                        <Box className="modal__content">
                                            {this.renderModalContent()}
                                            <Box className="modal__footer">
                                                <Box className="modal__action">
                                                    <Button id="cancelBtn" onClick={() => this.handleCloseModalAdd()}>
                                                        Cancel
                                                    </Button>
                                                    <Button disabled={this.handleCheckDisbaleAddNewHoliday()} id="doneBtn" onClick={() => this.handleAddBreaks()}>
                                                        Done
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </ModalContainer>}
                    <DeleteConfirmationDialog
                        open={this.state.bulkDeleteConfirmation}
                        onDone={this.handleAllDelete}
                        onClose={this.handleBulkDeleteConfirmationDialog}
                    />
                    <DeleteConfirmationDialog
                        open={!!this.state.deleteConfirmationId}
                        onDone={() =>
                            this.handleDeleteBreak(this.state.deleteConfirmationId)
                        }
                        onClose={() => this.handleDeleteConfirmationDialog(0)}
                    />
                </Layout>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Image = styled("img")(({theme}) => ({
    marginBottom: 30,
    width: 238,
    height: 232,
}))
export const SelectDateStyled = styled(Box)(({ theme }) => ({
    margin: "16px 0",
    "& .labelInput": {
        fontFamily: "Poppins",
        fontWeight: 700,
        marginTop: 12,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#64748B",
        textAlign: "left"
    },
    "& .calendar__box": {
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
        marginTop: "4px",

    }
}))

export const ModalContainer = styled(Modal)(({ theme }) => ({

    "& .modal__content": {
        overflowY: "auto",
        overflowX: "hidden",
        padding: "40px",
        maxHeight: '50vh',
        "& ::-webkit-scrollbar-track": {
            background: "#EAEAEA",
            borderRadius: "10px"
         
        },
        "& ::-webkit-scrollbar-thumb": {
            background: "#ffffff",
            borderRadius: "10px",
            border: "3px solid #EAEAEA"
        },
        "& .short__description": {
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#0F172A",
            marginBottom: "16px"
        },
        "& ::-webkit-scrollbar": {
            width: "10px"
        },
       
        "& ::-webkit-scrollbar-thumb:hover": {
            background: "#efffff"
        },
        
        "& .label": {
            color: "#64748B",
            lineHeight: "22px",
            marginBottom: "4px",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 700,
            
        },
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            height: "56px",
            "& .MuiInputBase-input": {
                padding: "10px 8px"
            },
            "& fieldset": {
                border: "1px solid #CBD5E1",
            },
            "& .MuiInputBase-input::placeholder": {
                
                fontWeight: 400,
                lineHeight: "24px",
                color: "#64748B",
                fontFamily: "Poppins",
                fontSize: "16px",
                opacity: 1
            },
           
        }
    },
    "& .modal__container": {
        width: "100%",
        maxHeight: '90%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "610px",
        position: 'absolute',
        top: '50%',
        backgroundColor: '#FFFFFF',
        borderRadius: "8px",
        
        left: '50%',
        boxShadow: "0px 25px 50px 0px #00000017",
  
        [theme.breakpoints.down(620)]: {
            width: "95%"
        }
    },

    "& .modal__header": {
        padding: "24px 40px",
        borderBottom: "1px solid #E2E8F0",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        boxSizing: "border-box",
        "& h4": {
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
            color: "#000000"
        },
        "& #btnCloseModal": {
            width: "36px",
            height: "24px",
            marginRight: "-26px",
            minWidth: "auto",
            "& .MuiButton-startIcon": {
                margin: "0px"
            },
            "& .MuiButton-label": {
                width: "24px",
                "& .MuiSvgIcon-root": {
                    color: "#334155"
                }
            },
        }
    },
    "& .modal__footer": {
        boxSizing: "border-box",
        width: "calc(100% + 80px)",
        marginTop: "40px",
        borderTop: "1px solid #E2E8F0",
        marginLeft: "-40px",
        "& .modal__action": {
            wdith: "100%",
            display: "flex",
            gap: "16px",
            padding: "24px 40px 0px",
            marginBottom: "-16px",

            "& button": {
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "capitalize",
                height: "56px",
                flex: "0 0 calc(50% - 8px)",
                color: "#0099FF",
                borderRadius: "8px",
                fontFamily: "Poppins",
                fontWeight: 600,
            },

            "& #doneBtn": {
                border: "none",
                background: "#0099FF",
                fontWeight: 700,
                color: "#FFFFFF",
            },
            "& #cancelBtn": {
                border: "1px solid #0099FF"
            },
            "& #doneBtn.Mui-disabled": {
                fontWeight: 700,
                background: "#F1F5F9",
                color: "#64748B"
            }
        }
    }
}))
export const ActionDataContainer = styled(Box)(({ theme }) => ({

    flexDirection: "row-reverse",
    flex: "0 0 calc(100%/12*8 - 24px)",
    display: "flex",
    gap: "24px",
    "& .buttonIcon": {
        borderRadius: "8px",
        height: "44px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& .textButton": {
            fontFamily: "Poppins",
            lineHeight: "17px",
            fontSize: "16px",

        },
        padding: "10px 16px",
        gap: "8px",
        boxSizing: "border-box"
    },
    "& #addNew": {
        color: "#FFFFFF",
        maxWidth: "170px",
        width: "100%",
        backgroundColor: "#0099FF",
        "& .textButton": {
            fontWeight: 700,
            color: "#FFFFFF",
        },
        [theme.breakpoints.down(770)]: {
            justifyContent: "center",
            "& .textButton": {
                display: "none"
            },
        },
        
    },
    "& #uploadBulk": {
        display: "flex",
        height: "44px",
        maxWidth: "236px",
        width: "100%",
        backgroundColor: "transparent",
        border: "1px solid #0099FF",
        color: "#0099FF",
        "& .textButton": {
            fontWeight: 600,
            color: "#0099FF",
        },
        [theme.breakpoints.down(770)]: {
            justifyContent: "center",
            "& .textButton": {
                display: "none"
            },
        }
    },
    [theme.breakpoints.down('sm')]: {
        "& .buttonIcon": {
            fontSize: "12px"
        }
    },
    [theme.breakpoints.down(410)]: {
        gap: "12px",
        fontSize:"18px"
       
    }
}))

const webStyle = {
    daysstyle:
    {
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: "Poppins",
        textAlign: 'center',
        fontWeight: 150, cursor: "pointer",
        color:"#64748B",
        borderRadius: 20,
        width: 40,
        margin: "10px 0px",
        padding: 3
    },
    selectedday:
    {
        color: "#0099FF", border: "1px solid #99D6FF",
              backgroundColor: "#E5F5FF"
    },
    tableHeader: {
        fontWeight: 700,
        fontSize: 14,
      },
      tableRow: {
        fontWeight: 400,
        fontSize: 14,
        wordBreak: "break-all",
      },
    labelContainer:
    {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.005em",
        color: "#281D19 ",
        marginLeft: "24px",
    },
    noDataInnerContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width:"100%",
        textAlign: "center",
        gap: 10,
       
        margin: 50,
      },
      headerTitle: {
        fontWeight: 700,
        fontSize: 24,
        marginLeft: 24,
        marginRight: 24,
      },
      noDataMainText: {
        fontWeight: 700,
        fontSize: 16,
      },
      noDataSubText: {
        fontSize: 14,
      },
      tooltipButton: {
        textTransform: "none",
        justifyContent: "start",
        color: "#0F172A",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "22px",
      },
      redText: {
        color: "#DC2626",
      },

} as const;

interface TableComponentProps {
  listBreaks: BreakData;
  selectedMode: boolean;
  perPage: number;
  openId: string | null;
  updateSelectedBreakData: (id: number) => void;
  handlePageChangeBreak: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleRowPerPageChangeBreak: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditClick: (row: Break) => void;
  handleSelectOption: () => void;
  handleDeleteConfirmationDialog: (id: number) => void;
  handleToggleAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseAction: () => void;
}

const TableComponent: React.FC<TableComponentProps> = memo((props) => {
  const {
    listBreaks,
    selectedMode,
    openId,
    updateSelectedBreakData,
    handlePageChangeBreak,
    handleRowPerPageChangeBreak,
    handleEditClick,
    handleSelectOption,
    handleDeleteConfirmationDialog,
    handleToggleAction,
    handleCloseAction,
    perPage
  } = props;

  return (
    <TableContainer component={Paper} style={{ marginTop: 15, boxShadow: 'none', padding: '0 16px 16px' }}>
      <Table>
        <TableHead>
          <TableRow>
            {selectedMode && <TableCell style={webStyle.tableHeader} />}
            <TableCell style={webStyle.tableHeader}>Break name</TableCell>
            <TableCell style={webStyle.tableHeader}>Days</TableCell>
            <TableCell style={webStyle.tableHeader}>Duration</TableCell>
            <TableCell style={webStyle.tableHeader}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listBreaks.breaks.data.map((breakItem, idx) => (
            <TableRow key={idx} data-test-id="breakTableRow">
              {selectedMode && (
                <TableCell component="th" scope="row">
                  <StyledCheckbox
                    onClick={() => updateSelectedBreakData(breakItem.attributes.id)}
                    data-test-id={`checkbox-delete`}
                    checked={breakItem.isSelected}
                  />
                </TableCell>
              )}
              <TableCell style={webStyle.tableRow}>{breakItem.attributes.name}</TableCell>
              <TableCell style={webStyle.tableRow}>{breakItem.attributes.days.join(', ')}</TableCell>
              <TableCell style={webStyle.tableRow}>{breakItem.attributes.duration}</TableCell>
              <TableCell style={webStyle.tableRow}>
                <ClickAwayListener
                  mouseEvent={openId === breakItem.id ? 'onClick' : false}
                  onClickAway={handleCloseAction}
                >
                  <CustomTooltip
                    interactive
                    open={breakItem.id === openId}
                    placement="left-start"
                    title={
                      <React.Fragment>
                        <Button
                          data-test-id="edit-break"
                          style={webStyle.tooltipButton}
                          onClick={() => handleEditClick(breakItem)}
                        >
                          {configJSON.edit}
                        </Button>
                        <Button
                          data-test-id="select-break"
                          style={webStyle.tooltipButton}
                          onClick={handleSelectOption}
                        >
                          {configJSON.select}
                        </Button>
                        <Button
                          data-test-id="delete-break"
                          style={{ ...webStyle.tooltipButton, ...webStyle.redText }}
                          onClick={() => handleDeleteConfirmationDialog(breakItem.attributes.id)}
                        >
                          {configJSON.delete}
                        </Button>
                      </React.Fragment>
                    }
                  >
                    <ActionButton
                      data-test-id="breakActionButton"
                      name={breakItem.id.toString()}
                      size="small"
                      onClick={handleToggleAction}
                      style={{ background: breakItem.id === openId ? '#0099FF' : 'white' }}
                    >
                      <MoreVert
                        fontSize="medium"
                        style={{ zIndex: 2, color: breakItem.id === openId ? 'white' : '#0F172A' }}
                      />
                    </ActionButton>
                  </CustomTooltip>
                </ClickAwayListener>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={6}
              count={listBreaks.total_breaks}
              rowsPerPage={perPage}
              page={listBreaks.current_page - 1}
              onPageChange={handlePageChangeBreak}
              onRowsPerPageChange={handleRowPerPageChangeBreak}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
});
// Customizable Area End
