import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@material-ui/core";
import { Close, ControlPoint, ExpandMore } from "@material-ui/icons";
import Dropzone, { FileRejection } from "react-dropzone";
import CustomLinearDeterminate from "./CustomLinearDeterminate.web";

interface myProps {
  open: boolean;
  title: string;
  isFileVerifyLoading: boolean;
  verifyUploadedFile: (file: File) => Promise<void>;
  fileVerifyError: string;
  isFileUploaded: boolean;
  handleDelete: () => void;
  handleDownloadBtn: (type: string) => Promise<void>;
  onClose: () => void;
  onDone: (files: File) => Promise<void>;
}

export default function GenericBulkUploadDialog({
  open,
  title,
  isFileVerifyLoading,
  verifyUploadedFile,
  fileVerifyError,
  isFileUploaded,
  handleDelete,
  handleDownloadBtn,
  onClose,
  onDone,
}: myProps) {
  const [files, setFiles] = useState<File[]>();
  const [fileUploadError, setFileUploadError] = useState("");
  const [fileType, setFileType] = useState("csv");

  const onDrop = (files: File[], reject: FileRejection[]) => {
    if (reject && reject.length) {
      if (reject[0].errors[0].code === "too-many-files") {
        setFileUploadError("Please select one file at a time for upload.");
      } else if (reject[0].errors[0].code === "file-invalid-type") {
        setFileUploadError(
          "The selected file does not match the allowed file types of CSV and Xls."
        );
      }
    } else {
      setFiles(files);
      setFileUploadError("");
      verifyUploadedFile(files[0]);
    }
  };

  const handleDeleteFile = () => {
    setFiles(undefined);
    handleDelete();
  };

  return (
    <ModalContainer id="modal" open={open}>
      <Box className="modal__container">
        <Box className="modal__header">
          <Typography component="h4">{title}</Typography>
          <Button startIcon={<Close />} id="btnCloseModal" onClick={onClose} />
        </Box>
        <Box className="modal__content" data-test-id="dropzone">
          <Dropzone
            onDrop={onDrop}
            accept={{
              "text/csv": [".csv"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                ".xlsx",
              ],
            }}
            maxFiles={1}
            disabled={!!(files && files.length)}
          >
            {({ getRootProps, getInputProps }) => (
              <DropzoneContainer>
                <Box className="stepContainer">
                  <Typography component="h4" className="title">
                    Step 1 : Download sample template & Modify your file
                    accordingly
                  </Typography>
                  <Box className="downloadTemplate">
                    <Typography component="p">Download Template as:</Typography>
                    <Select
                      data-test-id="selectTemplate"
                      id="selectTemplate"
                      IconComponent={ExpandMore}
                      value={fileType}
                      onChange={(e) => setFileType(e.target.value as string)}
                    >
                      <MenuItem value="csv">CSV</MenuItem>
                      <MenuItem value="xlsx">XLSX</MenuItem>
                    </Select>
                    <Button
                      data-test-id="btnDownload"
                      className="btnDownload"
                      onClick={() => handleDownloadBtn(fileType)}
                    >
                      <img alt="download" src={require("./download.png")} />
                      Download
                    </Button>
                  </Box>
                </Box>
                <Box className="stepContainer" data-test-id="dropzone">
                  <Typography component="h4" className="title">
                    Step 2 : Upload the file
                  </Typography>
                  <Typography className="upload__text">
                    Upload the edited template by drag & drop or click the
                    button below.
                  </Typography>
                  <Box {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <img
                      src={require("./image_cloud_upload.svg")}
                      width={64}
                      height={64}
                      className="upload__icon"
                    />
                    <Typography component="h3">Upload media</Typography>
                    <Typography component="p">
                      File type supported: csv, xlsx
                    </Typography>
                    <Box className="upload__text__container">
                      <ControlPoint style={{ color: "#0099FF" }} />
                      <Typography className="text__area">
                        Browse file to upload
                      </Typography>
                    </Box>
                  </Box>
                  {fileUploadError && (
                    <div
                      className="upload-error-text"
                      data-test-id="file-upload-error"
                    >
                      {fileUploadError}
                    </div>
                  )}
                  <Box className={`upload__note ${fileVerifyError && "error"}`}>
                    <Typography className="upload__note-text">
                      Please make sure you use same format as per the sample
                      reference file.
                    </Typography>
                  </Box>
                </Box>
                <Box className="bulkHoliday__list">
                  {files &&
                    files.map((file: File, index: number) => (
                      <Box
                        key={index}
                        className="bulkHoliday__item"
                        data-test-id="uploaded-file"
                      >
                        <CustomLinearDeterminate isShow={isFileVerifyLoading} />
                        <img
                          src={require("./project_item_upload.svg")}
                          width={50}
                          height={56}
                        />
                        <Box className="bulkHoliday__detail">
                          <Box className="bulkHoliday__info">
                            <Typography component="p">{file.name}</Typography>
                          </Box>
                          <Box
                            className="bulkHoliday__action"
                            onClick={handleDeleteFile}
                            data-test-id="btnDeleteFile"
                          >
                            <img
                              src={require("./delete_icon.svg")}
                              width={32}
                              height={32}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </DropzoneContainer>
            )}
          </Dropzone>
          <Box className="modal__footer">
            <Box className="modal__action">
              <Button data-test-id="cancelBtn" id="cancelBtn" onClick={onClose}>
                Cancel
              </Button>
              <Button
                data-test-id="doneBtn"
                id="doneBtn"
                disabled={!isFileUploaded}
                onClick={() => onDone((files && files[0]) as File)}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ModalContainer>
  );
}

export const ModalContainer = styled(Modal)(({ theme }) => ({
  "& .modal__container": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "610px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "0px 25px 50px 0px #00000017",
    width: "100%",
    maxHeight: "90%",
    [theme.breakpoints.down(620)]: {
      width: "95%",
    },
  },
  "& .modal__header": {
    padding: "24px 40px",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    boxSizing: "border-box",
    "& h4": {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      color: "#000000",
    },
    "& #btnCloseModal": {
      marginRight: "-26px",
      width: "36px",
      height: "24px",
      minWidth: "auto",
      "& .MuiButton-label": {
        width: "24px",
        "& .MuiSvgIcon-root": {
          color: "#334155",
        },
      },
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
    },
  },
  "& .modal__content": {
    padding: "40px",
    maxHeight: "50vh",
    overflowY: "auto",
    overflowX: "hidden",
    "& ::-webkit-scrollbar": {
      width: "10px",
    },
    "& ::-webkit-scrollbar-track": {
      borderRadius: "10px",
      background: "#EAEAEA",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#ffffff",
      borderRadius: "10px",
      border: "3px solid #EAEAEA",
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#efffff",
    },
    "& .short__description": {
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      marginBottom: "16px",
    },
    "& .label": {
      color: "#64748B",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      marginBottom: "4px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      height: "56px",
      "& .MuiInputBase-input": {
        padding: "10px 8px",
      },
      "& .MuiInputBase-input::placeholder": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#64748B",
        opacity: 1,
      },
      "& fieldset": {
        border: "1px solid #CBD5E1",
      },
    },
  },
  "& .modal__footer": {
    marginTop: "40px",
    borderTop: "1px solid #E2E8F0",
    boxSizing: "border-box",
    width: "calc(100% + 80px)",
    marginLeft: "-40px",
    "& .modal__action": {
      padding: "24px 40px 0px",
      marginBottom: "-16px",
      wdith: "100%",
      display: "flex",
      gap: "16px",
      "& button": {
        textTransform: "capitalize",
        height: "56px",
        flex: "0 0 calc(50% - 8px)",
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0099FF",
        borderRadius: "8px",
      },
      "& #cancelBtn": {
        border: "1px solid #0099FF",
      },
      "& #doneBtn": {
        border: "none",
        fontWeight: 700,
        color: "#FFFFFF",
        background: "#0099FF",
      },
      "& #doneBtn.Mui-disabled": {
        background: "#F1F5F9",
        fontWeight: 700,
        color: "#64748B",
      },
    },
  },
}));

export const DropzoneContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  "& .stepContainer": {
    paddingBottom: "16px",
    borderBottom: "1px solid #CBD5E1",
    "& .title": {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#0F172A",
      width: "calc(100% + 30px)",
      [theme.breakpoints.down(520)]: {
        width: "100%",
      },
    },
    "& .downloadTemplate": {
      marginTop: "16px",
      display: "flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "space-between",
      "& p": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#0F172A",
      },
      "& #selectTemplate.MuiInputBase-input": {
        paddingLeft: "16px",
      },
      "& .MuiInputBase-root": {
        width: "94px",
        height: "44px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
      },
      "& .MuiInput-underline::before,.MuiInput-underline::after": {
        content: "none",
      },
      "& .MuiSelect-icon": {
        color: "#0F172A",
        marginRight: "4px",
      },
      "& #selectTemplate": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#0F172A",
      },
      "& #selectTemplate.MuiSelect-select:focus": {
        background: "transparent",
      },
      "& .MuiList-root .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "transparent",
      },
      "& .btnDownload": {
        background: "#0099FF",
        color: "#FFFFFF",
        borderRadius: "8px",
        height: "44px",
        padding: "10px 54px",
        "& img": {
          marginRight: "8px",
        },
      },
      "& .btnDownload .MuiButton-label": {
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "capitalize",
      },
      [theme.breakpoints.down(650)]: {
        flexWrap: "wrap",
      },
    },
    "& .upload__text": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
      color: "#0F172A",
      marginTop: "16px",
    },
    "& .upload__note": {
      width: "100%",
      boxSizing: "border-box",
      background: "#F8FAFC",
      borderLeftWidth: "4px",
      borderLeftStyle: "solid",
      borderLeftColor: "#1676C0",
      borderRadius: "4px",
      padding: "12px 16px",
      marginTop: "16px",
      "&.error": {
        background: "#FEE2E2",
        borderLeft: "4px solid #F87171",
        "& .upload__note-text": {
          color: "#DC2626",
        },
      },
      "& .upload__note-text": {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#0F172A",
      },
    },
  },
  "& .bulkHoliday__list": {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    "& .bulkHoliday__item": {
      padding: "16px",
      display: "flex",
      alignItems: "center",
      boxShadow: "0px 8px 32px 0px #0000000F",
      borderRadius: "8px",
      position: "relative",
      "& .bulkHoliday__detail": {
        marginLeft: "22px",
        flex: "1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& p": {
          color: "#0F172A",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
        },
        "& span": {
          color: "#64748B",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
    },
    "& .bulkHoliday__action": {
      cursor: "pointer",
    },
  },
  "& .dropzone": {
    margin: "16px 0",
    border: "2px dashed #CBD5E1",
    borderRadius: "16px",
    cursor: "pointer",
    height: "200px",
    maxWidth: "520px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h3": {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#0F172A",
      marginBottom: "4px",
    },
    "& .upload__icon": {
      margin: "25px 0 8px",
    },
    "& p": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      color: "#475569",
      marginBottom: "8px",
    },
    "& .upload__text__container": {
      display: "flex",
      alignItems: "center",
    },
    "& .upload__text__container .text__area": {
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
      color: "#0099FF",
      marginLeft: "8px",
      marginBottom: "0px",
    },
  },
  "& .upload-error-text": {
    color: "#DC2626",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  },
}));
