// Customizable Area Start
import React, { Fragment } from "react";
import AddBulkHolidayController, {
  Props,
} from "./AddBulkHolidayController.web";
import GenericBulkUploadDialog from "../../../components/src/GenericBulkUploadDialog.web";
import CustomBulkUploadSuccessDialog from "../../../components/src/CustomBulkUploadSuccessDialog.web";
// Customizable Area End

export default class AddBulkHoliday extends AddBulkHolidayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Fragment>
        <GenericBulkUploadDialog
          open={!this.state.isDialogOpen}
          title="Add Bulk Holidays"
          isFileVerifyLoading={this.state.isFileVerifyLoading}
          verifyUploadedFile={this.verifyUploadedFile}
          fileVerifyError={this.state.fileVerifyError}
          isFileUploaded={this.state.isFileUploaded}
          handleDelete={this.handleDelete}
          handleDownloadBtn={this.handleDownloadBtn}
          onClose={this.props.handleCloseModalAdd}
          onDone={this.handleDone}
        />
        <CustomBulkUploadSuccessDialog
          open={this.state.isDialogOpen}
          loading={this.state.isDoneLoading}
          onDone={this.props.fileUploadSuccessfully}
          onClose={this.handleDialogClose}
          downloadLink={this.state.downloadLink}
          error={this.state.isDoneError}
        />
      </Fragment>
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
// Customizable Area End
