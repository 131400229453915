import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { KeyboardEvent } from "react";
import { expireTokenHandling } from "../../../components/src/Utilities";
import createRequestMessage from "../../fileattachment/src/Helpers/create-request-message";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
type TTypeModal = 'addNew' | 'addBulk' | false
type SuccessData = {
    message: string;
  };
export type Break = {
    id: string
    type: string
    attributes: {
      id: number
      name: string
      duration: string
      days: string[]
      duration_mins: number
    }
    isSelected: boolean
  }
  
export type BreakData = {
    breaks: {
      data: Break[];
    };
    current_page: number;
    total_pages: number;
    total_breaks: number;
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isLoading: boolean,
    token: string,
    selectedTime: number,
    selectedmenu: any,
    days: any;
    perPage: number;
    listYear: number[],
    currentYear: number,
    modalType: TTypeModal,
    currentDepartment: string | number,
    listDepartment: string[],
    newbreakTitle: string,
    daySelected: Date[] | null,
    listBreaks: BreakData
    formAddHoliday: {
        title: string,
        start_date: string,
        end_date: string
    },
    isOpenSelectYear: boolean
    editId: string;
    openId: string;
    selectedMode: boolean;
    selectAll: boolean;
    selectedIds: number[];
    bulkDeleteConfirmation: boolean;
    deleteConfirmationId: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class SettingScheduleController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getListBreaksId = ''
    getListYearSelectId = ''
    getAddBreakId = ''
    deleteBreakApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];
        this.state = {
            isLoading: true,
            token: "",
            selectedTime: 0,
            selectedmenu: "Pause Breaks",
            listYear: [],
            currentYear: new Date().getFullYear(),
            modalType: false,
            perPage: 10,
            listDepartment: ["Sales", "Dev"],
            currentDepartment: 0,
            newbreakTitle: "",
            days: [{ "name": "Mon", selected: false },
            { "name": "Tue", selected: false },
            { "name": "Wed", selected: false },
            { "name": "Thu", selected: false },
            { "name": "Fri", selected: false },
            { "name": "Sat", selected: false },
            { "name": "Sun", selected: false }
            ],
            daySelected: [],
            listBreaks: {
                breaks: {
                  data: [],
                },
                current_page: 1,
                total_pages: 1,
                total_breaks: 0,
              },
            formAddHoliday: {
                title: "",
                start_date: "",
                end_date: ""
            },
            isOpenSelectYear: false,
            editId: "",
            openId: "",
            selectedMode: false,
            selectAll: false,
            selectedIds: [],
            bulkDeleteConfirmation: false,
            deleteConfirmationId: 0,
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        expireTokenHandling(responseJson)
        if (apiRequestCallId === this.getListBreaksId) {
            
             this.setState(prev => ({ ...prev, listBreaks: { ...responseJson, breaks: {data: responseJson.breaks.data.map((response: Break) => ({ ...response, isSelected: false }))}}}))

             this.setState({ isLoading: false,modalType:false, openId: "",
             selectedMode: false,
             selectAll: false,
             selectedIds: [], })
        }
        if (apiRequestCallId === this.getAddBreakId) {
            this.setState({ isLoading: true })

            this.getListBreaks(this.state.token)
            this.resetFormData()
            this.setState({ modalType: false })
       }
       if (apiRequestCallId === this.deleteBreakApiCallId) {
            this.receiveDeleteBreakAPICall(responseJson)
       }
    }

    async componentDidMount(): Promise<void> {
        if (!window.localStorage.getItem('authToken'))
            this.props.navigation.navigate('LandingPage')
        const token = window.localStorage.getItem("authToken") || ""
        this.setState({ token })
        this.getListBreaks(token)

    }
    handleIncrement = () => {
        
        this.setState(prevState => ({
            selectedTime: Math.min(prevState.selectedTime + 10, 999)
        }));
    };
    handleDecrement = () => {
        this.setState(prevState => ({
            selectedTime: Math.max(prevState.selectedTime - 10, 0)
        }));
    
    };
    handleDayClick = (name: string) => {
        const updatedDays = this.state.days.map((day: { name: string; selected: any; }) =>
            day.name === name ? { ...day, selected: !day.selected } : day
        );
        this.setState({ days: updatedDays })
    };
    handleAllDayClick = (selected: boolean) => {
        const updatedDays = this.state.days.map((day: { name: string; selected: any; }) =>
            ({ ...day, selected: selected })
        );
        this.setState({ days: updatedDays })
    };
    handleOpenModalAdd(typeModal: TTypeModal) {
        this.setState({ modalType: typeModal, openId: "",
        selectedMode: false,
        selectAll: false,
        selectedIds: [], })
    }
    handleCloseModalAdd() {
        this.setState({ modalType: false });
        this.resetFormData();

    }
    handleTitleField(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({
            newbreakTitle: e.target.value, formAddHoliday: {
                ...this.state.formAddHoliday,
                title: e.target.value
            }
        })
    }
    handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        if (e.key === 'ArrowUp') {
            this.handleIncrement()
        } else if (e.key === 'ArrowDown') {
            this.handleDecrement()
        }
    };
    handlePageChangeBreak = async (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        this.getListBreaks(this.state.token, page + 1)
      }
    
      handleRowPerPageChangeBreak = async (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prev => ({
          ...prev,
          perPage: +event.target.value,
        }), () => this.getListBreaks(this.state.token))
        
      };
    handleTimeField(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const value = e.target.value.replace(/\D/g, ''); 
        this.setState({
            selectedTime: value == "" ? 0 : parseInt(e.target.value)
        })

    }
    handleCheckDisbaleAddNewHoliday() {
        const isAnySelected = this.state.days.some((day: { selected: boolean; }) => day.selected === true);
        return !( this.state.newbreakTitle &&  this.state.selectedTime && isAnySelected)
    }

    resetFormData=()=> {
        const updatedDays = this.state.days.map((day: any) => ({ ...day, selected: false }));

        this.setState({ newbreakTitle: "", selectedTime: 0,days:updatedDays, editId: ""  })
    }
   
    getListBreaks=(token: string,page: number = 1)=> {
        const header = {
            "Content-Type": configJSON.applicationJsonApiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getListBreaksId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.breaksApiEndPoint+`?page=${page}&per_page=${this.state.perPage}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleAddBreaks=()=>
    {
        const header = {
            "Content-Type": configJSON.applicationJsonApiContentType,
             "token" :this.state.token,
        };
        const selectedDays = this.state.days.filter((day: { selected: any; }) => day.selected).map((day: { name: any; }) => day.name);
        const raw = JSON.stringify({
            break: {
              name: this.state.newbreakTitle,
              duration: this.state.selectedTime,
              days: selectedDays
            }
          })
        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAddBreakId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.editId ? `${configJSON.breaksApiEndPoint}/${this.state.editId}` : `${configJSON.breaksApiEndPoint}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            raw,
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.editId ? configJSON.putApiMethodType : configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleToggleAction = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const { name } = event.currentTarget;
        if (!this.state.selectedMode) {
            this.setState((prevState) => ({
            openId: prevState.openId === name ? "" : name,
            }));
        }
    };

    handleCloseAction = () => {
        this.setState((prev) => ({ ...prev, openId: "" }));
    };

    handleEditClick = (breakData: Break) => {
        this.setState({ 
            modalType: "addNew", 
            editId: breakData.id,
            newbreakTitle: breakData.attributes.name, 
            selectedTime: breakData.attributes.duration_mins,
            days: this.state.days.map((initialDay: { name: string; selected: boolean}) => ({
                ...initialDay,
                selected: breakData.attributes.days.includes(initialDay.name)
            })) 
        })
    }
    
    handleSelectOption = () => {
        this.setState(
          {
            selectedMode: !this.state.selectedMode,
            selectAll: false,
          },
          () => this.handleSelectAllBreakDelete(false)
        );
      };
    
      handleSelectAll = (checked: boolean) => {
        this.setState({ selectAll: !checked }, () =>
          this.handleSelectAllBreakDelete(this.state.selectAll)
        );
      };
    
      handleAllDelete = () => {
        this.handleDeleteBreak(this.state.selectedIds);
      };
    
      handleSelectAllBreakDelete = (selectAll: boolean) => {
        const newBreakData = this.state.listBreaks.breaks.data.map(
          (breakData) => {
            breakData.isSelected = selectAll;
            return breakData;
          }
        );
        const updatedData = {
          ...this.state.listBreaks,
          breaks: { data: newBreakData },
        };
        this.setState({ listBreaks: updatedData });
        this.handleSelectBreakId();
      };
    
      handleSelectBreakId = () => {
        const filteredData = this.state.listBreaks.breaks.data.filter(
          (breakData) => breakData.isSelected
        );
        const breakIds = filteredData.map((breakData) => breakData.attributes.id);
        this.setState({ selectedIds: breakIds }, () =>
          this.checkAllSelectedBreak()
        );
      };
    
      checkAllSelectedBreak = () => {
        if (
          this.state.selectedIds.length ==
          this.state.listBreaks.breaks.data.length
        ) {
          this.setState({ selectAll: true });
        } else {
          this.setState({ selectAll: false });
        }
      };
    
      updateSelectedBreakData = (breakId: number) => {
        const newBreakData = this.state.listBreaks.breaks.data.map(
          (breakData) => {
            if (breakData.attributes.id === breakId) {
              breakData.isSelected = !breakData.isSelected;
            }
            return breakData;
          }
        );
        const updatedData = {
          ...this.state.listBreaks,
          breaks: { data: newBreakData },
        };
        this.setState({ listBreaks: updatedData });
        this.handleSelectBreakId();
      };

      handleBulkDeleteConfirmationDialog = () => {
        this.setState({
          bulkDeleteConfirmation: !this.state.bulkDeleteConfirmation,
        });
      };
    
      handleDeleteConfirmationDialog = (breakId: number) => {
        this.setState({ deleteConfirmationId: breakId });
      };
    
      handleDeleteBreak = async (deleteId: number | number[]) => {
        this.setState({
          isLoading: true,
          deleteConfirmationId: 0,
          bulkDeleteConfirmation: false,
        });
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        this.deleteBreakApiCallId = requestMessage.messageId
        createRequestMessage({
            requestMessage,
            endPoint: `${configJSON.breaksApiEndPoint}?break_ids=[${deleteId}]`,
            method: configJSON.deleteApiMethodType,
            token: await getStorageData("authToken"),
        })
      };
    
      receiveDeleteBreakAPICall = (responseJson: SuccessData) => {
        if (responseJson && responseJson.message) {
          this.getListBreaks(this.state.token);
        }
      };
    // Customizable Area End
}