export const dashboardIcon = require("../assets/dashboardIcon.svg");
export const dashboardIconSelected = require("../assets/dashboardIconSelected.svg");
export const agentsIcon = require("../assets/agentsIcon.svg");
export const agentsIconHovered = require("../assets/agentsIconHovered.svg");
export const agentTemplateIcon = require("../assets/agentTemplateIcon.svg");
export const businessScheduleIcon = require("../assets/businessScheduleIcon.svg");
export const champaignIcon = require("../assets/champaignIcon.svg");
export const manageDeptIcon = require("../assets/manageDeptIcon.svg");
export const managersIcon = require("../assets/managersIcon.svg");
export const numberManagementIcon = require("../assets/numberManagementIcon.svg");
export const offlineAgentsIcon = require("../assets/offlineAgentsIcon.svg");
export const onlineAgentsIcon = require("../assets/onlineAgentsIcon.svg");
