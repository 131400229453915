import React from 'react';
import {
    Box,
    Button,
    Typography
  } from "@material-ui/core";

// @ts-ignore
import image from "../assets/logoCaliforny.png";
//@ts-ignore
import peopleLogo from "../assets/peopleLogo.png";
const img = require("./assets");

import EmailAccountRegistrationController,{Props} from './EmailAccountRegistrationController';
import { MainContainer, MainDivImg, SecondContainer } from './EmailAccountRegistration.web';



export default class SuccessfulPage extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const otpName = localStorage.getItem('name') || ""
    return(
      <div>
              <MainContainer>
                <SecondContainer>
                  <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <img src={image} style={{marginBottom:'69px', width: "180px"}} />
                      <img style={{marginBottom: '48px'}} src={peopleLogo} alt="" />
                      <Typography style={webStyles.title}>
                      Hi, {otpName}
                      </Typography>
                      <Box style={{textAlign:"center",marginBottom:"16px"}}>
                      <Typography style={webStyles.subTitle}>
                          Great news! You're all signed up.
                      <img src={img.partyImg} style={{width:"20px", marginLeft: "5px"}} />  
                      </Typography>
                      
                      </Box>
                      <Typography style={webStyles.info}>
                          Our team is working on setting up your account, and we can't wait to give you a quick call on your mobile number for confirmation.
                      </Typography>
                  </Box>
                  <Box>
                    <Box style={{display: 'flex',flexDirection:'row',justifyContent: 'center', alignItems: 'center'}}>
                     <Button
                     style={
                       webStyles.btnBack}
                       onClick={this.handleBackToHomePage}
                       data-test-id = "testBackToHomePage"
                       >
                       <img src={img.backBtn} style={{marginRight: "8px"}}/>
                        Back to Home Page</Button>
                   </Box>
                  </Box>
                </SecondContainer>
                <MainDivImg>
                      <img 
                      src={img.groupImage} 
                      style={{ width: "100%" }} />
                      <Box className="text-div">
                          <Typography style={
                            webStyles.bottomTitle}
                            >Manage your clients</Typography>
                          <Typography 
                          style={
                            webStyles.bottomSubTitle}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nostrum, odit laudantium. Facere, officia.</Typography>
                      </Box>
                </MainDivImg>
              </MainContainer>
        </div>
        );
  }
}

const webStyles = {
    background: {
      position: "relative",height: "750px",backgroundColor: "#41B5DA",
      overflow: 'hidden',
      borderRadius: '32px 32px 32px 32px'
    } as React.CSSProperties,
    circle: {
      position: "absolute",top: "50%",left: "50%",
      transform: "translate(-50%, -50%)",
      width: "474px",height: "474px",backgroundColor: "red",
      borderRadius: "373px",
      background: "linear-gradient(180deg, #72EFD5 0%, rgba(217, 217, 217, 0.00) 100%)",
      display: "flex",justifyContent: "center",alignItems: "center",
    } as React.CSSProperties, 
    title: {
      color: "#281D19",fontFamily: "Poppins",fontSize: "16px",fontStyle: "normal",
      fontWeight: 400,lineHeight: "24px",
      marginBottom: '16px'
    },
    subTitle: {
      color: "#281D19",fontFamily: "Poppins",fontSize: "18px",
      fontStyle: "normal",fontWeight: 700,lineHeight: "26px",
    } as React.CSSProperties,
    info: {
      textAlign: 'center',color: "#281D19",
      fontFamily: "Poppins",fontSize: "16px",
      fontStyle: "normal",fontWeight: 400,
      lineHeight: "24px",
      marginBottom: "47px",
    } as React.CSSProperties,
   
    bottomTextBox: {
      width:"500px",position: 'absolute', 
      bottom: '100px',left: "50%",
      textAlign: "center",transform: "translateX(-50%)",
      color: "white",
    } as React.CSSProperties,
    bottomTitle: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: '24px'
    },
    bottomSubTitle: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
    },
    btnBack: {
      fontSize: '16px',
      fontFamily: 'Poppins',
      textTransform: 'capitalize',
      border: 'none',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      background: 'transparent',
      color: '#0099FF',
    } as React.CSSProperties,
}
  
