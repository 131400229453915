// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Button,
  styled,
  IconButton,
  InputBase,
  InputAdornment,
} from "@material-ui/core";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import Click2CallController, {
  Props,
  images,
  configJSON,
} from "./Click2CallController";
import Layout from "../../../components/src/Layout.web";
import CustomSelectMenu from "../../../components/src/CustomSelectMenu.web";
import CustomDialog from "../../../components/src/CustomDialog.web";
// Customizable Area End

export default class Click2Call extends Click2CallController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Layout menuItem="click2Call" navigation={this.props.navigation}>
        <MainContainer data-test-id="click2CallComponent">
          <Box className="header">
            <img src={images.click2call} width={64} height={48} />
            <Typography className="headerTitle">
              {configJSON.click2call}
            </Typography>
          </Box>
          <Box className="container">
            <Box className="liftSide">
              <Box className="formInput mb-24">
                <Typography className="inputLabel">
                  {configJSON.fromNumber}
                </Typography>
                <CustomSelectMenu
                  data-test-id="fromNumberSelect"
                  list={this.state.didNumberList.map((number) => ({
                    id: number.number,
                    label: number.number,
                  }))}
                  value={this.state.fromNumber}
                  name="fromNumber"
                  onChange={this.handleFromValue}
                  disabled={this.state.didNumberList.length < 2}
                />
              </Box>
              <Box className="inputWithAddressBook">
                <Box className="formInput">
                  <Typography className="inputLabel">
                    {configJSON.toNumber}
                  </Typography>
                  <InputBase
                    data-test-id="toNumberInput"
                    className="inputBox"
                    type="text"
                    value={this.state.toNumber}
                    onChange={this.handleToValue}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        style={{ zIndex: 1, marginRight: 0 }}
                      >
                        <CallRoundedIcon className="callIcon" />
                      </InputAdornment>
                    }
                  />
                </Box>
                <IconButton
                  data-test-id="addressBookButton"
                  className="addressBookIcon"
                  onClick={this.onClickToOpenContactDialog}
                >
                  <img src={images.addressBook} />
                </IconButton>
              </Box>
              {!!this.state.errorMessage && (
                <Typography
                  color="error"
                  className="mt-24"
                  data-test-id="error-message"
                >
                  {this.state.errorMessage}
                </Typography>
              )}
              {!!this.state.successMessage && (
                <Typography className="mt-24" data-test-id="success-message">
                  {this.state.successMessage}
                </Typography>
              )}
              <Button
                data-test-id="callButton"
                className="button callButton"
                disabled={
                  !this.state.fromNumber ||
                  !this.state.toNumber ||
                  !!this.state.successMessage
                }
                onClick={this.handleOnCallClick}
              >
                <CallRoundedIcon /> {configJSON.call}
              </Button>
              <Button className="button secondButton">
                <div className="profile">
                  <img src={images.image} />
                  <div className="profileName">
                    <Typography className="nameLabel">
                      {configJSON.kevinKare}
                    </Typography>
                    <Typography className="timeLabel">
                      {configJSON.ringing}
                    </Typography>
                  </div>
                </div>
                <div style={{ margin: "0 auto" }}>
                  <img src={images.callClose} />
                </div>
              </Button>
            </Box>
            <Box className="rightSide">
              <Box className="callInfoBox">
                <img src={images.profile} />
                <Typography className="callInfoText">
                  {configJSON.kevinKare}
                </Typography>
                <Typography className="callInfoText1">
                  {configJSON.number}
                </Typography>
                <Typography className="callInfoText2">
                  {configJSON.ringing}
                </Typography>
                <Box className="callInfoButtons">
                  <IconButton className="micButton">
                    <img src={images.micIcon} />
                  </IconButton>
                  <IconButton className="micDisableButton">
                    <img src={images.micDisable} />
                  </IconButton>
                  <IconButton className="callCloseButton">
                    <img src={images.callClose1} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
          <CustomDialog
            open={this.state.showContactSelectDialog}
            title={configJSON.searchContact}
            doneTitle={configJSON.done}
            content={this.contactDialogContent()}
            showClose={false}
            rootClassName="click2callDialog"
            onClose={this.handleContactSelectDialog}
            disableSave={!this.state.selectedContact}
            onDone={this.handleContactDialogDone}
          />
        </MainContainer>
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled(Box)(() => ({
  gap: "40px",
  display: "flex",
  flexDirection: "column",
  "& .mb-24": {
    marginBottom: "24px",
  },
  "& .mt-24": {
    marginTop: "24px",
  },
  "& .MuiTypography-colorError": {
    color: "#F87171",
  },
  "& .header": {
    gap: "24px",
    display: "inline-flex",
    alignItems: "center",
    "& .headerTitle": {
      color: "#281D19",
      fontSize: "24px",
      fontFamily: "Poppins",
      fontWeight: 700,
      lineHeight: "32px",
    },
  },
  "& .container": {
    gap: "40px",
    display: "flex",
    "& .liftSide": {
      "& .formInput": {
        gap: "16px",
        display: "flex",
        flexDirection: "column",
        width: "265px",
        "& .inputLabel": {
          color: "#0F172A",
          fontSize: "20px",
          fontFamily: "Poppins",
          fontWeight: 600,
          lineHeight: "28px",
        },
        "& .inputBox": {
          "&.MuiInputBase-root": {
            background: "white",
            borderRadius: "4px",
            border: "1px #CBD5E1 solid",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "32px",
            display: "inline-flex",
            height: "48px",
            width: "100%",
            padding: "8px",
          },
          "& .MuiInputBase-input": {
            color: "#1E293B",
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: 400,
            lineHeight: "24px",
          },
          "& .callIcon": {
            color: "#64748B",
            position: "absolute",
            left: "8px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
          },
        },
      },
      "& .inputWithAddressBook": {
        display: "flex",
        alignItems: "flex-end",
        gap: "16px",
        "& .MuiIconButton-root": {
          padding: 0,
        },
      },
      "& .addressBookIcon": {
        boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: "4px",
        background: "#F1F5F9",
      },
      "& .button": {
        borderRadius: "8px",
        width: "265px",
        marginTop: "40px",
        textTransform: "none",
      },
      "& .callButton.MuiButton-root": {
        background: "#34D399",
        padding: "14px",
        border: "1px solid #34D399",
        "& .MuiButton-label": {
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          display: "inline-flex",
          color: "white",
          fontSize: "16px",
          fontFamily: "Poppins",
          fontWeight: 700,
          lineHeight: "24px",
        },
        "&:disabled": {
          background: "#E2E8F0",
          border: "1px solid #CBD5E1",
          "& .MuiButton-label": {
            color: "#334155",
          },
        },
      },
      "& .secondButton.MuiButton-root": {
        display: "none",
        background:
          "linear-gradient(white, white) padding-box, linear-gradient(90deg, #935AF2, #FF4956) border-box",
        border: "1px solid transparent",
        padding: "5px 8px",
        "& .MuiButton-label": {
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
          "& .profile": {
            display: "flex",
            justifyContent: "flex-start",
            gap: "8px",
            "& .profileName": {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            },
            "& .nameLabel": {
              color: "#000000",
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: 700,
              lineHeight: "24px",
            },
            "& .timeLabel": {
              color: "#000000",
              fontSize: "13px",
              fontFamily: "Roboto",
              fontWeight: 400,
              lineHeight: "18px",
            },
          },
        },
      },
    },
    "& .rightSide": {
      display: "none",
      marginTop: "44px",
      minWidth: "686px",
      background: "#FFFFFF",
      border: "1px solid #DAD8D9",
      borderRadius: "24px",
      boxShadow: "0px 2px 8px 0px #00000014",
      "& .callInfoBox": {
        margin: "9px auto",
        padding: "64px 20px 30px 24px",
        width: "fit-content",
        textAlign: "center",
        position: "relative",
        "& .callInfoText": {
          color: "#0F172A",
          fontSize: "30px",
          fontFamily: "Poppins",
          fontWeight: 700,
          lineHeight: "40px",
          marginTop: "12px",
        },
        "& .callInfoText1": {
          color: "#0F172A",
          fontSize: "16px",
          fontFamily: "Poppins",
          fontWeight: 400,
          lineHeight: "24px",
          marginTop: "8px",
        },
        "& .callInfoText2": {
          color: "#FFFFFF",
          fontSize: "14px",
          fontFamily: "Poppins",
          fontWeight: 400,
          lineHeight: "22px",
          background: "#1E293B",
          borderRadius: "20px",
          width: "67px",
          padding: "5px 0px",
          margin: "24px auto 20px",
        },
        "& .callInfoButtons": {
          gap: "12px",
          display: "flex",
          justifyContent: "center",
          "& .MuiIconButton-root": {
            padding: "14px",
            borderRadius: "50%",
          },
          "& .micButton": {
            border: "1px #F1F0F1 solid",
          },
          "& .micDisableButton": {
            display: "none",
            background: "#0099FF",
          },
          "& .callCloseButton": {
            background: "#FF4956",
          },
        },
      },
    },
  },
  "@media (max-width:959px)": {
    "& .container": {
      "& .rightSide": {
        minWidth: "500px !important",
      },
    },
  },
  "@media (max-width:769px)": {
    "& .container": {
      "& .rightSide": {
        minWidth: "300px !important",
      },
    },
  },
  "@media (max-width:600px)": {
    "& .container": {
      flexDirection: "column",
    },
  },
}));
// Customizable Area End
