// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import createRequestMessage from "../../fileattachment/src/Helpers/create-request-message";
import { expireTokenHandling } from "../../../components/src/Utilities";

export const configJSON = require("./config");
export const images = require("./assets");

type SuccessData = {
  messages: string;
  message: string;
};

type ErrorData = {
  errors: {
    email: string;
    password: string;
    confirm_password: string;
  }[];
};

type ResponseData = SuccessData | ErrorData;
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  tokenResetPassword: string;
  enableNewPasswordField: boolean;
  enableConfirmPasswordField: boolean;
  emailId: string;
  emailIdError: string;
  sendLinkSuccess: string;
  newPassword: string;
  newPasswordError: string;
  confirmPassword: string;
  confirmPasswordError: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendLinkApiCallId: string = "";
  saveApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      tokenResetPassword: "",
      enableNewPasswordField: true,
      enableConfirmPasswordField: true,
      emailId: "",
      emailIdError: "",
      sendLinkSuccess: "",
      newPassword: "",
      newPasswordError: "",
      confirmPassword: "",
      confirmPasswordError: "",
      // Customizable Area End
    };
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  handleReceiveFunction = (
    apiRequestCallId: string,
    responseJson: ResponseData
  ) => {
    if (apiRequestCallId === this.sendLinkApiCallId) {
      this.receiveSendLinkAPICall(responseJson);
    }
    if (apiRequestCallId === this.saveApiCallId) {
      this.receiveSaveAPICall(responseJson);
    }
  };

  async componentDidMount() {
    const getToken = await getStorageData("authToken");
    if (getToken) this.goToNavigation("Dashboard");

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    this.setState({ tokenResetPassword: token || "" });
  }

  goToNavigation = (route: string) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMsg);
  };

  getResetOrForgotComponent = (
    forgotComponent: JSX.Element,
    resetComponent: JSX.Element
  ) => {
    return !!this.state.tokenResetPassword ? resetComponent : forgotComponent;
  };

  handleClickShowPassword = (type: keyof S) => {
    this.setState((prevState) => ({
      ...prevState,
      [type]: !this.state[type],
    }));
  };

  handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      emailIdError: "",
      sendLinkSuccess: "",
      newPasswordError: "",
      confirmPasswordError: "",
    }));
  };

  handleButtonDisable = () => {
    return !!this.state.tokenResetPassword
      ? !this.state.newPassword || !this.state.confirmPassword
      : !this.state.emailId || !!this.state.sendLinkSuccess;
  };

  handleSendLinkClick = () => {
    const body = {
      data: {
        attributes: {
          email: this.state.emailId,
        },
      },
    };

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendLinkApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage,
      endPoint: configJSON.sendLinkApiEndPoint,
      method: configJSON.httpPostMethod,
      header: header,
      body: JSON.stringify(body),
    });
  };

  receiveSendLinkAPICall = (responseJson: ResponseData) => {
    const errorResponseJson = responseJson as ErrorData;
    const successResponseJson = responseJson as SuccessData;
    if (errorResponseJson.errors && !!errorResponseJson.errors.length) {
      if (errorResponseJson.errors[0].email) {
        this.setState({ emailIdError: errorResponseJson.errors[0].email });
      }
    }
    if (successResponseJson.messages) {
      this.setState({ sendLinkSuccess: successResponseJson.messages });
    }
  };

  handleSaveClick = () => {
    const body = {
      data: {
        new_password: this.state.newPassword,
        confirm_password: this.state.confirmPassword,
        token: this.state.tokenResetPassword,
      },
    };

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage,
      endPoint: configJSON.saveApiEndPoint,
      method: configJSON.httpPostMethod,
      header: header,
      body: JSON.stringify(body),
    });
  };

  receiveSaveAPICall = (responseJson: ResponseData) => {
    const errorResponseJson = responseJson as ErrorData;
    const successResponseJson = responseJson as SuccessData;
    if (errorResponseJson.errors && !!errorResponseJson.errors.length) {
      if (errorResponseJson.errors[0].password) {
        this.setState({
          newPasswordError: errorResponseJson.errors[0].password,
        });
      }
      if (errorResponseJson.errors[0].confirm_password) {
        this.setState({
          confirmPasswordError: errorResponseJson.errors[0].confirm_password,
        });
      }
    }
    if (successResponseJson.message) {
      this.goToNavigation("EmailAccountLoginBlock");
    }
  };
  // Customizable Area End
}
