Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPIMethod = "POST";
exports.deleteAPIMethod = "DELETE";
exports.putAPIMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "groups2";
exports.labelBodyText = "groups2 Body";

exports.labelAgentMode = "Agent Modes";
exports.shortDescriptionAgentMode = "(Select atleast one or multiple)"

exports.createAgentEndpoint = "bx_block_stickyagent2/agents";
exports.requiredField = "Required field!";
exports.passwordGenericError = "Please check for validations of the password.";
exports.usernameExistError = "The username entered is already in use.";
exports.emailExistError = "The email id entered is already in use.";
exports.emailaddExistError = "The email address entered is already in use.";
exports.mobileExistError = "The Mobile number entered is already in use.";
exports.invalidMobileNumber = "Invalid mobile number format.";
exports.usernameWhitespaceError = "Username must not have whitespace.";
exports.invalidCharacterError = "Contains an invalid character.";
exports.listAgent = "List Agent";
exports.addAgent = "Add Agent";
exports.agentName = "Agent Name";
exports.userName = "User Name";
exports.password = "Password";
exports.departments = "Departments";
exports.extensions = "Extensions";
exports.actions = "Actions";
exports.maskedPassword = "******";
exports.dash = "-";
exports.noDataMainText = "No Data Available";
exports.noDataSubText = "There is no data to show you right now.";
exports.testPass = "Asdf@1";

exports.getAgentsEndpoint = "bx_block_stickyagent2/agents";
exports.createDepartmentEndpoint = "bx_block_account_groups/departments";

exports.getDepartmentEndpoint = "bx_block_account_groups/departments";
 exports.createManagerEndpoint = "bx_block_account_groups/managers";
 exports.getManagersEndpoint = "bx_block_account_groups/managers";
exports.btnExampleTitle = "CLICK ME";
exports.mobilePhoneRegex = /^[\+]?[(]?(\d{3})[)]?[-\s.]?(\d{3})[-\s.]?(\d{4,6})$/;

exports.listDepartment = "Manage Department";
exports.addDepartment = "Add Department";
exports.departmentNoDataMainText = "No Data Available";
exports.departmentNoDataSubText = "There is no data show you right now.";
exports.departmentNameExistError = "The department name entered is already in use.";
exports.noResultFound = "No Result found";
exports.noDepartmentMatched = "No Department matched your Criteria. Try Searching for something else.";
exports.departmentName = "Department Name";
exports.connectionMode = "Connection Mode";
exports.fallbackRules = "FallBack Rules";
exports.days = "Days";
exports.nation = "Nation";
exports.state = "State";
exports.action = "Action";
exports.edit = "Edit";
exports.select = "Select";
exports.delete = "Delete";
exports.selectAll = "Select all";
exports.unselectAll = "Unselect all";
exports.cancel = "Cancel";
// Customizable Area End
