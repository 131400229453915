Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.getListYearApiEndPoint = "bx_block_calendar/holidays/years_with_holidays"
exports.holidayApiEndPoint = "bx_block_calendar/holidays"
exports.breaksApiEndPoint = "bx_block_calendar/breaks"
exports.departmentEndPoint = "bx_block_account_groups/departments"

exports.noDataMainText = "No Data Available";
exports.noDataSubText = "There is no data to show you right now.";
exports.noDataBreakMainText = "No Break Available";
exports.noDataBreakSubText = "There is no break to show you right now.";
exports.edit = "Edit";
exports.select = "Select";
exports.delete = "Delete";
exports.selectAll = "Select all";
exports.unselectAll = "Unselect all";
exports.cancel = "cancel";
// Customizable Area End