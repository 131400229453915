import React from "react";
import {
    Box,
    styled,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CalendarController, { Props } from "./CalendarController.web";
import Calendar from 'react-calendar';
// Customizable Area End

export default class CalendarCustom extends CalendarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <CalendarCustomStyled data-test-id="calendarCustomStyled">
                <Calendar 
                    locale="en-GB"
                    selectRange={true} 
                    prev2Label={null}
                    next2Label={null} 
                    tileClassName={(props) => this.handleTileClass({...props})}
                    onChange={(rangeDate) => this.props.handleSelectRangeDate(rangeDate)} 
                    onClickDay={(day) => this.props.handleSelectDay(day)}
                    value={this.props.daySelected}/>
            </CalendarCustomStyled>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const CalendarCustomStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    "& .react-calendar": {
        border: "none",
        width: "300px"
    },
    "& .react-calendar__navigation": {
        margin: "0 -10px"
    },
    "& .react-calendar__navigation button": {
        fontSize: "24px",
        color: "#334155",
        "& .react-calendar__navigation__label__labelText": {
            fontSize: "14px",
            fontWeight: 700,
            fontFamily: "Inter",
            lineHeight: "20px",
            color: "#0F172A"
        }
    },
    "& .react-calendar__month-view__weekdays__weekday abbr": {
        textDecoration: "none",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#334155",
        textTransform: "capitalize"
    },
    "& .react-calendar__tile--now": {
        background: "none"
    },
    "& .react-calendar__tile--active": {
        background: "#E5F5FF",
        color: "#0099FF",
        borderTop: "1px solid #99D6FF",
        borderBottom: "1px solid #99D6FF"
    },
    "& .react-calendar__tile--rangeStart": {
        borderRadius: "20px 0 0 20px",
        borderLeft: "1px solid #99D6FF"
    },
    "& .react-calendar__tile--rangeEnd": {
        borderRadius: "0 20px 20px 0",
        borderRight: "1px solid #99D6FF"
    },
    "& .react-calendar__tile--rangeBothEnds": {
        border: "1px solid #99D6FF",
        borderRadius: "20px"
    },
    "& .react-calendar__tile--hover": {
        background: "#E5F5FF"
    },
    "& .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus": {
        color: "#0099FF",
        background: "#E5F5FF"
    },
    "& .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus": {
        background: "#E5F5FF"
    },
    "& .react-calendar__month-view__days": {
        rowGap: "6px"
    },
    "& .react-calendar__month-view__days .react-calendar__tile--now:enabled:focus":{
        background: "#E5F5FF"
    },
    "& .react-calendar__tile--active.react-calendar__month-view__days__day--neighboringMonth": {
        color: "#0099FF"
    },
    "& .react-calendar__month-view__days__day--weekend,.react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury": {
        color: "#0F172A"
    },

    "& .react-calendar__tile--active.react-calendar__month-view__days__day--weekend": {
        color: "#0099FF"
    },
    "& .lessThanToday": {
        color: "#868a94"
    }
}))


// Customizable Area End
