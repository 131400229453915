Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "leadmanagement3";
exports.labelBodyText = "leadmanagement3 Body";
exports.putAPiMethod = "PUT";
exports.deleteAPiMethod = "DELETE";

exports.btnExampleTitle = "CLICK ME";

exports.addressBook = "Address Book";
exports.addGroup = "Add Group";
exports.addContact = "Add Contact";
exports.addressNoDataMainText = "No Data Available";
exports.addressNoDataSubText = "There is no data show you right now.";
exports.createGroup = "Create group";
exports.editGroup = "Edit group";
exports.groupName = "Group name";
exports.createGroupApiEndPoint = "bx_block_leadmanagement3/contact_groups";
exports.getGroupApiEndPoint = "bx_block_leadmanagement3/contact_groups";
exports.createContactApiEndPoint = "bx_block_leadmanagement3/contacts";
exports.getContactApiEndPoint = "bx_block_leadmanagement3/contacts";
exports.groupNameExistError = "The group name entered is already in use.";
exports.create = "Create";
exports.name = "Name";
exports.action = "Action";
exports.noResultFound = "No Result found";
exports.noContactGroupMatched = "No Contact group matched your Criteria. Try Searching for something else.";
exports.noContactMatched = "No Contact matched your Criteria. Try Searching for something else.";
exports.edit = "Edit";
exports.delete = "Delete";
exports.save = "Save";
exports.select = "Select";
exports.selectAll = "Select all";
exports.unselectAll = "Unselect all";
exports.cancel = "Cancel";
exports.company = "Company";
exports.contactNumber = "Contact Number";
exports.contact = "Contact";
exports.emailId = "Email-ID";
exports.group = "Group";
exports.chooseGroup = "Choose Group";
exports.invalidEmail = "Invalid email-address!";
exports.invalidNumber = "Invalid contact number format.";
exports.emailInUse = "The email id entered is already in use.";
exports.numberInUse = "The contact number is already in use.";
exports.requiredField = "Required field!";
exports.unassigned = "Unassigned";
exports.editContact = "Edit Contact";
// Customizable Area End