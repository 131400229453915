import React from "react";

// Customizable Area Start
import {
  Box,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  styled,
  Input,
  Button
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
// @ts-ignore
import background from "../assets/image.png"
// @ts-ignore
import image from "../assets/logoCallifony.png";
// @ts-ignore
import flag from '../assets/flag.png';
// @ts-ignore
import india from '../assets/twemoji_flag-india.svg';
// @ts-ignore
import arrowDown from '../assets/back-up-down-svgrepo-com.svg';

const StyledBtnLogin = styled(Button)({
  width: '93%',
  padding: '16px',
  fontSize: '16px',
  fontFamily: 'Poppins',
  border: 'none',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  borderRadius: '8px',
  color: 'white',
  marginBottom: '16px',
  marginTop: "64px",
  "@media (max-width: 1024px)": {
      width: '93%',
  },
  "@media (max-width: 600px)": {
      marginTop: '16px',
      width: '100%',
  },
})

const StyledInput = styled(Input)({
  width: '93%',
  fontFamily: "Poppins",
  padding: '10px 8px',
  border: '1px solid #CBD5E1',
  borderRadius: '8px',
  marginTop: "4px",
  color: "#0F172A",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  "@media (max-width: 1024px)": {
      width: '93%',
  },
  "@media (max-width: 600px)": {
      width: '100%',
  },
})

const StyledLinksBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center', 
  alignItems: 'center',
  gap: '25px', 
})

const StyledFormWrapper = styled(Box)({
  display: 'flex',
  gap:'170px',
  justifyContent: 'space-between',
  alignItems: "center",
  padding: '93px 48px 118px',
  "@media (max-width: 1279px)": {
    gap:'100px',
  },
  "@media (max-width: 1024px)": {
    gap:'45px',
  },
  "@media (max-width: 600px)": {
    padding: '20px 30px 60px 30px',
  },
})

const StyledTitle = styled(Typography)({
  color: "#0A0A0A",
  fontFamily: "Montserrat",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "50px",
  marginBottom: "56px",
  "@media (max-width: 600px)": {
    fontSize: '28px',
  },
})

const StyledHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: "24px 48px",
  gap: "24px",
  "@media (max-width: 600px)": {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: 'center',
  },
})
const StyledMainBox = styled(Box)({
  display:'flex',justifyContent:'center',
  "& .innerbox":
  {
    overflowX: 'hidden',backgroundColor:"white",width:'100%',
    // "@media (min-width: 1500px)": {
    //   width:'95%',
    // },
    // "@media (min-width: 1600px)": {
    //   width:'90%',
    // },
    // "@media (min-width: 1700px)": {
    //   width:'85%',
    // },
    // "@media (min-width: 1800px)": {
    //  width:'80%',
    // },
  }
  
})

const StyledFormBox = styled(Box)({
  width:'45%',
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'space-between',
  "& .forgotPasswordLink": {
    color: "#94A3B8",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    fontWeight: 400,
    cursor: "pointer",
  },
  "@media (max-width: 1024px)": {
    width:'60%',
  },
  "@media (max-width: 600px)": {
    width:'100%',
  },
})

const StyledNubmersBox = styled(Box)({
  display: 'flex',
  gap: '24px',
  "@media (max-width: 1024px)": {
    display: 'none',
  },
  "@media (max-width: 600px)": {
    display: 'none',
  },
})

const StyledImageBox = styled(Box)({
  width: '45%',
  "& .img": {
    width: "100%",
    height: '100%'
  },
  "@media (max-width: 1024px)": {
    width: '35%', 
    "& .img": {
      width: "100%",
      height: '50%'
    },
   },
  "@media (max-width: 600px)": {
    display: 'none', 
   },
})

const webStyles = {
  errMsg: {
    fontSize: '13px',
    fontFamily: "Montserrat",
    color: 'rgb(200,0,0)',
  },
  errMessage: {
    fontSize: '13px',
    fontFamily: "Montserrat",
    color: 'rgb(200,0,0)',
    marginBottom: '16px'
  },
  inputTitle: {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  links: {
    color: "#3C3C3C",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    marginRight: '7px',
    textDecoration: 'none'
  },
  number: {
    color: "#3C3C3C",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: '24px'
  },
  spanSignUpTitle: {
    color: "#0F172A",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: '24px'
  },
  arrow: {
    width: "7px",
    height: '7px'
  },

  disabledBtn: {
    width: '93%',
    padding: '10px 16px',
    fontSize: '16px',
    fontFamily: 'Inter',
    border: 'none',
    fontStyle: 'normal',
    textTransform: 'capitalize',
    fontWeight: 700,
    lineHeight: '24px',
    borderRadius: '8px',
    background: 'gray',
    color: 'white',
    marginBottom: '16px',
  } as React.CSSProperties
};
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
          <StyledMainBox>
        <Box className="innerbox">
        <Box>
          <StyledHeaderBox
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={image} />
            </Box>
            <StyledLinksBox>
              <Box>
                <Link style={webStyles.links} href="">
                  Integrations
                </Link>
                <img style={webStyles.arrow} src={arrowDown} />
              </Box>
              <Box>
                <Link style={webStyles.links} href="">
                  Developer
                </Link>
                <img style={webStyles.arrow} src={arrowDown} />
              </Box>
              <StyledNubmersBox>
                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img src={india} alt="" />
                  <span style={webStyles.number}>+022-555-1231</span>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img src={flag} alt="" />
                  <span style={webStyles.number}>+971-212-3553</span>
                </Box>
              </StyledNubmersBox>
            </StyledLinksBox>
          </StyledHeaderBox>
        </Box>
        <StyledFormWrapper>
          <StyledFormBox>
            <Box>
              <StyledTitle>
                Remote-Ready Contact Center Platform to Support Smarter and <br/><span style={{color:'#F0483E'}}>Sell Faster</span>
              </StyledTitle>
            </Box>
            <Box>
              <Box style={{padding: '0px 0px 24px 0px'}}>
                <Typography style={webStyles.inputTitle}>Username / Email ID</Typography>
                <StyledInput
                  style={this.state.errorMessage ? {border: '1px solid rgb(200,0,0)'}: {border: '1px solid #CBD5E1'}}
                  disableUnderline
                  data-test-id="txtInputEmail"
                  placeholder={"Enter Username / Email ID"}
                  fullWidth={true}
                  value={this.state.email}
                  onChange={(e: any) => {
                    this.setEmail(e.target.value)
                    this.setState({emailError: ""})
                    this.setState({errorMessage: ""})
                  }}
                />
                <Typography style={webStyles.errMsg}>{this.state.emailError}</Typography>
              </Box>
              <Box>
                <Typography style={webStyles.inputTitle}>Password</Typography>
                <StyledInput
                  style={this.state.errorMessage ? {border: '1px solid rgb(200,0,0)'}: {border: '1px solid #CBD5E1'}}
                  disableUnderline
                  data-test-id="txtInputPassword"
                  type={this.state.enablePasswordField ? "password" : "text"}
                  placeholder={"Enter your password"}
                  fullWidth={true}
                  value={this.state.password}
                  onChange={(e: any) => {
                    this.setPassword(e.target.value)
                    this.setState({ passwordError: "" })
                    this.setState({ errorMessage: "" })
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                      >
                        {this.state.enablePasswordField ? (
                          <VisibilityOffOutlinedIcon />
                          ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Typography style={webStyles.errMsg}>{this.state.passwordError}</Typography>
              </Box>
              <Typography style={webStyles.errMessage}>{this.state.errorMessage}</Typography>
              <div style={{ textAlign: "end", width: "93%" }}>
                <Link
                  className="forgotPasswordLink"
                  onClick={() => this.goToForgotPassword()}
                  data-test-id="forgotPasswordLink"
                >
                  Forgot password ?
                </Link>
              </div>
            </Box>
            <Box>
              <Box style={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
                <StyledBtnLogin
                disabled={!this.isValidInputFields()}
                style={this.isValidInputFields() ? {background: '#0099FF',color:'white',textTransform: 'capitalize'}: {background: "#E2E8F0",color:'#334155',textTransform: 'capitalize'}}
                data-test-id="btnEmailLogIn"
                onClick={() => this.doEmailLogin()}
                >Log In</StyledBtnLogin>
                <Typography style={webStyles.spanSignUpTitle}>Don't have an account?<Link href="/signup" style={{marginLeft:'8px',color: "#0099FF", cursor: "pointer", fontWeight: 700}}>Sign Up</Link></Typography>
              </Box>
            </Box>
          </StyledFormBox>
          <StyledImageBox>
            <Box>
              <img className="img" src={background}/>
            </Box>
          </StyledImageBox>
        </StyledFormWrapper>
        </Box>
        </StyledMainBox>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
