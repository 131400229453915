// Customizable Area Start
import React, { Fragment } from "react";

import {
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import Dropzone from "react-dropzone";
import {
  deleteIcon,
  imageCloudUpload,
  imagePlus,
  projectItemUpload,
} from "./assets";
import AddBulkAgentController, {
  Props,
  images,
  configJSON,
} from "./AddBulkAgentController.web";
import { ButtonCustom } from "../../groups2/src/AddAgent.web";
import CustomBulkUploadSuccessDialog from "../../../components/src/CustomBulkUploadSuccessDialog.web";
import CustomLinearDeterminate from "../../../components/src/CustomLinearDeterminate.web";
// Customizable Area End

export default class AddBulkAgent extends AddBulkAgentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Fragment>
        <Dropzone
          onDrop={this.onDrop}
          accept={{
            "text/csv": [".csv"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx"],
          }}
          maxFiles={1}
          disabled={!!this.state.agentFiles.length}
        >
          {({ getRootProps, getInputProps }) => (
            <DropzoneContainer>
              <div className="step-1">
                <div className="step-text">{configJSON.step1}</div>
                <div className="step-1-data">
                  <div className="step-data-text">
                    {configJSON.downloadTemplate}
                  </div>
                  <SelectDiv
                    data-test-id="agent-file-type"
                    disableUnderline
                    id="agent-file-type"
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    value={this.state.agentFileType}
                    onChange={this.handleOnAgentFileTypeChange}
                  >
                    <MenuItem value="csv">{configJSON.csv}</MenuItem>
                    <MenuItem value="xlsx">{configJSON.xlsx}</MenuItem>
                  </SelectDiv>
                  <Button
                    data-test-id="download-btn"
                    className="download-btn"
                    onClick={this.handleDownloadBtn}
                  >
                    <img src={images.download} /> {configJSON.download}
                  </Button>
                </div>
              </div>
              <div className="step-2">
                <div className="step-text" style={{ marginBottom: "16px" }}>
                  {configJSON.step2}
                </div>
                <div className="step-data-text">
                  {configJSON.step2Description}
                </div>
                <Box {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <img
                    src={imageCloudUpload}
                    width={48}
                    height={48}
                    className="upload__icon"
                  />
                  <Typography component="h3">
                    {configJSON.uploadMedia}
                  </Typography>
                  <Typography component="p">
                    {configJSON.fileTypeSupported}
                  </Typography>
                  <Box className="upload__text">
                    <img src={imagePlus} width={20} height={20} />
                    <Typography component="span">
                      {configJSON.browseFileToUpload}
                    </Typography>
                  </Box>
                </Box>
                {this.state.agentFileUploadError && (
                  <div
                    className="upload-error-text"
                    data-test-id="file-upload-error"
                  >
                    {this.state.agentFileUploadError}
                  </div>
                )}
                <div
                  className={`step-2-text ${
                    this.state.agentFileVerifyError && "error"
                  }`}
                >
                  {configJSON.step2Description1}
                </div>
              </div>
              <aside>
                <Box className="projectFilesContainer">
                  <Typography component="h4">
                    {configJSON.projectFile}
                  </Typography>
                </Box>
                <Box className="projectFiles__list">
                  {this.state.agentFiles.map((file: any, index: number) => (
                    <Box
                      key={index}
                      className="projectFiles__item"
                      data-test-id="uploaded-file"
                    >
                      <CustomLinearDeterminate
                        isShow={this.state.isAgentFileVerifyLoading}
                      />
                      <img src={projectItemUpload} width={50} height={56} />
                      <Box className="projectFiles__detail">
                        <Box className="projectFiles__info">
                          <Typography component="p">{file.name}</Typography>
                        </Box>
                        <Box
                          className="projectFiles__action"
                          onClick={() => this.handleDeleteFile(index)}
                          data-test-id="btnDeleteFile"
                        >
                          <img src={deleteIcon} width={32} height={32} />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </aside>
              <Box>
                <ButtonCustom
                  data-test-id="save-btn"
                  style={{ marginTop: "145px" }}
                  disabled={!this.state.isAgentFileUploaded}
                  onClick={this.handleDone}
                >
                  {configJSON.submit}
                </ButtonCustom>
              </Box>
            </DropzoneContainer>
          )}
        </Dropzone>
        <CustomBulkUploadSuccessDialog
          open={this.state.isAgentDialogOpen}
          loading={this.state.isAgentDoneLoading}
          onDone={this.goToAgentScreen}
          onClose={this.handleDialogClose}
          downloadLink={this.state.agentDownloadLink}
          error={this.state.isAgentDoneError}
        />
      </Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const DropzoneContainer = styled(Box)(({ theme }) => ({
  "& .step-1": {
    marginTop: "40px",
    maxWidth: "530px",
    width: "100%",
    "& .step-1-data": {
      padding: "16px 10px 16px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
      "@media (max-width:796px)": {
        flexWrap: "wrap",
      },
      "& .download-btn": {
        borderRadius: "8px",
        background: "#0099FF",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        textTransform: "none",
        padding: "10px 54px",
        "& .MuiButton-label": {
          display: "flex",
          gap: "8px",
        },
      },
    },
  },
  "& .step-text": {
    color: "#0F172A",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  "& .step-data-text": {
    color: "#0F172A",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
  },
  "& .step-2": {
    maxWidth: "520px",
    width: "100%",
    paddingTop: "16px",
    borderTop: "1px solid #CBD5E1",
    marginBottom: "32px",
  },
  "& .dropzone": {
    margin: "16px 0",
    border: "2px dashed #CBD5E1",
    borderRadius: "16px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "25px",
    "& .upload__icon": {
      marginBottom: "14px",
    },
    "& h3": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#0F172A",
      marginBottom: "4px",
    },
    "& p": {
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#475569",
      marginBottom: "14px",
    },
    "& .upload__text": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    "& .upload__text span": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#0099FF",
    },
  },
  "& .step-2-text": {
    color: "#0F172A",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    padding: "12px 16px",
    background: "#F8FAFC",
    borderLeft: "4px solid #1676C0",
    borderRadius: "4px",
    "&.error": {
      color: "#DC2626",
      background: "#FEE2E2",
      borderLeft: "4px solid #F87171",
    },
  },
  "& aside": {
    maxWidth: "520px",
    width: "100%",
  },
  "& .projectFilesContainer": {
    maxWidth: "520px",
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "column-reverse",
    borderBottom: "1px solid #E2E8F0",
    "& h4": {
      marginBottom: "8px",
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "18px",
      color: "#64748B",
      textTransform: "uppercase",
    },
  },
  "& .projectFiles__list": {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    "& .projectFiles__item": {
      padding: "16px",
      display: "flex",
      alignItems: "center",
      boxShadow: "0px 8px 32px 0px #0000000F",
      borderRadius: "8px",
      position: "relative",
      "& .projectFiles__detail": {
        marginLeft: "22px",
        flex: "1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& p": {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#0F172A",
        },
        "& span": {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#64748B",
        },
      },
    },
    "& .projectFiles__action": {
      cursor: "pointer",
    },
  },
  "& .upload-error-text": {
    color: "#DC2626",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "16px",
  },
}));

const SelectDiv = styled(Select)({
  padding: "10px 8px 10px 20px",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  color: "#0F172A",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "22px",
  width: "94px",
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
  "& ..MuiSelect-icon": {
    color: "#0F172A",
  },
  "& .MuiInputBase-input": {
    padding: "0",
  },
});

// Customizable Area End
