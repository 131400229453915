// Customizable Area Start
import { FileRejection } from "react-dropzone";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { expireTokenHandling } from "../../../components/src/Utilities";

const { baseURL } = require("../../../framework/src/config");

export const configJSON = require("./config");
export const images = require("./assets");
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  type?: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  agentFiles: File[];
  agentFileType: string;
  agentFileUploadError: string;
  isAgentFileVerifyLoading: boolean;
  agentFileVerifyError: string;
  isAgentFileUploaded: boolean;
  isAgentDialogOpen: boolean;
  isAgentDoneLoading: boolean;
  agentDownloadLink: string;
  isAgentDoneError: boolean;
  apiEndPointWithType: {
    downloadFileApiEndPoint: string;
    verifyFileApiEndPoint: string;
    bulkUploadApiEndPoint: string;
    redirectionScreen: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddBulkAgentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      agentFiles: [],
      agentFileType: "csv",
      agentFileUploadError: "",
      isAgentFileVerifyLoading: false,
      agentFileVerifyError: "",
      isAgentFileUploaded: false,
      isAgentDialogOpen: false,
      isAgentDoneLoading: false,
      agentDownloadLink: "",
      isAgentDoneError: false,
      apiEndPointWithType: {
        downloadFileApiEndPoint: configJSON.agentDownloadFileApiEndPoint,
        verifyFileApiEndPoint: configJSON.agentVerifyFileApiEndPoint,
        bulkUploadApiEndPoint: configJSON.agentBulkUploadApiEndPoint,
        redirectionScreen: "Agents",
      },
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      expireTokenHandling(responseJson);
      if (apiRequestCallId && responseJson) {
        this.handleAgentReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  handleAgentAPICall = async (apiEndPoint: string) => {
    const agentData = new FormData();
    agentData.append("file", this.state.agentFiles[0]);

    const headers = {
      Token: await getStorageData("authToken"),
    };

    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      agentData
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postDataMethod
    );

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleAgentReceiveFunction = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.agentVerifyFileApiCallId) {
      this.receiveVerifyFileAPICall(responseJson);
    } else if (apiRequestCallId === this.bulkAgentUploadApiCallId) {
      this.receiveBulkUploadAPICall(responseJson);
    }
  };

  async componentDidMount() {
    switch (this.props.type) {
      case "Manager":
        this.setState({
          apiEndPointWithType: {
            downloadFileApiEndPoint: configJSON.managerDownloadFileApiEndPoint,
            verifyFileApiEndPoint: configJSON.managerVerifyFileApiEndPoint,
            bulkUploadApiEndPoint: configJSON.managerBulkUploadApiEndPoint,
            redirectionScreen: "Manager",
          },
        });
        break;
      case "Contact":
        this.setState({
          apiEndPointWithType: {
            downloadFileApiEndPoint: configJSON.contactDownloadFileApiEndPoint,
            verifyFileApiEndPoint: configJSON.contactVerifyFileApiEndPoint,
            bulkUploadApiEndPoint: configJSON.contactBulkUploadApiEndPoint,
            redirectionScreen: "AddressBook",
          },
        });
        break;
      default:
        this.setState({
          apiEndPointWithType: {
            downloadFileApiEndPoint: configJSON.agentDownloadFileApiEndPoint,
            verifyFileApiEndPoint: configJSON.agentVerifyFileApiEndPoint,
            bulkUploadApiEndPoint: configJSON.agentBulkUploadApiEndPoint,
            redirectionScreen: "Agents",
          },
        });
        break;
    }
  }

  agentVerifyFileApiCallId: string = "";
  bulkAgentUploadApiCallId: string = "";

  handleOnAgentFileTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown | string }>
  ) => {
    this.setState({
      agentFileType: event.target.value as string,
    });
  };

  handleDownloadBtn = async () => {
    const userId = await getStorageData("userId");
    window.open(
      `${baseURL}/${this.state.apiEndPointWithType.downloadFileApiEndPoint}${this.state.agentFileType}&account_id=${userId}`
    );
  };

  onDrop = (files: File[], reject: FileRejection[]) => {
    if (reject && reject.length) {
      if (reject[0].errors[0].code === "too-many-files") {
        this.setState({
          agentFileUploadError: configJSON.moreFileError,
        });
      } else if (reject[0].errors[0].code === "file-invalid-type") {
        this.setState({
          agentFileUploadError: configJSON.invalidFileTypeError,
        });
      }
    } else {
      this.setState(
        {
          agentFiles: files,
          agentFileUploadError: "",
          isAgentFileVerifyLoading: true,
        },
        () => this.verifyUploadedFile()
      );
    }
  };

  verifyUploadedFile = async () => {
    this.agentVerifyFileApiCallId = await this.handleAgentAPICall(
      this.state.apiEndPointWithType.verifyFileApiEndPoint
    );
  };

  receiveVerifyFileAPICall = (responseJson: any) => {
    this.setState({ isAgentFileVerifyLoading: false });
    if (responseJson.error) {
      this.setState({ agentFileVerifyError: responseJson.error });
    } else if (responseJson.message) {
      this.setState({ isAgentFileUploaded: true });
    }
  };

  handleDeleteFile = (fileId: number) => {
    this.state.agentFiles.splice(fileId, 1);
    this.setState({
      agentFiles: this.state.agentFiles,
      agentFileVerifyError: "",
      isAgentFileUploaded: false,
    });
  };

  handleDone = async () => {
    this.setState({
      isAgentDoneLoading: true,
      isAgentDialogOpen: true,
      isAgentDoneError: false,
    });
    this.bulkAgentUploadApiCallId = await this.handleAgentAPICall(
      this.state.apiEndPointWithType.bulkUploadApiEndPoint
    );
  };

  handleDialogClose = () => {
    this.setState({
      isAgentDialogOpen: false,
    });
  };

  receiveBulkUploadAPICall = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        isAgentDoneLoading: false,
        agentDownloadLink: responseJson.report_download_link,
      });
    } else {
      this.setState({ isAgentDoneLoading: false, isAgentDoneError: true });
    }
  };

  goToAgentScreen = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      this.state.apiEndPointWithType.redirectionScreen
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  // Customizable Area End
}
