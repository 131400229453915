import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Link,
  Input,
  Typography,styled, Select, MenuItem, Checkbox
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
// @ts-ignore
import image from "../assets/logoCaliforny.png";

export const images = require("./assets");
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
  configJSON,
} from "./EmailAccountRegistrationController";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
     
      <ThemeProvider theme={theme}>
        <div >
          <MainContainer>
            <SecondContainer>
              <Box style={{ marginBottom: '30px', textAlign: "center" }}>
                <img src={image} style={{ marginBottom: '30px', width: "180px" }}/>
                <Typography style={webStyles.title}>
                  Sign up
                </Typography>
                <Typography style={webStyles.subTitle}>
                  Let's get started with your 30 days free trail
                </Typography>
              </Box>
              <InputMainBox>
                <Box style={{ padding: '0px 0px 24px 0px' }}>
                  <Typography style={webStyles.inputTitle}>Contact Name*</Typography>
                  <InputDiv
                    disableUnderline
                    data-test-id="testName"
                    placeholder={"Enter Contact Name"}
                    fullWidth={true}
                    value={this.state.signUpForm.name}
                    name="name"
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                  />
                  {this.state.signUpFormTouched.name && this.state.signUpFormError.name && <Typography style={webStyles.error}>{this.state.signUpFormError.name}</Typography>}
                </Box>
                <Box style={{ padding: '0px 0px 24px 0px' }}>
                  <Typography style={webStyles.inputTitle}>Company Name*</Typography>
                  <InputDiv
                    disableUnderline
                    data-test-id="testCompanyName"
                    placeholder={"Enter Company Name"}
                    fullWidth={true}
                    value={this.state.signUpForm.companyName}
                    name="companyName"
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                  />
                  {this.state.signUpFormTouched.companyName && this.state.signUpFormError.companyName && <Typography style={webStyles.error}>{this.state.signUpFormError.companyName}</Typography>}
                </Box>
                <Box style={{ padding: '0px 0px 24px 0px' }}>
                  <Typography style={webStyles.inputTitle}>Mobile Number</Typography>
                  <div style={{display: "flex", gap: "12px"}}>
                    <SelectDiv 
                      disableUnderline
                      id="country-code"
                      defaultValue="IN"
                      value={this.state.signUpForm.countryCode}
                      onChange={this.handleCountryCodeChange}
                      name="country-code"
                    >
                      {getCountries().filter(country => ["IN", "AE"].includes(country)).map((country) => (
                        <MenuItem key={country} value={country}>
                         <img style={{ width: "20px", marginRight: "5px" }} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`} /> {country} +{getCountryCallingCode(country)}
                        </MenuItem>
                      ))}
                    </SelectDiv>
                    <InputDiv
                      disableUnderline
                      data-test-id="testMobile"
                      placeholder={"Please enter mobile number"}
                      fullWidth={true}
                      value={this.state.signUpForm.mobileNumber}
                      name="mobileNumber"
                      onChange={this.handleInputChange}
                      type="text"
                      onBlur={this.handleBlur}
                    />
                  </div>
                  {this.state.mobileError && <Typography style={webStyles.error}>{this.state.mobileErrorMessage}</Typography>}
                </Box>
                <Box
                style={{padding: '0px 0px 24px 0px'}}
                >
                  <Typography style={webStyles.inputTitle}>Company Email*</Typography>
                  <InputDiv
                    disableUnderline
                    data-test-id="testEmail"
                    placeholder={"Enter your Company Address"}
                    fullWidth={true}
                    value={this.state.signUpForm.email}
                    name="email"
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                  />
                  {this.state.signUpFormTouched.email && this.state.signUpFormError.email && <Typography style={webStyles.error}>{this.state.signUpFormError.email}</Typography>}
                  {this.state.emailError && <Typography style={webStyles.error}>{this.state.emailErrorMessage}</Typography>}
                </Box>
                <Box style={{display: 'flex',alignItems: 'center', gap: "8px"}}>
                <StyledCheckbox
                    data-test-id={"btnAgree"}
                    onClick={() =>
                      this.setAgree(!this.state.checkedAgree)
                    }
                    checked={this.state.checkedAgree}
                  />
                <StyledCheckboxTitle>{configJSON.checkAgreement}</StyledCheckboxTitle>
              </Box>
                </InputMainBox>
              <Box>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <CustumButton 
                  onClick={this.handleNextClick}  data-test-id="testNextBtn" disabled={!(this.state.signUpForm.companyName && this.state.signUpForm.email && this.state.signUpForm.name && this.state.checkedAgree)}>Next</CustumButton>
                  <Typography style={webStyles.spanSignUpTitle}>Already have an account?<Link style={{ marginLeft: '8px', cursor:"pointer", fontWeight: 700, color: "#0099FF" }} onClick={this.handleLogin} data-test-id="testLoginBtn">Log In</Link></Typography>
                </Box>
              </Box>
            </SecondContainer>
            <MainDivImg>
              <img 
              src={images.groupImage} 
              style={{ width: "100%" }} />
              <Box 
                className="text-div">
                <Typography 
                style={webStyles.bottomTitle}>Manage your clients</Typography>
                <Typography 
                style={webStyles.bottomSubTitle}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</Typography>
              </Box>
            </MainDivImg>
          </MainContainer>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
export const CustumButton =  styled(Button)({
  width: "100%",
  marginTop: "32px",
  padding: '16px',
  fontSize: '16px',
  fontFamily: 'Poppins',
  textTransform: 'capitalize',
  border: 'none',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  borderRadius: '8px',
  background: '#0099FF',
  color: 'white',
  marginBottom: '16px',
  "&:hover": {
    background: '#0099FF',
  },
  "&:disabled": {
    background: "#E2E8F0",
    color:'#334155'
  }
})
export const SecondContainer =  styled(Box)({
  width: "35%",
  padding: "57px 94px 104px 120px",
  "@media(max-width:959px)": {
    width: "100%",
  },
  "@media(max-width:600px)": {
    padding: "30px 45px",
  },
})
export const MainContainer = styled(Box)({
display: 'flex', justifyContent: 'space-between',
backgroundColor: "white",
alignItems: "center",
  "@media(max-width:600px)": {
    justifyContent: "center",
  },
})

const InputMainBox = styled(Box)({
  
})
const InputDiv = styled(Input)({
  padding: '10px 8px',
  border: '1px solid #CBD5E1',
  borderRadius: '8px',
  color: "#0F172A",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "24px",
});

const SelectDiv = styled(Select)({
  padding: '0px 8px',
  border: '1px solid #CBD5E1',
  borderRadius: '8px',
  color: "#0F172A",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "24px",
  width: "140px",
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

export const MainDivImg = styled(Box)({
  width :"45%",
  padding: "32px",
  position: "relative",
  "& .text-div": {
    width: "70%",
    position: 'absolute',
    bottom: '18%',
    left: "50%",
    textAlign: "center",
    transform: "translateX(-50%)",
    color: "white",
  },
  "@media(max-width:1159px)": {
    "& .text-div": {
      bottom: '10%',
    }
  },
  "@media(max-width:959px)": {
    display: "none"
  },
});

const webStyles = {
  circle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "474px",
    height: "474px",
    backgroundColor: "red",
    borderRadius: "373px",
    background: "linear-gradient(180deg, #72EFD5 0%, rgba(217, 217, 217, 0.00) 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  title: {
    color: "#281D19",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: '16px'
  },
  subTitle: {
    color: "#281D19",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  inputTitle: {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    marginBottom: "4px",
  },
  links: {
    color: "black",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginRight: '3px',
    textDecoration: 'none'
  },
  bottomTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: '24px'
  },
  bottomSubTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  checkboxTitle: {
    color: '#0F172A',fontFamily: "Inter",
    fontSize: "14px",fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  number: {
    color: "black",fontFamily: "Inter",fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 'normal'
  },
  spanSignUpTitle: {
    color: "#0F172A",fontFamily: "Poppins",fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    textWrap: "nowrap",
  },
  arrow: {
    width: "7px",height: '7px'
  },
  btnLogin: {
    width: '360px',padding: '10px 16px',
    fontSize: '16px',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    borderRadius: '8px',
    background: 'linear-gradient(224deg, #445DA7 6.85%, #1DBADF 107.31%)',
    color: 'white',
    marginBottom: '16px'
  } as React.CSSProperties,
  error: {
    fontSize: "0.9em",
    color: "red",
    fontWeight:400,
  },
};

const StyledCheckbox = styled(Checkbox)({
  padding: "0",
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#0099FF",
  },
});

const StyledCheckboxTitle = styled(Typography)({
  color: '#0F172A',
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  "@media (max-width: 600px)": {
    fontSize: '12px',
  },
})




