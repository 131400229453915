import React from "react";

// Customizable Area Start
import {
  Box,
  Link,
  Typography
} from "@material-ui/core";

// @ts-ignore
import image from "../assets/logoCaliforny.png";
//@ts-ignore
import OtpInput from 'react-otp-input';
const img = require("./assets");
import { CustumButton, MainContainer, MainDivImg, SecondContainer } from "./EmailAccountRegistration.web";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";


export default class OTPVerificationScreen extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const boxColor = this.state.otpError ? "1px solid #F87171" :  "1px solid #CBD5E1"
    // Customizable Area End
    return (
      <div>
        <MainContainer>
          <SecondContainer data-test-id="secondContainer">
            <Box style={{ marginBottom: '30px', textAlign: "center" }}>
              <img src={image} style={{ marginBottom: '40px', width: "180px"}} />
              <Typography style={webStyles.subTitle}>
                Verify it's you!
              </Typography>
              <Typography style={webStyles.info}>
                We've sent a 4-digit verification code on <br /> your email & mobile number.
              </Typography>
            </Box>
            <Box style={{  display: 'flex', gap: '8px', justifyContent: "center", marginBottom: "16px"}}  data-test-id = 'otpInputBoxTest'>
              <OtpInput
                value={this.state.otp}
                onChange={(e: any) => this.handleInputChangeOTP(e)}
                numInputs={4}
                renderSeparator={<span style={{ width: "8px" }}></span>}
                renderInput={(props) => <input {...props} style={{
                  appearance: "textfield", MozAppearance: "textfield",


                  width: "54px",
                  textAlign: "center",
                  border: props && props.value && boxColor !== "1px solid #F87171" ? "1px solid #0099FF" : boxColor,
                  borderRadius: "8px",
                  height: "44px",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 400,
                  caretColor: "blue",


                }} />}
                inputType={"number"}

                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid #CBD5E1",
                  borderRadius: "8px",
                  width: "54px",
                  height: "44px",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 400,
                  caretColor: "blue",
                }}
                data-test-id = 'otpInputTest'
              />
            </Box>
            <div style={{display:"flex",alignItems:"center", justifyContent: "center", gap: "4px", height: "22px"}}>
              {this.state.otpError && 
                <>
                  <img src = {img.otpError}/>
                  <Typography 
                    style={{color:"#F87171", fontFamily:"Poppins",fontSize:"14px",fontWeight : 400,}}
                    >{this.state.otpErrorMessage}</Typography>
                </>
              }
            </div>
            <Box style={{ marginBottom: '110px', textAlign: "center", marginTop: "19px" }}>
              <Typography style={webStyles.otpSpan}>This code will expire in : <span style={{ fontWeight: 700 }}> {Math.floor(this.state.time / 60)
              .toString()
              .padStart(2, '0')}
            :{(this.state.time % 60).toString().padStart(2, '0')}</span></Typography>
            </Box>

            <Box>
              <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CustumButton 
                disabled= {this.state.otp.length != 4}
                  onClick={this.otpConfirmationApi} data-test-id="otpSuccessTest">Verify</CustumButton>
                <Typography style={webStyles.spanVerifyTitle}>Didn't received the code?<Link style={{ marginLeft: '8px', cursor : "pointer", color: "#0099FF", fontWeight: 700 }} onClick={this.resetOTPTimer} data-test-id="resetOTPTimerTest">Send again</Link></Typography>
              </Box>
            </Box>
          </SecondContainer>
          <MainDivImg>
            <img src={img.groupImage} style={{ width: "100%" }} />
            <Box className="text-div">
              <Typography style={webStyles.bottomTitle}>Manage your clients</Typography>
              <Typography style={webStyles.bottomSubTitle}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nostrum, odit laudantium. Facere, officia.</Typography>
            </Box>
          </MainDivImg>
        </MainContainer>
      </div>
    );
  }
}

const webStyles = {
  otp: {
    width: "52px",
    heigth: '44px',
    padding: '10px 8px',
  },
  otpSpan: {
    color: "#281D19",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  background: {
    position: "relative",
    height: "750px",
    backgroundColor: "#41B5DA",
    overflow: 'hidden',
    borderRadius: '32px 32px 32px 32px'
  } as React.CSSProperties,
  circle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "474px",
    height: "474px",
    backgroundColor: "red",
    borderRadius: "373px",
    background: "linear-gradient(180deg, #72EFD5 0%, rgba(217, 217, 217, 0.00) 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  title: {
    color: "black",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
    marginBottom: '16px'
  },
  subTitle: {
    color: "#281D19",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: '16px'
  } as React.CSSProperties,
  info: {
    textAlign: 'center',
    color: "#281D19",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  } as React.CSSProperties,
  bottomTextBox: {
    width: "500px",
    position: 'absolute',
    bottom: '100px',
    left: "50%",
    textAlign: "center",
    transform: "translateX(-50%)",
    color: "white",
  } as React.CSSProperties,
  bottomTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: '24px'
  },
  bottomSubTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  spanVerifyTitle: {
    color: "#0F172A",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  btnVerify: {
    width: '360px',
    padding: '10px 16px',
    fontSize: '16px',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    borderRadius: '8px',
    background: 'linear-gradient(224deg, #445DA7 6.85%, #1DBADF 107.31%)',
    color: 'white',
    marginBottom: '16px'
  } as React.CSSProperties,
}

