import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

interface myProps {
  open: boolean;
  title: string;
  content: JSX.Element;
  doneTitle: string;
  disableSave?: boolean;
  showTitle?: boolean;
  showContentClose?: boolean;
  showClose?: boolean;
  onDone?: () => void;
  onClose?: () => void;
  rootClassName?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(15,23,42,0.4)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
    "& .MuiDialog-paperScrollPaper": {
      borderRadius: "8px 8px 32px 8px",
      width: "100%",
    },
    "& .MuiDialogTitle-root": {
      padding: "26px 58px 26px 40px",
    },
    "&.click2callDialog": {
      "& .MuiDialogContent-dividers": {
        padding: "16px 40px !important",
        height: "282px",
      },
      "& .MuiDialogActions-root": {
        justifyContent: "flex-end",
        padding: "24px",
        "& .MuiButton-outlined": {
          border: "1px solid #0099FF",
          width: "120px",
        },
        "& .MuiButton-contained": {
          width: "120px",
        },
        "@media (max-width:599px)": {
          flexDirection: "row",
        },
      },
      "& .MuiInputBase-input": {
        margin: 0,
      },
      "& .buttonListing": {
        display: "flex",
        flexDirection: "column",
        marginTop: "16px",
        "& .MuiButton-root": {
          padding: "16px",
          borderBottom: "1px solid #E2E8F0 !important",
          justifyContent: "flex-start",
          textTransform: "none",
          border: "none",
        },
        "& .MuiButton-label": {
          color: "#0F172A",
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "22px",
        },
        "& .MuiButton-contained": {
          background: "#0099FF",
          borderRadius: 0,
          boxShadow: "none",
          "& .MuiButton-label": {
            color: "#FFFFFF",
          },
        },
      },
    },
  },
  content: {
    padding: "40px !important",
    "& .MuiInput-underline:before": {
      content: "none",
    },
    "& .MuiInput-underline:after": {
      content: "none",
    },
    "& .textField": {
      "&.MuiFormControl-root": {
        maxWidth: "436px",
      },
      "& .MuiInputBase-input": {
        border: "1px solid #CBD5E1",
        padding: "16px 8px",
        borderRadius: "8px",
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "24px",
    padding: "0px",
    "&.MuiIconButton-root.Mui-disabled": {
      opacity: 0.4,
    },
  },
  title: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  text: {
    color: "#0F172A",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  actions: {
    padding: "24px 40px",
    gap: "16px",
    justifyContent: "space-between",
    "&.MuiDialogActions-spacing > :not(:first-child)": {
      margin: 0,
    },
    "& .MuiButton-root": {
      textTransform: "none",
      borderRadius: "8px",
      padding: "16px",
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      "&.Mui-disabled": {
        background: "#F1F5F9",
        border: "none",
      },
    },
    "& .MuiButton-outlined": {
      border: "1px solid #CBD5E1",
      background: "#F8FAFC",
      color: "#0099FF",
      fontWeight: 600,
      whiteSpace: "nowrap",
      "&.Mui-disabled": {
        color: "#99D6FF",
      },
    },
    "& .MuiButton-contained": {
      background: "#0099FF",
      color: "#FFFFFF",
      fontWeight: 700,
      width: "79%",
      "&.Mui-disabled": {
        color: "#64748B",
      },
    },
    "@media (max-width:599px)": {
      flexDirection: "column",
      "& .MuiButton-root": {
        width: "100%",
      },
      "& .MuiButton-contained": {
        maxWidth: "none !important",
        width: "100%",
      },
    },
  },
}));

export default function CustomDialog({
  open,
  title,
  content,
  doneTitle,
  disableSave,
  showTitle = true,
  showContentClose = false,
  showClose = true,
  onDone,
  onClose,
  rootClassName,
}: myProps) {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      className={`${classes.root} ${rootClassName}`}
    >
      {showTitle && (
        <DialogTitle>
          <div className={classes.title}>{title}</div>
          {showClose && (
            <IconButton className={classes.closeButton} onClick={onClose}>
              <img src={require("./button_close_icon.png")} />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent dividers className={classes.content}>
        {showContentClose && (
          <IconButton className={classes.closeButton}>
            <img src={require("./button_close_icon.png")} />
          </IconButton>
        )}
        {content}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-test-id="doneButton"
          variant="contained"
          disabled={disableSave}
          onClick={onDone}
        >
          {doneTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
