import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id?: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    editManager: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class EditAgentController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.NavigationPayLoadMessage)
      ];

      this.state = {
        editManager: ""
      }
      // Customizable Area End

      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      // Customizable Area Start
      // Customizable Area End
    }

    // Customizable Area Start
    receive = async (from: string, message: Message) => {
      if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
        const data = message.getData("editManager");
        this.setState({editManager:data});
    }
    };
    // Customizable Area End
}

