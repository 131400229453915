// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  styled,
  InputBase,
  SwitchProps,
  Switch,
  FormGroup,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import AddInBoundIVRController, {
  Props,
  images,
  configJSON,
} from "./AddInBoundIvrController";
import Layout from "../../../components/src/Layout.web";
// Customizable Area End

export default class AddInBoundIVR extends AddInBoundIVRController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  groupListComponent() {
    return (
      <Box className="department-box">
        <div className="inBoundIvr-text">{configJSON.selectGroup} *</div>
        <div className="inBoundIvr-listing">
          <div className="inBoundIvr-div">
            <div className="inBoundIvr-text-1">{configJSON.group}</div>
            <div className="inBoundIvr-sd-box">
              <div className="inBoundIvr-search-box">
                <div className="search-box">
                  <Autocomplete
                    data-test-id="groupList"
                    freeSolo
                    multiple
                    clearOnBlur
                    disableClearable
                    options={this.state.groupList.mainList}
                    value={this.state.groupList.selectedList}
                    getOptionLabel={(option: { name: string; id: number }) => {
                      return option.name;
                    }}
                    onChange={(event, newValue) => {
                      this.handleGroupChange(newValue);
                    }}
                    renderInput={(params) => (
                      <div>
                        <TextField {...params} placeholder="Select Group ..." />
                        <SearchRoundedIcon className="search" />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout menuItem="campaign" navigation={this.props.navigation}>
        <Box display="inline-flex" alignItems="center" gridGap={24}>
          <img src={images.addIVR} width={64} height={48} />
          <Typography style={styles.inboundIVRLabelContainer} component="h2">
            {configJSON.inboundIVR}
          </Typography>
        </Box>
        <FormGroup
          style={{
            padding: "24px 40px",
            borderRadius: "8px",
            background: "#fff",
          }}
        >
          <InBoundIVRInFormationContainer data-test-id="inBoundIVRInFormationContainer">
            <Box>
              <InputLabel
                className="InputLabelCustom"
                error={!!this.state.formErrors.campaignName}
                htmlFor="campaignName"
              >
                {configJSON.campaignName}*
              </InputLabel>
              <InputBase
                data-test-id="campaignName"
                className="InputBaseCustom"
                id="campaignName"
                name="campaignName"
                type="text"
                value={this.state.formData.campaignName}
                error={!!this.state.formErrors.campaignName}
                onChange={this.handleChange}
              />
              {!!this.state.formErrors.campaignName && (
                <Typography color="error" data-test-id="name-error">
                  {this.state.formErrors.campaignName}
                </Typography>
              )}
            </Box>
            <Box>
              <InputLabel className="InputLabelCustom" htmlFor="selectDID">
                {configJSON.selectDID}*
              </InputLabel>
              <Select
                data-test-id="selectDID"
                className="SelectCustom"
                id="selectDID"
                name="selectDID"
                value={this.state.formData.selectDID}
                onChange={(event) => this.handleChange(event)}
                IconComponent={ExpandMoreRoundedIcon}
                MenuProps={{
                  className: "menuProps",
                }}
              >
                <MenuItem value={0} style={styles.menuItem} disabled>
                  {configJSON.select}
                </MenuItem>
                {this.state.didNumberList.map((mode) => (
                  <MenuItem
                    key={mode.id}
                    value={mode.id}
                    style={styles.menuItem}
                  >
                    {mode.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <InputLabel className="InputLabelCustom" htmlFor="selectIVR">
                {configJSON.selectIVR}*
              </InputLabel>
              <Select
                data-test-id="selectIVR"
                className="SelectCustom"
                id="selectIVR"
                name="selectIVR"
                value={this.state.formData.selectIVR}
                onChange={(event) => this.handleChange(event)}
                IconComponent={ExpandMoreRoundedIcon}
                MenuProps={{
                  className: "menuProps",
                }}
              >
                <MenuItem value={0} style={styles.menuItem} disabled>
                  {configJSON.select}
                </MenuItem>
                {AddInBoundIVR.SELECT_IVR_CONSTANT.map((mode) => (
                  <MenuItem
                    key={mode.id}
                    value={mode.id}
                    style={styles.menuItem}
                  >
                    {mode.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="department-box">
              <div className="inBoundIvr-text">
                {configJSON.selectDepartment} *
              </div>
              <div className="inBoundIvr-listing">
                <div className="inBoundIvr-div">
                  <div className="inBoundIvr-text-1">
                    {configJSON.departments}
                  </div>
                  <div className="inBoundIvr-sd-box">
                    <div className="inBoundIvr-search-box">
                      <div className="search-box">
                        <Autocomplete
                          data-test-id="departmentList"
                          freeSolo
                          multiple
                          clearOnBlur
                          disableClearable
                          options={this.state.departmentList.mainList}
                          value={this.state.departmentList.selectedList}
                          getOptionLabel={(option: {
                            name: string;
                            id: number;
                          }) => {
                            return option.name;
                          }}
                          onChange={(event, newValue) => {
                            this.handleDepartmentChange(newValue);
                          }}
                          renderInput={(params) => (
                            <div>
                              <TextField
                                {...params}
                                placeholder="Select Department ..."
                              />
                              <SearchRoundedIcon className="search" />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
            <Box className="switchBox">
              <InputLabel
                className="InputLabelCustom"
                htmlFor="enableRecording"
              >
                {configJSON.enableRecording}*
              </InputLabel>
              <SwitchCustom
                data-test-id="enableRecording"
                id="enableRecording"
                name="enableRecording"
                checked={this.state.formData.enableRecording}
                onChange={(event, checked) =>
                  this.handleChange(event, true, checked)
                }
              />
            </Box>
            <Box className="switchBox">
              <InputLabel
                className="InputLabelCustom"
                htmlFor="enableClick2Call"
              >
                {configJSON.enableClick2Call}*
              </InputLabel>
              <SwitchCustom
                data-test-id="enableClick2Call"
                id="enableClick2Call"
                name="enableClick2Call"
                checked={this.state.formData.enableClick2Call}
                onChange={(event, checked) =>
                  this.handleChange(event, true, checked)
                }
              />
            </Box>
            <Box>
              <InputLabel className="InputLabelCustom" htmlFor="callBackURL">
                {configJSON.callBackURL}
              </InputLabel>
              <InputBase
                data-test-id="callBackURL"
                className="InputBaseCustom"
                id="callBackURL"
                name="callBackURL"
                type="text"
                value={this.state.formData.callBackURL}
                onChange={this.handleChange}
              />
            </Box>
            <Box className="switchBox">
              <InputLabel className="InputLabelCustom" htmlFor="blacklistGroup">
                {configJSON.blacklistGroup}*
              </InputLabel>
              <SwitchCustom
                data-test-id="blacklistGroup"
                id="blacklistGroup"
                name="blacklistGroup"
                checked={this.state.formData.blacklistGroup}
                onChange={(event, checked) =>
                  this.handleChange(event, true, checked)
                }
              />
            </Box>
            {this.state.formData.blacklistGroup && this.groupListComponent()}
            <Box className="switchBox">
              <InputLabel className="InputLabelCustom" htmlFor="whitelistGroup">
                {configJSON.whitelistGroup}*
              </InputLabel>
              <SwitchCustom
                data-test-id="whitelistGroup"
                id="whitelistGroup"
                name="whitelistGroup"
                checked={this.state.formData.whitelistGroup}
                onChange={(event, checked) =>
                  this.handleChange(event, true, checked)
                }
              />
            </Box>
            {this.state.formData.whitelistGroup && this.groupListComponent()}
            <Box style={{ marginLeft: "40px" }}>
              <InputLabel
                className="InputLabelCustom"
                htmlFor="wrapUpTime.first"
                style={{ padding: "12px 0" }}
              >
                {configJSON.wrapUpTime} :
              </InputLabel>
              <div className="timeBox">
                <InputLabel
                  className="InputLabelCustom"
                  htmlFor="wrapUpTime.first"
                >
                  SS
                </InputLabel>
                <div>
                  <InputBase
                    data-test-id="wrapUpTimeFirst"
                    className="TimeInputBaseCustom"
                    id="wrapUpTime.first"
                    name="first"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.wrapUpTime.first}
                    onChange={(event) =>
                      this.handleNumberChange(event, "wrapUpTime")
                    }
                  />
                  <InputBase
                    data-test-id="wrapUpTimeSecond"
                    className="TimeInputBaseCustom"
                    id="wrapUpTime.second"
                    name="second"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.wrapUpTime.second}
                    onChange={(event) =>
                      this.handleNumberChange(event, "wrapUpTime")
                    }
                  />
                </div>
              </div>
            </Box>
            <Box>
              <InputLabel className="InputLabelCustom" htmlFor="dispositions">
                {configJSON.dispositions}*
              </InputLabel>
              <Select
                data-test-id="dispositions"
                className="SelectCustom"
                id="dispositions"
                name="dispositions"
                value={this.state.formData.dispositions}
                onChange={(event) => this.handleChange(event)}
                IconComponent={ExpandMoreRoundedIcon}
                MenuProps={{
                  className: "menuProps",
                }}
              >
                {AddInBoundIVR.DISPOSITIONS_CONSTANT.map((rules) => (
                  <MenuItem
                    key={rules.id}
                    value={rules.id}
                    style={styles.menuItem}
                  >
                    {rules.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {this.state.formData.dispositions === 2 && (
              <Box>
                <InputLabel className="InputLabelCustom" htmlFor="apiInput">
                  {configJSON.apiInput}
                </InputLabel>
                <InputBase
                  data-test-id="apiInput"
                  className="InputBaseCustom"
                  id="apiInput"
                  name="apiInput"
                  type="text"
                  value={this.state.formData.apiInput}
                  onChange={this.handleChange}
                />
              </Box>
            )}
            <Box>
              <InputLabel
                className="InputLabelCustom"
                htmlFor="hitScreenPOPTrigger"
              >
                {configJSON.hitScreenPOPTrigger}
              </InputLabel>
              <Select
                data-test-id="hitScreenPOPTrigger"
                className="SelectCustom"
                id="hitScreenPOPTrigger"
                name="hitScreenPOPTrigger"
                value={this.state.formData.hitScreenPOPTrigger}
                onChange={(event) => this.handleChange(event)}
                IconComponent={ExpandMoreRoundedIcon}
                MenuProps={{
                  className: "menuProps",
                }}
              >
                <MenuItem value={0} style={styles.menuItem}>
                  {configJSON.select}
                </MenuItem>
                {AddInBoundIVR.HIT_SCREEN_POP_TRIGGER_CONSTANT.map((mode) => (
                  <MenuItem
                    key={mode.id}
                    value={mode.id}
                    style={styles.menuItem}
                  >
                    {mode.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <InputLabel className="InputLabelCustom" htmlFor="screenPOPURL">
                {configJSON.screenPOPURL}
              </InputLabel>
              <InputBase
                data-test-id="screenPOPURL"
                className="InputBaseCustom"
                id="screenPOPURL"
                name="screenPOPURL"
                type="text"
                value={this.state.formData.screenPOPURL}
                onChange={this.handleChange}
              />
            </Box>

            <Box>
              <Button
                data-test-id="saveButton"
                type="submit"
                className="ButtonCustom"
                disabled={this.isSaveBtnDisabled()}
                onClick={this.handleSave}
              >
                {configJSON.save}
              </Button>
            </Box>
          </InBoundIVRInFormationContainer>
        </FormGroup>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  inboundIVRLabelContainer: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#281D19",
  },
  menuItem: {
    fontSize: "14px",
  },
} as const;

export const InBoundIVRInFormationContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gridRowGap: "40px",
  maxWidth: "100%",
  marginTop: "9px",
  "& .department-box": {
    background: "#F8FAFC",
    borderRadius: "16px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    maxWidth: "485px",
    "& .inBoundIvr-text": {
      fontWeight: 600,
      fontSize: "20px",
      color: "#0099FF",
      fontFamily: "Poppins",
      lineHeight: "28px",
    },
    "& .inBoundIvr-listing": {
      alignItems: "flex-start",
      gap: "16px",
      display: "flex",
    },
    "& .inBoundIvr-text-1": {
      fontWeight: 700,
      fontSize: "14px",
      color: "#64748B",
      fontFamily: "Poppins",
      lineHeight: "22px",
      marginBottom: "4px",
    },
    "& .priority-box": {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      background: "#FFFFFF",
      width: "60px",
      height: "56px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .inBoundIvr-div": {
      width: "100%",
      "& .inBoundIvr-sd-box": {
        alignItems: "center",
        gap: "5px",
        display: "flex",
        "& .inBoundIvr-search-box": {
          width: "100%",
          padding: "12px 8px",
          background: "#ffffff",
          border: "1px solid #CBD5E1",
          borderRadius: "8px",
          display: "flex",
          gap: "8px",
          flexWrap: "wrap",
          "& .search-box": {
            fontFamily: "Poppins",
            fontSize: "16px",
            color: "#94A3B8",
            fontWeight: 400,
            lineHeight: "24px",
            overflowWrap: "anywhere",
            width: "100%",
            position: "relative",
            "& .MuiInputBase-root": {
              fontFamily: "Poppins",
              fontSize: "16px",
              color: "#94A3B8",
              fontWeight: 400,
              lineHeight: "24px",
              gap: "8px",
              "&:before": {
                content: "none",
              },
              "&:after": {
                content: "none",
              },
              "& .MuiAutocomplete-tag": {
                margin: "0",
                background: "#F1F5F9",
              },
              "& .MuiAutocomplete-input": {
                minWidth: "114px",
              },
              "& .MuiChip-label": {
                fontFamily: "Poppins",
                fontSize: "14px",
                color: "#334155",
                fontWeight: 400,
                lineHeight: "22px",
              },
            },
            "& .MuiIconButton-root": {
              padding: 0,
            },
            "& .search.MuiSvgIcon-root": {
              background: "#ffffff",
              position: "absolute",
              color: "#94A3B8",
              right: 0,
              bottom: "4px",
            },
          },
        },
        "& .MuiButtonBase-root": {
          marginRight: "13px",
          padding: "0",
          "& .MuiSvgIcon-root": {
            color: "#0099FF",
          },
        },
      },
    },
  },
  "& .ButtonCustom": {
    maxHeight: "56px",
    height: "100%",
    maxWidth: "520px",
    width: "100%",
    borderRadius: "8px",
    padding: "16px",
    color: "#FFFFFF",
    borderColor: "#0099FF",
    backgroundColor: "#0099FF",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    textTransform: "none",
    lineHeight: "24px",
    marginTop: "10px",
    marginBottom: "93px",
    "&:disabled": {
      borderColor: "#CBD5E1",
      backgroundColor: "#E2E8F0",
      color: "#334155",
    },
    "&:hover": {
      backgroundColor: "#0099FF",
      color: "#FFFFFF",
      borderColor: "#0099FF",
    },
  },
  "& .InputLabelCustom": {
    fontWeight: 700,
    fontSize: "18px",
    color: "#334155",
    fontFamily: "Poppins",
    lineHeight: "26px",
  },
  "& .MuiFormLabel-root.Mui-error": {
    color: "#F87171",
  },
  "& .InputBaseCustom": {
    width: "100%",
    marginTop: "4px",
    maxWidth: "520px",
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: "#F87171",
        color: "#F87171",
      },
    },
    "& .MuiInputBase-input": {
      height: "34px",
      borderRadius: 8,
      maxWidth: "520px",
      backgroundColor: "#FFFFFF",
      position: "relative",
      borderColor: "#CBD5E1",
      border: "1px solid",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
    },
  },
  "& .TimeInputBaseCustom": {
    margin: "0 2px",
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: "#F87171",
      },
    },
    "& .MuiInputBase-input": {
      height: "34px",
      borderRadius: 8,
      maxWidth: "10px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#CBD5E1",
      fontSize: 16,
      width: "100%",
      padding: "10px 30px",
    },
  },
  "& .SelectCustom": {
    width: "100%",
    marginTop: "4px",
    maxWidth: "520px",
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
    "& .MuiInputBase-input": {
      height: "34px",
      borderRadius: 8,
      maxWidth: "520px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#CBD5E1",
      fontSize: 16,
      padding: "10px 12px",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-icon": {
      padding: "0 7px",
      background: "white",
      right: 1,
    },
  },
  "& .switchBox": {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "440px",
    height: "52px",
  },
  "& .timeBox": {
    alignItems: "center",
    gap: "8px",
    display: "flex",
    marginTop: "10px",
    flexWrap: "wrap",
  },
  "& .menuProps": {
    borderRadius: "8px",
    padding: "12px",
    border: "1px solid #CBD5E1",
    minWidth: "494px",
  },
  "@media (max-width:499px)": {
    "& .department-box": {
      padding: "0px",
      display: "flex",
      "& .inBoundIvr-listing": {
        flexDirection: "column",
      },
    },
  },
}));

const SwitchCustom = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  height: 28,
  padding: 0,
  width: 48,
  "& .MuiSwitch-switchBase": {
    margin: 2,
    transitionDuration: "300ms",
    padding: 0,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
        background: "#0099FF",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#FFFFFF",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    width: 24,
    height: 24,
    boxSizing: "border-box",
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#E2E8F0",
    opacity: 1,
    borderRadius: 40,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Customizable Area End
