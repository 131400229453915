// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import tz from "countries-and-timezones";
import { Country, State } from "country-state-city";
import { getStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../fileattachment/src/Helpers/create-request-message";
import { expireTokenHandling } from "../../../components/src/Utilities";

export const configJSON = require("./config");
export const images = require("./assets");

type ITime = {
  first: number | "";
  second: number | "";
};

export type FormData = {
  departmentName: string;
  connectionMode: number;
  recordCalls: boolean;
  ringingTime: ITime;
  fallBackRules: number;
  configureTimings: string;
  days: string;
  dayList: {
    name: string;
    isSelected: boolean;
  }[];
  fromTimeHH: ITime;
  fromTimeMM: ITime;
  toTimeHH: ITime;
  toTimeMM: ITime;
  stickyAgent: boolean;
  stickyAgentOption: string;
  stickyAgentOptionTime: ITime;
  queueTimeout: number;
  addNation: string;
  addState: string;
  skillInput: string;
  skillList: string[];
  offlineCampaignNumbersInput: string;
  offlineCampaignNumbersList: string[];
};
export type ErrorData = {
  departmentName: string;
  fromTime: string;
  toTime: string;
};

export type IOption = { id: number; name: string };

export type IMainAgentList = {
  agentList: IOption[];
  selectedAgentList: IOption[];
};

const initialDayList = [
  {
    name: "Mon",
    isSelected: false,
  },
  {
    name: "Tue",
    isSelected: false,
  },
  {
    name: "Wed",
    isSelected: false,
  },
  {
    name: "Thu",
    isSelected: false,
  },
  {
    name: "Fri",
    isSelected: false,
  },
  {
    name: "Sat",
    isSelected: false,
  },
  {
    name: "Sun",
    isSelected: false,
  },
];

const initialData: FormData = {
  departmentName: "",
  connectionMode: 1,
  recordCalls: true,
  ringingTime: { first: "", second: "" },
  fallBackRules: 1,
  configureTimings: "Asia/Kolkata",
  days: "",
  dayList: initialDayList,
  fromTimeHH: { first: "", second: "" },
  fromTimeMM: { first: "", second: "" },
  toTimeHH: { first: "", second: "" },
  toTimeMM: { first: "", second: "" },
  stickyAgent: false,
  stickyAgentOption: "",
  stickyAgentOptionTime: { first: "", second: "" },
  queueTimeout: 1,
  addNation: "IN",
  addState: "Delhi",
  skillInput: "",
  skillList: [],
  offlineCampaignNumbersInput: "",
  offlineCampaignNumbersList: [],
};

const initialErrors: ErrorData = {
  departmentName: "",
  fromTime: "",
  toTime: "",
};

type DepartmentList = {
  department: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        name: string;
        sticky_agent: string;
        ringing_time: number;
        company_id: number;
        connection_mode: string;
        record_calls: boolean;
        fallback_rules: string;
        configure_timings: string;
        days: string[];
        days_mode: string;
        start_time: string;
        end_time: string;
        sticky_agent_toggle: boolean;
        sticky_agent_ringing_time: number;
        queue_timeout: string;
        nation: string;
        state: string;
        country_code: string;
        created_at: string;
        updated_at: string;
        sticky_agent_mode: string;
        skills: string[];
        offline_campaign_number: string[];
        agent_priorities: { agent_id: number; priority: number }[];
        connection_mode_id: number;
        fallback_rules_id: number;
        days_mode_id: number;
      };
    };
  };
};

type ResponseData = DepartmentList;
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  formData: FormData;
  formErrors: ErrorData;
  agentsData: IOption[];
  mainAgentList: IMainAgentList[];
  editDepartmentId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddDepartmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      formData: initialData,
      formErrors: initialErrors,
      agentsData: [],
      mainAgentList: [],
      editDepartmentId: 0,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const editId = message.getData("EditDepartment");
      this.setState({ editDepartmentId: Number(editId) });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const id = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const successData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      expireTokenHandling(successData);
      if (id === this.getAllAgentsAPICallId) {
        this.receiveLoadAgents(successData);
      }
      if (id === this.createDepartmentAPICallId) {
        this.receiveCreateDepartment(successData);
      }
      if (id === this.getDepartmentApiCallId) {
        this.receiveGetDepartmentAPICall(successData);
      }
    }
  };

  getAllAgentsAPICallId = "";
  createDepartmentAPICallId = "";
  getDepartmentApiCallId: string = "";

  static CONNECTION_MODE_CONSTANT = [
    {
      id: 1,
      label: "Priority routing",
    },
    {
      id: 2,
      label: "Parallel routing",
    },
    {
      id: 3,
      label: "Auto Call Distribution",
    },
    {
      id: 4,
      label: "Round Robin",
    },
  ] as const;

  static FALLBACK_RULES_CONSTANT = [
    {
      id: 1,
      label: "Disconnect",
    },
    {
      id: 2,
      label: "Voicemail",
    },
    {
      id: 3,
      label: "Dial-out",
    },
    {
      id: 4,
      label: "IVR",
    },
  ] as const;

  static CONFIGURE_TIMINGS_CONSTANT = Object.values(tz.getAllTimezones()).map(
    (entry) => entry.name
  );

  static QUEUE_TIMEOUT_CONSTANT = [
    {
      id: 1,
      label: "min",
    },
    {
      id: 2,
      label: "sec",
    },
  ] as const;

  static ADD_NATION_CONSTANT = Country.getAllCountries().map((entry) => ({
    code: entry.isoCode,
    name: entry.name,
  }));

  ADD_STATE_CONSTANT: string[] = State.getStatesOfCountry("IN").map(
    (entry) => entry.name
  );

  goToScreen = (route: string) => {
    const msg = new Message(getName(MessageEnum.NavigationNavigate));
    msg.addData(getName(MessageEnum.NavigationNavigateData), {
      route,
    });
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  componentDidMount = async () => {
    await this.getAgentsAPI();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.formData.addNation !== this.state.formData.addNation) {
      this.ADD_STATE_CONSTANT = State.getStatesOfCountry(
        this.state.formData.addNation
      ).map((entry) => entry.name);
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          addState: this.ADD_STATE_CONSTANT[0],
        },
      }));
    }

    if (prevState.formData.stickyAgent !== this.state.formData.stickyAgent) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          stickyAgentOption: prevState.formData.stickyAgent ? "1" : "",
        },
      }));
    }

    if (
      prevState.formData.stickyAgentOption !==
        this.state.formData.stickyAgentOption &&
      this.state.formData.stickyAgentOption === "1"
    ) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          stickyAgentOptionTime: { first: "", second: "" },
        },
      }));
    }

    if (prevState.formData.days !== this.state.formData.days) {
      let selected: string[] = [];
      if (this.state.formData.days === "1") {
        selected = ["Mon", "Tue", "Wed", "Thu", "Fri"];
      }
      if (this.state.formData.days === "2") {
        selected = ["Sat", "Sun"];
      }
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          dayList: initialDayList.map((day) => ({
            name: day.name,
            isSelected: selected.includes(day.name),
          })),
        },
      }));
    }
  }

  handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>,
    isSwitch?: boolean,
    checked?: boolean
  ) => {
    const name = event.target.name as keyof FormData;
    let value = event.target.value;
    if (name === "departmentName" && (value as string).length > 80) {
      return;
    }
    if (name === "offlineCampaignNumbersInput") {
      const regex = new RegExp(/[A-z]/g);
      const newValue = (value as string).replace(regex, "");
      value = newValue;
    }
    if (isSwitch) {
      value = checked;
    }
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleNumberChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>,
    parent: keyof FormData
  ) => {
    const name = event.target.name as keyof FormData;
    const value = event.target.value;
    const regex = new RegExp(/\D/g); // Matches any character that is not a digit (0-9)
    const newValue = (value as string).replace(regex, "");
    if (newValue.length < 2) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [parent]: {
            ...(prevState.formData[parent] as Object),
            [name]: newValue,
          },
        },
      }));
    }
  };

  handleDayClick = (index: number, selected: boolean) => {
    const newDayList = [...this.state.formData.dayList];
    newDayList[index].isSelected = selected;
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        dayList: newDayList,
      },
    }));
  };

  handleAddSkill = () => {
    const newSkillList = [...this.state.formData.skillList];
    newSkillList.push(this.state.formData.skillInput);
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        skillInput: "",
        skillList: newSkillList,
      },
    }));
  };

  handleDeleteSkill = (index: number) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        skillList: prevState.formData.skillList.filter((_, i) => i !== index),
      },
    }));
  };

  handleAddOfflineCampaignNumbersInput = () => {
    const newOfflineCampaignNumbersList = [
      ...this.state.formData.offlineCampaignNumbersList,
    ];
    newOfflineCampaignNumbersList.push(
      this.state.formData.offlineCampaignNumbersInput
    );
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        offlineCampaignNumbersInput: "",
        offlineCampaignNumbersList: newOfflineCampaignNumbersList,
      },
    }));
  };

  handleDeleteOfflineCampaignNumber = (index: number) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        offlineCampaignNumbersList:
          prevState.formData.offlineCampaignNumbersList.filter(
            (_, i) => i !== index
          ),
      },
    }));
  };

  areAllTrue(...values: boolean[]) {
    return values.every((val) => val);
  }

  some(...values: boolean[]) {
    return values.some((val) => val);
  }

  isSaveBtnDisabled = () => {
    const isRingingTime = this.areAllTrue(
      this.state.formData.ringingTime.first !== "",
      this.state.formData.ringingTime.second !== "",
      Number(
        `${this.state.formData.ringingTime.first}${this.state.formData.ringingTime.second}`
      ) > 1
    );

    const isFromTime = this.areAllTrue(
      this.state.formData.fromTimeHH.first !== "",
      this.state.formData.fromTimeHH.second !== "",
      this.state.formData.fromTimeMM.first !== "",
      this.state.formData.fromTimeMM.second !== ""
    );

    const isToTime = this.areAllTrue(
      this.state.formData.toTimeHH.first !== "",
      this.state.formData.toTimeHH.second !== "",
      this.state.formData.toTimeMM.first !== "",
      this.state.formData.toTimeMM.second !== ""
    );

    const isStickyAgent = this.some(
      !this.state.formData.stickyAgent,
      this.areAllTrue(
        this.state.formData.stickyAgent,
        this.state.formData.stickyAgentOption !== "2"
      ),
      this.areAllTrue(
        this.state.formData.stickyAgent,
        this.state.formData.stickyAgentOption === "2",
        this.state.formData.stickyAgentOptionTime.first !== "",
        this.state.formData.stickyAgentOptionTime.second !== ""
      )
    );

    const isDays = this.areAllTrue(
      !!this.state.formData.days,
      this.some(
        this.state.formData.days !== "3",
        this.areAllTrue(
          this.state.formData.days === "3",
          this.state.formData.dayList.some((day) => day.isSelected)
        )
      )
    );

    const isAgentSelected =
      !!this.state.mainAgentList.length &&
      !!this.state.mainAgentList[0].selectedAgentList.length;

    return this.some(
      !this.state.formData.departmentName,
      !isRingingTime,
      !isFromTime,
      !isToTime,
      !isStickyAgent,
      !isDays,
      !isAgentSelected
    );
  };

  setError = (field: keyof ErrorData, error: string) => {
    this.setState((prevState) => ({
      ...prevState,
      formErrors: {
        ...prevState.formErrors,
        [field]: error,
      },
    }));
  };

  checkValidation = () => {
    this.setState({ formErrors: initialErrors });
    let isValid = true;

    const checkFromTimeHH = Number(
      `${this.state.formData.fromTimeHH.first}${this.state.formData.fromTimeHH.second}`
    );
    const checkFromTimeMM = Number(
      `${this.state.formData.fromTimeMM.first}${this.state.formData.fromTimeMM.second}`
    );
    if (checkFromTimeHH >= 24 || checkFromTimeMM >= 60) {
      this.setError("fromTime", "error");
      isValid = false;
    }

    const checkToTimeHH = Number(
      `${this.state.formData.toTimeHH.first}${this.state.formData.toTimeHH.second}`
    );
    const checkToTimeMM = Number(
      `${this.state.formData.toTimeMM.first}${this.state.formData.toTimeMM.second}`
    );
    if (checkToTimeHH >= 24 || checkToTimeMM >= 60) {
      this.setError("toTime", "error");
      isValid = false;
    }

    if (
      !(
        checkFromTimeHH < checkToTimeHH ||
        (checkFromTimeHH === checkToTimeHH && checkFromTimeMM < checkToTimeMM)
      )
    ) {
      this.setError("toTime", "error");
      isValid = false;
    }

    return isValid;
  };

  getSelectedAgentList = () => {
    const agentList = [...this.state.mainAgentList];
    const addedAgentList: { agent_id: number; priority: number }[] = [];
    agentList.forEach((list, i) => {
      addedAgentList.push(
        ...list.selectedAgentList.map((selected) => ({
          agent_id: Number(selected.id),
          priority: i + 1,
        }))
      );
    });
    return addedAgentList;
  };

  handleSave = () => {
    const isValid = this.checkValidation();
    if (isValid) {
      const formData = this.state.formData;
      const addNation = AddDepartmentController.ADD_NATION_CONSTANT.find(
        (n) => n.code === formData.addNation
      );
      const department = {
        name: formData.departmentName,
        connection_mode: formData.connectionMode,
        record_calls: formData.recordCalls,
        ringing_time: Number(
          `${formData.ringingTime.first}${formData.ringingTime.second}`
        ),
        fallback_rules: formData.fallBackRules,
        configure_timings: formData.configureTimings,
        days_mode: Number(formData.days),
        days: formData.dayList.filter((d) => d.isSelected).map((d) => d.name),
        start_time: `${formData.fromTimeHH.first}${formData.fromTimeHH.second}:${formData.fromTimeMM.first}${formData.fromTimeMM.second}:00`,
        end_time: `${formData.toTimeHH.first}${formData.toTimeHH.second}:${formData.toTimeMM.first}${formData.toTimeMM.second}:00`,
        sticky_agent_toggle: formData.stickyAgent,
        sticky_agent: formData.stickyAgentOption,
        sticky_agent_ringing_time: Number(
          `${formData.stickyAgentOptionTime.first}${formData.stickyAgentOptionTime.second}`
        ),
        queue_timeout: formData.queueTimeout,
        nation: addNation && addNation.name,
        state: formData.addState,
        country_code: formData.addNation,
        skills: formData.skillList,
        offline_campaign_number: formData.offlineCampaignNumbersList,
        agent_priorities_attributes: this.getSelectedAgentList(),
      };

      this.createDepartmentAPI(JSON.stringify({ department }));
    }
  };

  getNewFilteredList = () => {
    const getSelectedAgent: number[] = [];
    this.state.mainAgentList.forEach((a) =>
      getSelectedAgent.push(...a.selectedAgentList.map((s) => s.id))
    );
    const getNewList = this.state.agentsData.filter((agent) => {
      return !getSelectedAgent.includes(agent.id);
    });
    return getNewList;
  };

  handleAgentChange = (value: (IOption | string)[], index: number) => {
    const newValue = [...(value as IOption[])];
    let newMainAgentList: IMainAgentList[] = [...this.state.mainAgentList];
    newMainAgentList[index].selectedAgentList = newValue;
    this.setState((prev) => ({
      ...prev,
      mainAgentList: newMainAgentList,
    }));
  };

  handleAddAnotherLevels = () => {
    this.setState((prev) => ({
      ...prev,
      mainAgentList: [
        ...prev.mainAgentList,
        { agentList: this.getNewFilteredList(), selectedAgentList: [] },
      ],
    }));
  };

  handleDeleteAgent = () => {
    let newMainAgentList: IMainAgentList[] = [...this.state.mainAgentList];
    newMainAgentList.pop();
    this.setState((prev) => ({
      ...prev,
      mainAgentList: newMainAgentList,
    }));
  };

  getTimes = (apiTime: string) => {
    let time: ITime = {
      first: "",
      second: "",
    };
    time = {
      first: Number(apiTime[0]),
      second: Number(apiTime[1]),
    };
    return time;
  };

  getAgentsAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAgentsAPICallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage,
      endPoint: configJSON.getAgentsEndpoint,
      method: configJSON.validationApiMethodType,
      header: header,
    });
  };

  receiveLoadAgents = (successData: any) => {
    if (successData.agents) {
      const getList = successData.agents.data.map(
        (agent: { id: number; attributes: { agent_name: string } }) => ({
          id: agent.id,
          name: agent.attributes.agent_name,
        })
      );
      this.setState(
        (prev) => ({
          ...prev,
          agentsData: getList,
          mainAgentList: [{ agentList: getList, selectedAgentList: [] }],
        }),
        () => {
          if (this.state.editDepartmentId) this.getDepartmentAPICall();
        }
      );
    }
  };

  createDepartmentAPI = async (body: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createDepartmentAPICallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage,
      endPoint: this.state.editDepartmentId
        ? `${configJSON.createDepartmentEndpoint}/${this.state.editDepartmentId}`
        : configJSON.createDepartmentEndpoint,
      method: this.state.editDepartmentId
        ? configJSON.putAPIMethod
        : configJSON.postAPIMethod,
      header: header,
      body,
    });
  };

  receiveCreateDepartment = (successData: any) => {
    if (successData && successData.department) {
      this.goToScreen("Department");
    } else if ("errors" in successData) {
      let initialAPIErrors: ErrorData = { ...initialErrors };
      for (const val of successData.errors) {
        if (val.search("Name") >= 0) {
          initialAPIErrors.departmentName = configJSON.departmentNameExistError;
        }
      }
      this.setState((prev) => ({ ...prev, formErrors: initialAPIErrors }));
      return;
    }
  };

  getDepartmentAPICall = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDepartmentApiCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage,
      endPoint: `${configJSON.createDepartmentEndpoint}/${this.state.editDepartmentId}`,
      method: configJSON.validationApiMethodType,
      header: header,
    });
  };

  receiveGetDepartmentAPICall = (responseJson: ResponseData) => {
    if (responseJson && responseJson.department) {
      const apiData = responseJson.department.data.attributes;
      const apiAgentData: { [key: number]: number[] } = {};
      apiData.agent_priorities.forEach(
        ({ agent_id, priority }: { agent_id: number; priority: number }) => {
          if (!apiAgentData[priority]) {
            apiAgentData[priority] = [];
          }
          apiAgentData[priority].push(agent_id);
        }
      );
      const apiMainAgentList = Object.values(apiAgentData).map((agentData) => ({
        agentList: [],
        selectedAgentList: this.state.agentsData.filter((agent) =>
          agentData.includes(Number(agent.id))
        ),
      }));
      this.setState(
        {
          formData: {
            departmentName: apiData.name,
            connectionMode: apiData.connection_mode_id,
            recordCalls: apiData.record_calls,
            ringingTime: this.getTimes(
              apiData.ringing_time.toString().padStart(2, "0")
            ),
            fallBackRules: apiData.fallback_rules_id,
            configureTimings: apiData.configure_timings,
            days: apiData.days_mode_id.toString(),
            dayList: initialDayList.map((dayList) => ({
              name: dayList.name,
              isSelected: apiData.days.includes(dayList.name),
            })),
            fromTimeHH: this.getTimes(apiData.start_time.split(":")[0]),
            fromTimeMM: this.getTimes(apiData.start_time.split(":")[1]),
            toTimeHH: this.getTimes(apiData.end_time.split(":")[0]),
            toTimeMM: this.getTimes(apiData.end_time.split(":")[1]),
            stickyAgent: apiData.sticky_agent_toggle,
            stickyAgentOption: apiData.sticky_agent,
            stickyAgentOptionTime: this.getTimes(
              apiData.sticky_agent_ringing_time.toString().padStart(2, "0")
            ),
            queueTimeout: Number(apiData.queue_timeout),
            addNation: apiData.country_code,
            addState: apiData.state,
            skillInput: "",
            skillList: apiData.skills,
            offlineCampaignNumbersInput: "",
            offlineCampaignNumbersList: apiData.offline_campaign_number,
          },
          mainAgentList: [],
        },
        () =>
          this.setState({
            formData: {
              ...this.state.formData,
              addState: apiData.state,
            },
          })
      );
      apiMainAgentList.forEach((agent, index) => {
        this.setState((prevState) => ({
          ...prevState,
          mainAgentList: [
            ...prevState.mainAgentList,
            { agentList: this.getNewFilteredList(), selectedAgentList: [] },
          ],
        }));
        this.handleAgentChange(agent.selectedAgentList, index);
      });
    }
  };
  // Customizable Area End
}
