import React from "react";
import { AppBar, Box, Drawer, IconButton, Link, List, ListItem, Toolbar, Typography, styled } from "@material-ui/core";
// @ts-ignore
import image from "../assets/logoCallifony.png";
// @ts-ignore
import flag from "../assets/flag.png";
// @ts-ignore
import india from "../assets/twemoji_flag-india.svg";
// @ts-ignore
import arrowDown from "../assets/back-up-down-svgrepo-com.svg";
import HeaderLandingController, { Props } from "./HeaderLandingController.web";
import { Menu } from "@material-ui/icons";
const StyledWrapperBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#F4F4F4",
  padding: "24px 130px",
  gap: "10px",
  "@media (max-width: 1360px)": {
    display: "flex",
    flexDirection: "column"
  },
  "@media (max-width: 959px)": {
    padding: "24px 70px"
  },
  "@media (max-width: 600px)": {
    padding: "24px 30px"
  }
});
const StyledLinksBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "14px",
  "@media (max-width: 1024px)": {
    marginBottom: "15px"
  },
  "@media (max-width: 600px)": {
    display: "none"
  }
});
const StyledNumberBox = styled(Box)({
  display: "flex",
  gap: "14px",
  "@media (max-width: 1024px)": {
    display: "none"
  },
  "@media (max-width: 723px)": {
    display: "none"
  }
});
export default class HeaderLanding extends HeaderLandingController {
  constructor(props: Props) {
    super(props);
  }
  handleRenderDrawer() {
    return <List style={{marginTop: "40px"}}>
      {this.links.map(item => (
        <ListItemStyled
          button
          key={item.id}
        >
          <Typography
            style={{ fontSize: "14px", fontFamily: "Inter", fontWeight: 500 }}
          >
            {item.text} 
          </Typography>
          <img style={webStyles.arrowLogo} src={arrowDown} />
        </ListItemStyled>
      ))}
    </List>
  }
  handleRenderAppBar(){
    return <AppBarCustom position="static" color="transparent">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={this.handleDrawerToggle}
          data-testid="btnOpenDrawer"
        >
          <Menu />
        </IconButton>
      </Toolbar>
    </AppBarCustom>
  }
  render() {
    return (
      <StyledWrapperBox id="container__header" style={this.props.style}>
        <Drawer
          variant="temporary"
          open={this.state.isMobileOpen}
          onTransitionEnd={this.handleDrawerTransitionEnd}
          onClose={this.handleDrawerClose}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {this.handleRenderDrawer()}
        </Drawer>
        <LogoContainer>
          {this.handleRenderAppBar()}
          <LogoStyled>
            <img src={image} style={{ width: "126px" }} />
          </LogoStyled>
        </LogoContainer>
        <StyledLinksBox>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Link
              data-test-id="category-type-0"
              style={webStyles.links}
              href=""
            >
              About Us
            </Link>
            <img style={webStyles.arrowLogo} src={arrowDown} />
          </Box>
          <Box>
            <Link style={webStyles.links} href="">
              Products
            </Link>
            <img style={webStyles.arrowLogo} src={arrowDown} />
          </Box>
          <Box>
            <Link style={webStyles.links} href="">
              Solutions
            </Link>
            <img style={webStyles.arrowLogo} src={arrowDown} />
          </Box>
          <Box>
            <Link style={webStyles.links} href="">
              Integration
            </Link>
            <img style={webStyles.arrowLogo} src={arrowDown} />
          </Box>
          <Box>
            <Link style={webStyles.links} href="">
              Developer
            </Link>
          </Box>
        </StyledLinksBox>
        <StyledNumberBox>
          <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img src={india} alt="" />
            <span style={webStyles.number}>+022-555-1231</span>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img src={flag} alt="" />
            <span style={webStyles.number}>+971-212-3553</span>
          </Box>
        </StyledNumberBox>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px"
          }}
        >
          <button
            onClick={() =>
              this.props.navigation.navigate("EmailAccountLoginBlock")
            }
            style={webStyles.btnLogIn}
            data-test-id="testLogBtn"
          >
            Log In
          </button>
          <button
            style={webStyles.btnSignup}
            onClick={() =>
              this.props.navigation.navigate("EmailAccountRegistration")
            }
            data-test-id="testSignBtn"
          >
            Try for Free
          </button>
        </Box>
      </StyledWrapperBox>
    );
  }
}

const AppBarCustom = styled(AppBar)(({ theme }) => ({
  display: "none",
  width: "48px",
  height: "48px",
  justifyContent: "center",
  boxShadow: "none",
  "& .MuiToolbar-root": {
    backgroundColor: "transparent",
    padding: 0
  },
  "& .MuiToolbar-regular": {
    minHeight: "unset"
  },
  [theme.breakpoints.down("xs")]: {
    display: "block"
  }
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "2px",
  cursor: "pointer"
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]:{
    display: "flex",
    alignItems: "center",
    width: "100%"
  }
}));

const LogoStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]:{
    flex: "1",
    textAlign: "center",
    marginRight: "40px"
  }
}));

const webStyles = {
  links: {
    color: "black",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginRight: "3px",
    textDecoration: "none"
  },
  number: {
    color: "black",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  arrowLogo: {
    width: "7px",
    height: "7px"
  },
  btnLogIn: {
    fontSize: "14px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "6px 12px",
    borderRadius: "8px",
    border: "1px solid lightgray",
    background: "white",
    color: "black",
    cursor: "pointer"
  },
  btnSignup: {
    fontSize: "14px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "6px 12px",
    borderRadius: "8px",
    border: "none",
    background: "#09F",
    color: "white",
    cursor: "pointer"
  }
};
