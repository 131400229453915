// Customizable Area Start
import React, { memo } from "react";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { expireTokenHandling } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import { ActionButton, StyledCheckbox } from "../../groups2/src/Agents.web";

export const configJSON = require("./config");
export const images = require("./assets");
const { baseURL } = require("../../../framework/src/config");

type IvrFlowList = {
  ivr_form: {
    data: {
      id: string;
      attributes: {
        id: number;
        name: string;
        flow_type: string;
        app_url: string;
        custom: boolean;
        transfer: boolean;
        created_at: string;
        updated_at: string;
      };
      isSelected: boolean;
    }[];
  };
  search: boolean;
  total_count: number;
  current_page: number;
};

type SuccessData = {
  message: string;
};

type ResponseData = IvrFlowList | SuccessData;
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  ivrFlowListData: IvrFlowList;
  perPage: number;
  search: string;
  selectAll: boolean;
  selectedIds: number[];
  downloadFileType: string;
  bulkDeleteConfirmation: boolean;
  deleteConfirmationId: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class IvrFlowController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
      ivrFlowListData: {
        ivr_form: { data: [] },
        search: false,
        total_count: 0,
        current_page: 1,
      },
      perPage: 10,
      search: "",
      selectAll: false,
      selectedIds: [],
      downloadFileType: "csv",
      bulkDeleteConfirmation: false,
      deleteConfirmationId: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  getIvrFlowListApiCallId: string = "";
  deleteIvrFlowApiCallId: string = "";
  copyIvrFlowApiCallId: string = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  async componentDidMount() {
    this.GetIvrFlowListAPICall();
  }

  handleAPICall = async (apiEndPoint: string, method: string) => {
    const createApiMsg1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };

    createApiMsg1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    createApiMsg1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    runEngine.sendMessage(createApiMsg1.id, createApiMsg1);

    return createApiMsg1.messageId;
  };

  handleReceiveFunction = (
    apiRequestCallId: string,
    responseJson: ResponseData
  ) => {
    if (apiRequestCallId === this.getIvrFlowListApiCallId) {
      this.receiveGetIvrFlowListAPICall(responseJson);
    }
    if (apiRequestCallId === this.deleteIvrFlowApiCallId) {
      this.receiveDeleteIvrFlowAPICall(responseJson);
    }
    if (apiRequestCallId === this.copyIvrFlowApiCallId) {
      this.receiveCopyIvrFlowAPICall(responseJson);
    }
  };

  goToNavigation = (route: string, editId?: string) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    if (editId) {
      const raiseMessage1: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage1.addData("EditIvrFlow", editId);
      navigationMsg.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage1
      );
    }

    this.send(navigationMsg);
  };

  handleSearch = async (value: string) => {
    this.setState({ search: value }, () => {
      if (value.length == 0 || value.length > 2) {
        this.GetIvrFlowListAPICall();
      }
    });
  };

  handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    this.GetIvrFlowListAPICall(page + 1);
  };

  handleRowPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        perPage: +event.target.value,
      },
      () => this.GetIvrFlowListAPICall()
    );
  };

  handleSelectAll = (checked: boolean) => {
    this.setState({ selectAll: !checked }, () =>
      this.handleSelectAllIvrFlowDelete(this.state.selectAll)
    );
  };

  handleSelectAllIvrFlowDelete = (selectAll: boolean) => {
    const newIvrFlowData = this.state.ivrFlowListData.ivr_form.data.map(
      (ivrFlow) => {
        ivrFlow.isSelected = selectAll;
        return ivrFlow;
      }
    );
    const updatedData = {
      ...this.state.ivrFlowListData,
      ivr_form: { data: newIvrFlowData },
    };
    this.setState({ ivrFlowListData: updatedData }, () =>
      this.handleSelectFlowId()
    );
  };

  handleSelectFlowId = () => {
    const filteredData = this.state.ivrFlowListData.ivr_form.data.filter(
      (ivrFlow) => ivrFlow.isSelected
    );
    const ivrFlowIds = filteredData.map((ivrFlow) => ivrFlow.attributes.id);
    this.setState({ selectedIds: ivrFlowIds }, () =>
      this.checkAllSelectedIvrFlow()
    );
  };

  checkAllSelectedIvrFlow = () => {
    if (
      this.state.selectedIds.length ==
      this.state.ivrFlowListData.ivr_form.data.length
    ) {
      this.setState({ selectAll: true });
    } else {
      this.setState({ selectAll: false });
    }
  };

  updateSelectedIvrFlowData = (ivrFlowId: number) => {
    const newIvrFlowData = this.state.ivrFlowListData.ivr_form.data.map(
      (ivrFlow) => {
        if (ivrFlow.attributes.id === ivrFlowId) {
          ivrFlow.isSelected = !ivrFlow.isSelected;
        }
        return ivrFlow;
      }
    );
    const updatedData = {
      ...this.state.ivrFlowListData,
      ivr_form: { data: newIvrFlowData },
    };
    this.setState({ ivrFlowListData: updatedData });
    this.handleSelectFlowId();
  };

  handleOnDownloadFileTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown | string }>
  ) => {
    this.setState({
      downloadFileType: event.target.value as string,
    });
  };

  handleDownloadBtn = async () => {
    const userId = await getStorageData("userId");
    window.open(
      `${baseURL}/${configJSON.ivrFlowApiEndPoint}/download_file?format=${this.state.downloadFileType}&account_id=${userId}`
    );
  };

  handleBulkDeleteConfirmationDialog = () => {
    this.setState({
      bulkDeleteConfirmation: !this.state.bulkDeleteConfirmation,
    });
  };

  handleDeleteConfirmationDialog = (agentId: number) => {
    this.setState({ deleteConfirmationId: agentId });
  };

  GetIvrFlowListAPICall = async (page = 1) => {
    this.setState({
      isLoading: true,
      selectAll: false,
      selectedIds: [],
    });
    this.getIvrFlowListApiCallId = await this.handleAPICall(
      `${configJSON.ivrFlowApiEndPoint}?page=${page}&per_page=${this.state.perPage}&query=${this.state.search}`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetIvrFlowListAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as IvrFlowList;
    if (responseJsonData && responseJsonData.ivr_form) {
      this.setState({
        ivrFlowListData: {
          ...responseJsonData,
          ivr_form: {
            data: responseJsonData.ivr_form.data.map((ivrData) => ({
              ...ivrData,
              isSelected: false,
            })),
          },
        },
        isLoading: false,
      });
    }
  };

  handleDeleteIvrFlow = async (deleteId: number | number[]) => {
    this.setState({
      isLoading: true,
      bulkDeleteConfirmation: false,
      deleteConfirmationId: 0,
    });
    this.deleteIvrFlowApiCallId = await this.handleAPICall(
      `${configJSON.ivrFlowApiEndPoint}?form_ids=[${deleteId}]`,
      configJSON.deleteAPiMethod
    );
  };

  receiveDeleteIvrFlowAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as SuccessData;
    if (responseJsonData && responseJsonData.message) {
      this.GetIvrFlowListAPICall();
    }
  };

  handleCopyIvrFlow = async (copyId: number) => {
    this.copyIvrFlowApiCallId = await this.handleAPICall(
      `${configJSON.ivrFlowApiEndPoint}/${copyId}/duplicate`,
      configJSON.exampleAPiMethod
    );
  };

  receiveCopyIvrFlowAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as SuccessData;
    if (responseJsonData && responseJsonData.message) {
      this.GetIvrFlowListAPICall();
    }
  };

  renderNoData = () => {
    return this.state.isLoading ? (
      <></>
    ) : (
      <Box className="ivrFlowDataContainer" data-test-id="no-data">
        <Box className="ivrFlowDataInnerContainer">
          <img src={images.clipboards} className="noDataImage" />
          <Typography className="ivrFlowDataMainText">
            {configJSON.ivrFlowDataMainText}
          </Typography>
          <Typography className="ivrFlowDataSubText">
            {configJSON.ivrFlowDataSubText}
          </Typography>
        </Box>
      </Box>
    );
  };

  renderNoSearchData() {
    return (
      <Box className="ivrFlowDataContainer" data-test-id="no-search-data">
        <Box className="ivrFlowDataInnerContainer">
          <img src={images.noResultFound} className="noDataImage" />
          <Typography className="ivrFlowDataMainText">
            {configJSON.noResultFound}
          </Typography>
          <Typography className="ivrFlowDataSubText">
            {configJSON.noIvrFlowMatched}
          </Typography>
        </Box>
      </Box>
    );
  }

  renderIvrFlowNoData() {
    return this.state.ivrFlowListData.search
      ? this.renderNoSearchData()
      : this.renderNoData();
  }

  renderView() {
    return this.state.ivrFlowListData.ivr_form.data.length ? (
      <TableComponent
        data-test-id="tableComponent"
        goToNavigation={this.goToNavigation}
        handleCopyIvrFlow={this.handleCopyIvrFlow}
        handleDeleteConfirmationDialog={this.handleDeleteConfirmationDialog}
        handlePageChange={this.handlePageChange}
        handleRowsPerPageChange={this.handleRowPerPageChange}
        handleSelectAll={this.handleSelectAll}
        ivrFlowListData={this.state.ivrFlowListData}
        rowsPerPage={this.state.perPage}
        selectAll={this.state.selectAll}
        selectedIds={this.state.selectedIds}
        updateSelectedIvrFlowData={this.updateSelectedIvrFlowData}
      />
    ) : (
      this.renderIvrFlowNoData()
    );
  }
  // Customizable Area End
}

interface TableComponentProps {
  ivrFlowListData: IvrFlowList;
  rowsPerPage: number;
  updateSelectedIvrFlowData: (id: number) => void;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  goToNavigation: (path: string, id: string) => void;
  handleCopyIvrFlow: (id: number) => void;
  handleDeleteConfirmationDialog: (id: number) => void;
  selectAll: boolean;
  handleSelectAll: (checked: boolean) => void;
  selectedIds: number[];
}

const TableComponent: React.FC<TableComponentProps> = memo((props) => {
  const {
    ivrFlowListData,
    updateSelectedIvrFlowData,
    handlePageChange,
    handleRowsPerPageChange,
    goToNavigation,
    handleCopyIvrFlow,
    handleDeleteConfirmationDialog,
    selectAll,
    handleSelectAll,
    selectedIds,
  } = props;

  return (
    <TableContainer component={Paper} style={{ boxShadow: "none" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="tableHeader">
              <StyledCheckbox
                data-test-id="select_all"
                checked={selectAll}
                onChange={(event, checked) => handleSelectAll(!checked)}
              />
            </TableCell>
            <TableCell className="tableHeader">
              {configJSON.ivrFlowId}
            </TableCell>
            <TableCell className="tableHeader">{configJSON.flowName}</TableCell>
            <TableCell className="tableHeader">
              {configJSON.createdDate}
            </TableCell>
            <TableCell className="tableHeader">
              {configJSON.lastModifiedDate}
            </TableCell>
            <TableCell className="tableHeader">{configJSON.design}</TableCell>
            <TableCell className="tableHeader"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ivrFlowListData.ivr_form.data.map((ivrForm) => (
            <TableRow key={ivrForm.id} data-test-id="ivrFlowTableRow">
              <TableCell component="th" scope="row" className="tableRow">
                <StyledCheckbox
                  onClick={() =>
                    updateSelectedIvrFlowData(ivrForm.attributes.id)
                  }
                  data-test-id="checkbox-delete"
                  checked={ivrForm.isSelected}
                />
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {ivrForm.id.padStart(4, "0")}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {ivrForm.attributes.name}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {ivrForm.attributes.created_at}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {ivrForm.attributes.updated_at}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                <Button
                  data-test-id="design-ivr-flow"
                  variant="contained"
                  className="ivrFlowHeaderButton designButton"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  disabled={!!selectedIds.length}
                >
                  {configJSON.design}
                </Button>
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                <ActionButton
                  data-test-id="editButton"
                  name="edit"
                  size="small"
                  onClick={() => goToNavigation("AddIvrFlow", ivrForm.id)}
                  disabled={!!selectedIds.length}
                >
                  <img src={images.edit} alt="Edit" />
                </ActionButton>
                <ActionButton
                  data-test-id="copyButton"
                  name="copy"
                  size="small"
                  onClick={() => handleCopyIvrFlow(ivrForm.attributes.id)}
                  disabled={!!selectedIds.length}
                >
                  <img src={images.copy} alt="Copy" />
                </ActionButton>
                <ActionButton
                  data-test-id="deleteButton"
                  name="delete"
                  size="small"
                  onClick={() =>
                    handleDeleteConfirmationDialog(ivrForm.attributes.id)
                  }
                  disabled={!!selectedIds.length}
                >
                  <img src={images.deleteIcon} alt="Delete" />
                </ActionButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className="borderNone"
              colSpan={8}
              count={ivrFlowListData.total_count}
              rowsPerPage={props.rowsPerPage}
              page={ivrFlowListData.current_page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
});
