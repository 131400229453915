import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { removeStorageData } from "framework/src/Utilities";
import { expireTokenHandling } from "../../../components/src/Utilities";

// Customizable Area Start
export const configJSON = require("./config");
export const images = require("./assets");

export interface IBenefitData {
  id: string;
  title: string;
  description: string;
  logo_image_url: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailValue: string;
  headerResponse: {
    id: string;
    company_heading: string;
    company_subheading: string;
  };
  logoImagesResponse: {
    id: string;
    type: string;
    attributes: { logo_image: string };
  }[];
  benefitResponse: {
    id: string;
    title: string;
    description: string;
    benefit_cards: IBenefitData[];
  };
  textImageResponse: {
    id: string;
    heading: string;
    description: string;
    pointer_1: string;
    pointer_2: string;
    pointer_3: string;
    pointer_4: string;
    category: string;
  }[];
  logoIntegrationResponse: {
    id: string;
    type: string;
    attributes: { logo_image: string };
  }[];
  resultsRevenueResponse: {
    id: string;
    revenue_number: string;
    revenue_tag: string;
    description: string;
    author_name: string;
    author_description: string;
    profile_image_url: string;
  }[];
  customerFeedbackResponse: {
    id: string;
    description: string;
    author_name: string;
    designation: string;
    profile_image_url: string;
  }[];
  blogListingResponse: {
    id: string;
    name: string;
    category_name: string;
    author_name: string;
    images_and_videos: string;
    created_at: string;
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      emailValue: "",
      headerResponse: { id: "", company_heading: "", company_subheading: "" },
      logoImagesResponse: [],
      benefitResponse: {
        id: "",
        title: "",
        description: "",
        benefit_cards: [],
      },
      textImageResponse: [],
      logoIntegrationResponse: [],
      resultsRevenueResponse: [],
      customerFeedbackResponse: [],
      blogListingResponse: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson)

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  headerApiCallId: string = "";
  logoImagesApiCallId: string = "";
  benefitApiCallId: string = "";
  textImageApiCallId: string = "";
  logoIntegrationApiCallId: string = "";
  resultsRevenueApiCallId: string = "";
  customerFeedbackApiCallId: string = "";
  blogListingApiCallId: string = "";

  textImageStaticData = [
    { title: "Sales", image: images.salesImage },
    { title: "Marketing", image: images.marketingImage },
    { title: "Service", image: images.serviceImage },
  ];

  benefitStaticData = [
    { image: images.customer, selectedImage: images.customerSelected },
    { image: images.grow, selectedImage: images.growSelected },
    { image: images.support, selectedImage: images.supportSelected },
  ];

  async componentDidMount() {
    await this.getHeaderData();
    await this.getLogoImagesData();
    await this.getBenefitData();
    await this.getTextImageData();
    await this.getLogoIntegrationData();
    await this.getResultsRevenueData();
    await this.getCustomerFeedbackData();
    await this.getBlogListingData();
    await this.handleResetAuth();
  }

  handleAPICall = (apiEndPoint: string) => {
    const headers = { "Content-Type": configJSON.validationApiContentType };

    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleResetAuth = async () => {
    await removeStorageData("authToken");
    await removeStorageData("isDashboard");
    await removeStorageData("name");
    await removeStorageData("data");
    await removeStorageData("userId");
  };

  handleReceiveFunction = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.headerApiCallId:
        this.receiveHeaderAPICall(responseJson);
        break;
      case this.logoImagesApiCallId:
        this.receiveLogoImagesAPICall(responseJson);
        break;
      case this.benefitApiCallId:
        this.receiveBenefitAPICall(responseJson);
        break;
      case this.textImageApiCallId:
        this.receiveTextImageAPICall(responseJson);
        break;
      case this.logoIntegrationApiCallId:
        this.receiveLogoIntegrationAPICall(responseJson);
        break;
      case this.resultsRevenueApiCallId:
        this.receiveResultsRevenueAPICall(responseJson);
        break;
      case this.customerFeedbackApiCallId:
        this.receiveCustomerFeedbackAPICall(responseJson);
        break;
      case this.blogListingApiCallId:
        this.receiveBlogListingAPICall(responseJson);
      default:
        break;
    }
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleTryForFree = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegistration"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.NavigationSignUpDataMessage), {
      email: this.state.emailValue,
      name: "",
      companyName: "",
      mobileNumber: "",
      countryCode: "IN",
    });

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  goToBlogListing = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "BlogListing");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.setState({ emailValue: event.target.value });
  };

  getHeaderData = () => {
    this.headerApiCallId = this.handleAPICall(configJSON.headerApiEndPoint);
  };

  receiveHeaderAPICall = (responseJson: any) => {
    if (responseJson.header_section) {
      this.setState({ headerResponse: responseJson.header_section });
    }
  };

  getLogoImagesData = () => {
    this.logoImagesApiCallId = this.handleAPICall(
      configJSON.logoImagesApiEndPoint
    );
  };

  receiveLogoImagesAPICall = (responseJson: any) => {
    if (responseJson.logo_section && responseJson.logo_section.data) {
      this.setState({ logoImagesResponse: responseJson.logo_section.data });
    }
  };

  getBenefitData = () => {
    this.benefitApiCallId = this.handleAPICall(configJSON.benefitApiEndPoint);
  };

  receiveBenefitAPICall = (responseJson: any) => {
    if (responseJson.benefits_card_section) {
      this.setState({
        benefitResponse: responseJson.benefits_card_section,
      });
    }
  };

  getTextImageData = () => {
    this.textImageApiCallId = this.handleAPICall(
      configJSON.textImageApiEndPoint
    );
  };

  receiveTextImageAPICall = (responseJson: any) => {
    if (responseJson.text_and_image_section) {
      this.setState({
        textImageResponse: responseJson.text_and_image_section,
      });
    }
  };

  getLogoIntegrationData = () => {
    this.logoIntegrationApiCallId = this.handleAPICall(
      configJSON.logoIntegrationApiEndPoint
    );
  };

  receiveLogoIntegrationAPICall = (responseJson: any) => {
    if (responseJson.logo_section && responseJson.logo_section.data) {
      this.setState({
        logoIntegrationResponse: responseJson.logo_section.data,
      });
    }
  };

  getResultsRevenueData = () => {
    this.resultsRevenueApiCallId = this.handleAPICall(
      configJSON.resultsRevenueApiEndPoint
    );
  };

  receiveResultsRevenueAPICall = (responseJson: any) => {
    if (responseJson.results_and_revenue_sections) {
      this.setState({
        resultsRevenueResponse: responseJson.results_and_revenue_sections,
      });
    }
  };

  getCustomerFeedbackData = () => {
    this.customerFeedbackApiCallId = this.handleAPICall(
      configJSON.customerFeedbackApiEndPoint
    );
  };

  receiveCustomerFeedbackAPICall = (responseJson: any) => {
    if (responseJson.customer_feedback) {
      this.setState({
        customerFeedbackResponse: responseJson.customer_feedback,
      });
    }
  };

  getBlogListingData = () => {
    this.blogListingApiCallId = this.handleAPICall(
      configJSON.blogListingApiEndPoint
    );
  };

  receiveBlogListingAPICall = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        blogListingResponse: responseJson.data.map(
          (item: any) => item.attributes
        ),
      });
    }
  };
  // Customizable Area End
}
