import React from "react";

import {
    Container,
    Box,
    Typography,
    styled,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AgentActionController, { Props, TTabIndex } from "./AgentActionController.web";
import AddAgent from "./AddAgent.web";
import { group } from "./assets";
import AddBulkAgent from "../../importexportdata/src/AddBulkAgent.web";
import Layout from "../../../components/src/Layout.web";
const TAB_ACTION: {
    id: TTabIndex,
    label: string
}[] = [
    {
        id: 'addAgent',
        label: "Add Agents"
    },
    {
        id: 'bulkAgent',
        label: "Add Bulk Agents"
    },
]
// Customizable Area End

export default class AgentAction extends AgentActionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderChildrenTab = () => {
        if(this.state.tabIndexValue === "bulkAgent")
            return <AddBulkAgent {...this.props} />
        return <AddAgent {...this.props}/>
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Layout menuItem="agent" navigation={this.props.navigation}>
              <Box display="inline-flex" alignItems="center" gridGap={24}>
                  <img src={group}
                  width={64}
                  height={48}
                  />
                  <Typography style={styles.labelContainer} component="h2">Agents</Typography>
              </Box>
              <AgentActionTabContainer data-test-id="agentActionTabContainer">
                  <Container maxWidth = 'xl' className="tabContainer">
                      <Box className="tabBox">
                          {TAB_ACTION.map((tabObject) => (
                              <Box key={tabObject.id} className={`tabItem ${tabObject.id !== this.state.tabIndexValue ? "tabItem-disable" : ""}`} onClick={() => this.handleChangeTab(tabObject.id)} data-testid="tabItem">
                                  <Typography component="h3" className="labelAction">{tabObject.label}</Typography>
                              </Box>
                          ))}
                      </Box>
                  </Container>
                  <ActionContainer data-test-id="actionContainer">
                      {this.renderChildrenTab()}
                  </ActionContainer>
              </AgentActionTabContainer>
            </Layout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
  labelContainer: {
      fontWeight: 700,
      fontFamily: "Poppins",
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "-0.005em",
      color: "#281D19",
  }
} as const

const ActionContainer = styled(Container)(({theme}) => ({
    margin: "2px 0 0",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 40px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px 0px #00000008",
    background: "#FFFFFF",
    [theme.breakpoints.down(710)]: {
        padding: "0 8px",
    }
}))

export const AgentActionContainer = styled(Box)(() => ({
    background: "#F9F9F9",
    padding: "40px 32px",
    flex: 1,
    "& .container":{
        padding: 0,
    }
}))
export const AgentActionTabContainer = styled(Box)(({theme}) => ({
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px 8px 0 0",
    "& .tabContainer": {
        background: "#FFFFFF",
        padding: "0 40px",
        borderRadius: "8px 8px 0 0",
        boxShadow: "0px 4px 8px 0px #00000008",
        "& .tabBox": {
            display: "flex",
            height: "100%"
        }
    },
    "& .tabItem": {
        display: 'flex',
        cursor: "pointer",
        height: "45px",
        margin: "18px 44px 18px 0px",
        alignItems: "center",
    }, 
    "& .tabItem-disable":{
        "& .labelAction":{
            color: "#64748B"
        }
    },
    "& .labelAction": {
        backgroundClip: "text",
        background: "linear-gradient(99.09deg, #1676C0 2.64%, #13A9EC 100%)",
        color: "#0099FF",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        fontFamily: "Poppins",
    },
    [theme.breakpoints.down(710)]: {
        "& .tabItem":{
            marginRight: "8px"
        },
        "& .labelAction":{
            fontSize: "18px"
        },
        "& .tabContainer":{
            padding: "0 8px"
        }
    }
}))
// Customizable Area End
