Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "callrouting2";
exports.labelBodyText = "callrouting2 Body";
exports.deleteAPiMethod = "DELETE";
exports.patchAPiMethod = "PATCH";

exports.btnExampleTitle = "CLICK ME";

exports.campaign = "Campaign";
exports.createInboundIVR = "Create Inbound IVR";
exports.createOutboundIVR = "Create Outbound IVR";
exports.campaignNoDataMainText = "No Data Available";
exports.campaignNoDataSubText = "There is no data show you right now.";
exports.inboundIVR = "Inbound IVR";
exports.campaignName = "Campaign Name";
exports.selectDID = "Select DID";
exports.selectIVR = "Select IVR";
exports.select = "Select";
exports.selectDepartment = "Select Departments";
exports.departments = "Departments";
exports.enableRecording = "Enable Recording";
exports.enableClick2Call = "Enable Click2Call";
exports.callBackURL = "Call Back URL";
exports.blacklistGroup = "Blacklist Group";
exports.whitelistGroup = "Whitelist Group";
exports.selectGroup = "Select Group";
exports.group = "Group";
exports.wrapUpTime = "Wrap up time";
exports.dispositions = "Dispositions";
exports.apiInput = "API";
exports.hitScreenPOPTrigger = "Hit ScreenPOP Trigger";
exports.screenPOPURL = "ScreenPOP URL";
exports.save = "Save";
exports.campaignDID = "Campaign DID";
exports.campaignDate = "Campaign Date";
exports.campaignTime = "Campaign Time";
exports.action = "Action";
exports.nameErrorMessage = "This campaign name is already in use.";
exports.noResultFound = "No Result found";
exports.noInBoundMatched = "No Inbound IVR matched your Criteria. Try Searching for something else.";
exports.edit = "Edit";
exports.delete = "Delete";
exports.selectAll = "Select all";
exports.unselectAll = "Unselect all";
exports.cancel = "Cancel";

exports.getDepartmentApiEndPoint = "bx_block_account_groups/departments";
exports.getDIDNumberApiEndPoint = "bx_block_interactivevoiceresponseivr/did_numbers/get_company_did";
exports.getGroupApiEndPoint = "bx_block_callrouting2/campaigns/group_lists";
exports.campaignApiEndPoint = "bx_block_callrouting2/campaigns";
// Customizable Area End