// Customizable Area Start
import React from "react";
import EditContactController, {
  Props,
  images,
  configJSON,
} from "./EditContactController";
import Layout from "../../../components/src/Layout.web";
import { Box, Typography, styled } from "@material-ui/core";
import AddContact from "./AddContact.web";
// Customizable Area End

export default class EditContact extends EditContactController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout menuItem="addressBook" navigation={this.props.navigation}>
        <MainContainer data-test-id="editContactComponent">
          <Box className="editContactHeader">
            <Box className="headerBox">
              <img src={images.group} width={64} height={48} />
              <Typography className="editContactHeaderTitle">
                {configJSON.addressBook}
              </Typography>
            </Box>
          </Box>
          <Box className="editContactContainer">
            {!!this.state.editContactId && (
              <AddContact
                id={this.props.id}
                navigation={this.props.navigation}
                editContactId={this.state.editContactId}
              />
            )}
          </Box>
        </MainContainer>
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(() => ({
  gap: "20px",
  display: "grid",
  "& .editContactContainer": {
    padding: "20px 40px",
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px 0px #00000008",
    "@media (max-width:600px)": {
      padding: "8px",
    },
  },
  "& .editContactHeader": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
    padding: "5px",
  },
  "& .editContactHeaderTitle": {
    color: "#281D19",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "32px",
  },
  "& .headerBox": {
    display: "inline-flex",
    alignItems: "center",
    gap: "24px",
    flexWrap: "wrap",
  },
}));
// Customizable Area End
