// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  styled,
  InputBase,
  FormGroup,
  Select,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import AddContactController, {
  Props,
  configJSON,
} from "./AddContactController";
// Customizable Area End

export default class AddContact extends AddContactController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <FormGroup>
        <AddContactInformationController data-test-id="addContact-container">
          <InputLabel
            className="InputLabelCustom"
            style={{ fontSize: "20px", lineHeight: "28px" }}
          >
            {this.props.editContactId
              ? configJSON.editContact
              : configJSON.addContact}
          </InputLabel>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="name">
              {configJSON.name}*
            </InputLabel>
            <InputBase
              data-test-id="name"
              className="InputBaseCustom"
              id="name"
              name="name"
              type="text"
              value={this.state.formData.name}
              onChange={this.handleChange}
            />
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="company">
              {configJSON.company}
            </InputLabel>
            <InputBase
              data-test-id="company"
              className="InputBaseCustom"
              id="company"
              name="company"
              type="text"
              value={this.state.formData.company}
              onChange={this.handleChange}
            />
          </Box>
          <Box>
            <InputLabel
              className="InputLabelCustom"
              error={!!this.state.formErrors.contact_number}
              htmlFor="contact_number"
            >
              {configJSON.contactNumber}*
            </InputLabel>
            <InputBase
              data-test-id="contact_number"
              className="InputBaseCustom"
              id="contact_number"
              name="contact_number"
              type="text"
              value={this.state.formData.contact_number}
              onChange={this.handleChange}
              error={!!this.state.formErrors.contact_number}
              onBlur={() => this.handleOnBlur("contact_number")}
            />
            {!!this.state.formErrors.contact_number && (
              <Typography color="error" data-test-id="number-error">
                {this.state.formErrors.contact_number}
              </Typography>
            )}
          </Box>
          <Box>
            <InputLabel
              className="InputLabelCustom"
              error={!!this.state.formErrors.email}
              htmlFor="email"
            >
              {configJSON.emailId}
            </InputLabel>
            <InputBase
              data-test-id="email"
              className="InputBaseCustom"
              id="email"
              name="email"
              type="text"
              value={this.state.formData.email}
              onChange={this.handleChange}
              error={!!this.state.formErrors.email}
              onBlur={() => this.handleOnBlur("email")}
            />
            {!!this.state.formErrors.email && (
              <Typography color="error">
                {this.state.formErrors.email}
              </Typography>
            )}
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="contact_group_id">
              {configJSON.chooseGroup}*
            </InputLabel>
            <Select
              data-test-id="contact_group_id"
              className="SelectCustom"
              id="contact_group_id"
              name="contact_group_id"
              value={this.state.formData.contact_group_id}
              onChange={(event) => this.handleChange(event)}
              IconComponent={ExpandMoreRoundedIcon}
              MenuProps={{
                className: "menuProps",
              }}
            >
              {this.state.groupList.map((group) => (
                <MenuItem
                  key={group.id}
                  value={group.id}
                  style={styles.menuItem}
                >
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Button
              data-test-id="saveButton"
              type="submit"
              className="ButtonCustom"
              onClick={this.handleSave}
              disabled={this.isSaveBtnDisabled()}
            >
              {configJSON.save}
            </Button>
          </Box>
        </AddContactInformationController>
      </FormGroup>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  menuItem: {
    fontSize: "14px",
  },
} as const;

export const AddContactInformationController = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gridRowGap: "20px",
  maxWidth: "100%",
  "& .ButtonCustom": {
    maxWidth: "520px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "8px",
    padding: "16px",
    color: "#FFFFFF",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    margin: "60px 0",
    "&:disabled": {
      backgroundColor: "#E2E8F0",
      borderColor: "#CBD5E1",
      color: "#334155",
    },
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0099FF",
      borderColor: "#0099FF",
    },
  },
  "& .InputLabelCustom": {
    color: "#334155",
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Poppins",
    lineHeight: "26px",
  },
  "& .MuiFormLabel-root.Mui-error": {
    color: "#DC2626",
  },
  "& .MuiTypography-colorError": {
    color: "#DC2626",
  },
  "& .InputBaseCustom": {
    maxWidth: "520px",
    width: "100%",
    marginTop: "4px",
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: "#DC2626",
        color: "#DC2626",
      },
    },
    "& .MuiInputBase-input": {
      height: "34px",
      borderRadius: 8,
      maxWidth: "520px",
      position: "relative",
      border: "1px solid",
      borderColor: "#CBD5E1",
      backgroundColor: "#FFFFFF",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
    },
  },
  "& .SelectCustom": {
    width: "100%",
    marginTop: "4px",
    maxWidth: "520px",
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
    "& .MuiInputBase-input": {
      height: "34px",
      borderRadius: 8,
      position: "relative",
      maxWidth: "520px",
      border: "1px solid",
      borderColor: "#CBD5E1",
      backgroundColor: "#FFFFFF",
      fontSize: 16,
      width: "100%",
      alignItems: "center",
      padding: "10px 12px",
      display: "flex",
    },
    "& .MuiSelect-icon": {
      right: 1,
      background: "white",
      padding: "0 7px",
    },
  },
  "& .menuProps": {
    borderRadius: "8px",
    padding: "12px",
    border: "1px solid #CBD5E1",
    minWidth: "494px",
  },
}));
// Customizable Area End
