import React from "react";
import {
    Box,
    styled,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Calendar from 'react-calendar';
import CalendarViewController, { Props } from "./CalendarViewController.web";
// Customizable Area End

export default class CalendarView extends CalendarViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <CalendarViewStyled>
                <Calendar
                    locale="en-GB"
                    view="month"
                    activeStartDate={this.handleGetActiveStartDate(this.props.month, this.props.year)}
                    formatMonthYear={(locale, date) => this.formatLabelMonthYear(locale, date)}
                    prev2Label={null}
                    next2Label={null}
                    prevLabel={null}
                    nextLabel={null}
                    tileClassName={(props) => this.handleTileClass({ ...props }, this.props.month)} />
            </CalendarViewStyled>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const CalendarViewStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    "& .react-calendar": {
        pointerEvents: "none",
        border: "none",
        width: "340px"
    },
    "& .react-calendar__navigation": {
        margin: "0 -10px"
    },
    "& .react-calendar__navigation button": {
        fontSize: "24px",
        color: "#334155",
        "& .react-calendar__navigation__label__labelText": {
            fontSize: "14px",
            fontWeight: 700,
            fontFamily: "Poppins",
            lineHeight: "22px",
            color: "#0F172A"
        }
    },
    "& .react-calendar__month-view__weekdays": {
        gap: "10px"
    },
    "& .react-calendar__month-view__weekdays__weekday": {
        maxWidth: "40px",
        padding: "0 0 12px"
    },
    "& .react-calendar__month-view__weekdays__weekday abbr": {
        textDecoration: "none",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#64748B",
        textTransform: "capitalize"
    },
    "& .react-calendar__tile,.react-calendar__month-view__days__day--weekend abbr,.react-calendar__month-view__days__day--neighboringMonth abbr": {
        maxWidth: "40px",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 400,
        color: "#0F172A"
    },
    "& .react-calendar__tile--active": {
        background: "#CCEBFF",
        color: "#0099FF",
        borderTop: "1px solid #99D6FF",
        borderBottom: "1px solid #99D6FF"
    },
    "& .react-calendar__tile--active.react-calendar__tile--now ": {
        background: "#CCEBFF"
    },
    "& .react-calendar__tile--active.react-calendar__month-view__days__day--weekend abbr, .react-calendar__tile--active.react-calendar__month-view__days__day--neighboringMonth abbr":{
        color: "#0099FF"
    },
    "& .react-calendar__tile--now": {
        background: "none"
    },
    "& .react-calendar__tile--rangeBothEnds": {
        border: "1px solid #99D6FF",
        borderRadius: "20px",
        maxWidth: "40px"
    },
    "& .react-calendar__tile--rangeStart": {
        borderRadius: "20px 0 0 20px",
        borderLeft: "1px solid #99D6FF"
    },
    "& .react-calendar__tile--rangeEnd": {
        borderRadius: "0 20px 20px 0",
        borderRight: "1px solid #99D6FF"
    },
    "& .react-calendar__tile--hover": {
        background: "#E5F5FF"
    },
    "& .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus": {
        background: "#e6e6e6"
    },
    "& .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus": {
        color: "#0099FF",
        background: "#CCEBFF",
        maxWidth: "40px"
    },
    "& .react-calendar__month-view__days": {
        gap: "10px"
    },
    "& .react-calendar__month-view__days__day--weekend,.react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury": {
        color: "#0F172A"
    },

    "& .react-calendar__tile--active.react-calendar__month-view__days__day--weekend": {
        color: "#0099FF"
    },
    "& .lessThanToday": {
        color: "#868a94"
    }
}))


// Customizable Area End
