// Customizable Area Start
import React from "react";
import ForgotPasswordController, {
  Props,
  configJSON,
  images,
} from "./ForgotPasswordController.web";
import {
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Typography,
  styled,
} from "@material-ui/core";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainContainer>
        <div className="secondContainer">
          <div className="logoDiv">
            <img src={images.logoCallifony} />
          </div>
          {this.getResetOrForgotComponent(
            <div className="forgotPasswordSubTitle">
              {configJSON.forgotPasswordSubTitle}
            </div>,
            <div className="resetPasswordTitle">
              {configJSON.resetPasswordTitle}
            </div>
          )}
          {this.getResetOrForgotComponent(
            <>
              <div className="inputDiv">
                <InputLabel
                  className="inputTitle"
                  htmlFor="emailId"
                  error={!!this.state.emailIdError}
                >
                  {configJSON.emailId} *
                </InputLabel>
                <Input
                  disableUnderline
                  data-test-id="emailId"
                  className="inputBox"
                  placeholder={`Enter ${configJSON.emailId}`}
                  fullWidth={true}
                  name="emailId"
                  id="emailId"
                  value={this.state.emailId}
                  onChange={this.handleInputChange}
                  error={!!this.state.emailIdError}
                />
                {!!this.state.emailIdError && (
                  <Typography
                    className="fieldError"
                    data-test-id="emailIdError"
                  >
                    {this.state.emailIdError}
                  </Typography>
                )}
                {!!this.state.sendLinkSuccess && (
                  <Typography
                    className="fieldSuccess"
                    data-test-id="fieldSuccess"
                  >
                    {this.state.sendLinkSuccess}
                  </Typography>
                )}
              </div>
            </>,
            <>
              <div className="inputDiv">
                <InputLabel
                  className="inputTitle"
                  htmlFor="newPassword"
                  error={!!this.state.newPasswordError}
                >
                  {configJSON.newPassword} *
                </InputLabel>
                <Input
                  disableUnderline
                  data-test-id="newPassword"
                  className="inputBox"
                  fullWidth={true}
                  type={this.state.enableNewPasswordField ? "password" : "text"}
                  name="newPassword"
                  id="newPassword"
                  value={this.state.newPassword}
                  onChange={this.handleInputChange}
                  error={!!this.state.newPasswordError}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          this.handleClickShowPassword("enableNewPasswordField")
                        }
                        edge="end"
                      >
                        {this.state.enableNewPasswordField ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!this.state.newPasswordError && (
                  <Typography
                    className="fieldError"
                    data-test-id="newPasswordError"
                  >
                    {this.state.newPasswordError}
                  </Typography>
                )}
              </div>
              <div className="inputDiv">
                <InputLabel
                  className="inputTitle"
                  htmlFor="confirmPassword"
                  error={!!this.state.confirmPasswordError}
                >
                  {configJSON.confirmPassword} *
                </InputLabel>
                <Input
                  disableUnderline
                  data-test-id="confirmPassword"
                  className="inputBox"
                  fullWidth={true}
                  type={
                    this.state.enableConfirmPasswordField ? "password" : "text"
                  }
                  name="confirmPassword"
                  id="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.handleInputChange}
                  error={!!this.state.confirmPasswordError}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          this.handleClickShowPassword(
                            "enableConfirmPasswordField"
                          )
                        }
                        edge="end"
                      >
                        {this.state.enableConfirmPasswordField ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!this.state.confirmPasswordError && (
                  <Typography className="fieldError">
                    {this.state.confirmPasswordError}
                  </Typography>
                )}
              </div>
            </>
          )}
          <div className="buttonAndLinkDiv">
            {this.getResetOrForgotComponent(
              <Button
                className="customButton"
                data-test-id="sendLinkBtn"
                disabled={this.handleButtonDisable()}
                onClick={this.handleSendLinkClick}
              >
                {configJSON.sendLink}
              </Button>,
              <Button
                className="customButton"
                data-test-id="saveBtn"
                disabled={this.handleButtonDisable()}
                onClick={this.handleSaveClick}
              >
                {configJSON.save}
              </Button>
            )}
            <Typography className="spanSignUpTitle">
              {configJSON.dontHaveAccount}
              <Link
                className="signUpLink"
                onClick={() => this.goToNavigation("EmailAccountRegistration")}
                data-test-id="signUpBtn"
              >
                {configJSON.signUp}
              </Link>
            </Typography>
          </div>
        </div>
        <div className="mainDivImg">
          <img src={images.groupImage} data-test-id="rightSideImage" />
          <div className="text-div">
            <Typography className="bottomTitle">
              {configJSON.bottomTitle}
            </Typography>
            <Typography className="bottomSubTitle">
              {configJSON.bottomSubTitle}
            </Typography>
          </div>
        </div>
      </MainContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const MainContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  background: "white",
  "& .secondContainer": {
    width: "35%",
    padding: "57px 94px 104px 120px",
    "& .logoDiv": {
      textAlign: "center",
      "& img": {
        width: "180px",
      },
    },
    "& .forgotPasswordSubTitle": {
      fontFamily: "Poppins",
      color: "#281D19",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "left",
      marginTop: "52px",
      marginBottom: "40px",
    },
    "& .resetPasswordTitle": {
      fontFamily: "Poppins",
      color: "#281D19",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      textAlign: "center",
      marginTop: "30px",
      marginBottom: "78px",
    },
    "& .inputDiv": {
      marginBottom: "24px",
      "& .inputTitle": {
        color: "#334155",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "4px",
        "&.Mui-error": {
          color: "#DC2626",
        },
      },
      "& .inputBox": {
        padding: "10px 8px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        color: "#0F172A",
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "24px",
        "&.Mui-error": {
          borderColor: "#F87171",
          color: "#DC2626",
        },
      },
      "& .fieldError": {
        color: "#DC2626",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        marginTop: "10px",
      },
      "& .fieldSuccess": {
        color: "#334155",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        marginTop: "10px",
      },
    },
    "& .buttonAndLinkDiv": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& .customButton": {
        width: "100%",
        marginTop: "16px",
        padding: "16px",
        fontSize: "16px",
        fontFamily: "Poppins",
        textTransform: "capitalize",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        borderRadius: "8px",
        background: "#0099FF",
        color: "white",
        marginBottom: "16px",
        border: "1px solid #0099FF",
        "&:hover": {
          background: "#0099FF",
        },
        "&:disabled": {
          background: "#E2E8F0",
          color: "#334155",
          borderColor: "#CBD5E1",
        },
      },
      "& .spanSignUpTitle": {
        color: "#0F172A",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        lineHeight: "24px",
        fontWeight: 400,
        textWrap: "nowrap",
        "& .signUpLink": {
          marginLeft: "8px",
          cursor: "pointer",
          fontWeight: 700,
          color: "#0099FF",
        },
      },
    },
    "@media(max-width:959px)": {
      width: "100%",
    },
    "@media(max-width:600px)": {
      padding: "30px 45px",
    },
  },
  "& .mainDivImg": {
    width: "45%",
    padding: "32px",
    position: "relative",
    "& img": {
      width: "100%",
    },
    "& .text-div": {
      width: "70%",
      position: "absolute",
      bottom: "18%",
      left: "50%",
      textAlign: "center",
      transform: "translateX(-50%)",
      color: "white",
      "& .bottomTitle": {
        fontFamily: "Poppins",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        marginBottom: "24px",
      },
      "& .bottomSubTitle": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
      },
    },
    "@media(max-width:1159px)": {
      "& .text-div": {
        bottom: "10%",
      },
    },
    "@media(max-width:959px)": {
      display: "none",
    },
  },
  "@media(max-width:600px)": {
    justifyContent: "center",
  },
});
// Customizable Area End
