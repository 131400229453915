export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imageCloudUpload = require("../assets/image_cloud_upload.svg");
export const imagePlus = require("../assets/image_plus.svg");
export const deleteIcon = require("../assets/delete_icon.svg");
export const projectItemUpload = require("../assets/project_item_upload.svg");
export const group = require("../assets/group.svg")
export const circular_plus = require("../assets/circular_plus.svg")
export const three_elipsis_vertical = require("../assets/three_elipsis_vertical.svg")
export const clipboards = require("../assets/clipboards.svg")
export const noResultFound = require("../../blogpostsmanagement/assets/no-result-found.svg");
