// Customizable Area Start
import React from "react";
import { Box, Link, styled } from "@material-ui/core";

import FooterController, { Props, images } from "./FooterController.web";
// Customizable Area End

export default class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <FooterSection data-test-id="main-footer-page">
        <div className="footer-div">
          <div className="footer-div-details">
            <img src={images.companyLogo} className="footer-company-logo" />
            <div className="footer-div-text">
              We built an elegant solution. Our team created a fully integrated
              sales and marketing solution for SMBs
            </div>
            <div className="footer-icon-div">
              <Link href="https://twitter.com/" target="_blank">
                <img src={images.twitter} />
              </Link>
              <Link href="https://www.facebook.com/" target="_blank">
                <img src={images.facebook} />
              </Link>
              <Link href="https://www.linkedin.com/" target="_blank">
                <img src={images.linkedin} />
              </Link>
            </div>
          </div>
          <div className="footer-div-listing">
            <div className="footer-div-list">
              <div className="footer-div-text-1">Company</div>
              <div className="footer-div-text-2">
                About
                <br />
                Pricing
                <br />
                Blog
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div>© Copyright {new Date().getFullYear()} Callifony, Inc.</div>
          <div className="footer-copyright-text">
            <div>Terms of Service</div>
            <div>Privacy Policy</div>
          </div>
        </div>
      </FooterSection>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const FooterSection = styled(Box)({
  "& .footer-div": {
    padding: "110px 150px",
    display: "flex",
    gap: "100px",
    "& .footer-div-details": {
      maxWidth: "263px",
      "& .footer-company-logo": {
        width: "126px",
        marginBottom: "24px",
      },
      "& .footer-div-text": {
        color: "#404040",
        fontSize: "14px",
        fontFamily: "Satoshi",
        fontWeight: "400",
        lineHeight: "24px",
        marginBottom: "24px",
        textAlign: "justify",
      },
      "& .footer-icon-div": {
        display: "flex",
        gap: "16px",
      },
    },
    "& .footer-div-listing": {
      display: "flex",
      gap: "60px",
      whiteSpace: "nowrap",
      "& .footer-div-list": {
        "& .footer-div-text-1": {
          color: "black",
          fontSize: "16px",
          fontFamily: "Satoshi",
          fontWeight: "700",
          marginBottom: "10px",
        },
        "& .footer-div-text-2": {
          color: "black",
          fontSize: "14px",
          fontFamily: "Satoshi",
          fontWeight: "400",
          lineHeight: "34px",
          letterSpacing: "0.20px",
        },
      },
    },
  },
  "& .footer-copyright": {
    margin: "0 40px",
    borderTop: "1px solid rgba(120, 120, 120, 0.24)",
    padding: "40px 180px 40px 110px",
    color: "#404040",
    fontSize: "14px",
    fontFamily: "Satoshi",
    fontWeight: "400",
    letterSpacing: "0.20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    whiteSpace: "nowrap",
    "& .footer-copyright-text": {
      display: "flex",
      gap: "48px",
      width: "100%",
      justifyContent: "center",
    },
    "& .footer-copyright-text-1": {
      color: "black",
      fontSize: "14px",
      fontFamily: "Satoshi",
      fontWeight: "500",
      letterSpacing: "0.20px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& div": {
        display: "flex",
      },
    },
  },
  "@media (max-width:959px)": {
    "& .footer-div": {
      padding: "50px 70px !important",
      gap: "50px !important",
    },
    "& .footer-copyright": {
      flexDirection: "column",
      margin: "0 20px !important",
      padding: "20px 50px !important",
    },
  },
  "@media (max-width:600px)": {
    "& .footer-div": {
      padding: "30px !important",
      gap: "25px !important",
      "& .footer-div-details": {
        "& .footer-company-logo": {
          width: "100px !important",
          marginBottom: "12px !important",
        },
        "& .footer-div-text": {
          marginBottom: "12px !important",
        },
      },
      "& .footer-div-listing": {
        gap: "30px !important",
        flexWrap: "wrap",
        "& .footer-div-list": {
          "& .footer-div-text-1": {
            marginBottom: "6px !important",
          },
        },
      },
    },
    "& .footer-copyright": {
      margin: "0 10px !important",
      padding: "10px 25px !important",
      gap: "10px !important",
      "& .footer-copyright-text": {
        gap: "24px !important",
      },
      "& .footer-copyright-text-1": {
        gap: "6px !important",
        "& div": {
          alignItems: "center",
        },
      },
    },
  },
});
// Customizable Area End
