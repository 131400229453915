// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");
export const images = require("./assets");
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FooterController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {};
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
