import React, { Fragment } from "react";

import {
    Box,
    Button,
    InputLabel,
    Typography,
    styled,
    InputBase,
    SwitchProps,
    Switch,
    // Customizable Area Start
    FormGroup,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AddAgentController, { Props, FormData, configJSON, validationSchema } from "./AddAgentController.web";
import { Form, Formik, FormikProps } from "formik";
// Customizable Area End

export default class AddAgent extends AddAgentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
                // Customizable Area End
    }

    // Customizable Area Start

    static AGENT_INPUT_CONSTANT = [
        {
            id: "agentName",
            label: "Agent Name",
            type: "text"
        },
        {
            id: "mobile",
            label: "Mobile",
            type: "text"
        },
        {
            id: "alternateNumber",
            label: "Alternate Number",
            type: "text"
        },
        {
            id: "extension",
            label: "Extension",
            type: "text"
        },
        {
            id: "emailId",
            label: "Email-id",
            type: "email"
        },
        {
            id: "callPriority",
            label: "Call Priority",
            type: "text"
        }
    ] as const
    static AGENT_MODES_CONSTANT = [
        {
            id: "inboundCalls",
            label: "Inbound Calls",
            defaultChecked: false,
        },
        {
            id: "outboundCalls",
            label: "Outbound Calls",
            defaultChecked: false,
        },
        {
            id: "click2Call",
            label: "Click2Call",
            defaultChecked: false,
        },
        {
            id: "progressiveDialer",
            label: "Progressive Dialer",
            defaultChecked: true,
        },
        {
            id: "blended",
            label: "Blended",
            defaultChecked: false,
        },
    ] as const
    static PASSWORD_REQUIRED = ["Minimum 6 characters", "1 number", "1 special character", "1 uppercase and lowercase letter"]

    renderPassword = (
      values: FormData,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
      onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
      passwordHasError: boolean,
      error: string = ""
    ) => {
      const passwordLabel = <InputLabelCustom error={passwordHasError} htmlFor="password">Password</InputLabelCustom>
      const resetPasswordButton = (<Box style={styles.passwordLabelContainer}>
          {
            this.props.editAgent && !this.state.resetPassword ?
              (<Button
                data-test-id="reset-password"
                variant="contained"
                style={styles.resetButton}
                onClick={super.handleEditResetPassword(values)}
              >
                Reset
              </Button>) :
              (<></>)
          }
        </Box>)
        const passFieldWithError = !this.props.editAgent || this.state.resetPassword ?
          (
            <>
              <InputBaseCustom
                hidden={!this.state.resetPassword}
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                value={values.password}
                onChange={onChange}
                onBlur={onBlur}
                style={passwordHasError ? styles.errorText : {}}
              />
              {
                passwordHasError
                && (<Typography color="error">{error}</Typography>)
              }
            </>
          ) :
          (<></>)
      let passwordHeader = passwordLabel
      if (this.props.editAgent && !this.state.resetPassword) {
        passwordHeader = (
          <Box style={styles.passwordLabelContainer}>
            {passwordLabel}
            {resetPasswordButton}
          </Box>
        )
      }
      return (
        <>
          {passwordHeader}
          {passFieldWithError}
        </>
      )
    }

    renderForm = ({
        values,
        errors,
        touched,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }: FormikProps<FormData>
    ) => {
      let usernameHasError = super.areAllTrue(
        !!touched.username,
        super.some(!!errors.username, !!this.state.initialErrors.username),
      )
      let passwordHasError = super.areAllTrue(
        !!touched.password,
        super.some(!!errors.password, !!this.state.initialErrors.password),
      )
      let isSaveDisable = this.some(
          isSubmitting,
          Object.keys(errors).length !== 0,
          !this.areAllTrue(
            !!touched.agentName,
            !!touched.mobile,
            !!touched.extension,
            !!touched.emailId,
            !!touched.callPriority,
            !!touched.username,
            values.password !== "",
          ),
        )

      return (
        <Form translate="" onSubmit={handleSubmit}>
          <FormGroup >
            <AgentInformationContainer>
                {AddAgent.AGENT_INPUT_CONSTANT.map(input => {
                    const hasError = super.areAllTrue(!!touched[input.id], super.some(!!errors[input.id], !!this.state.initialErrors[input.id]))
                    return (<Box key={input.id}>
                        <InputLabelCustom error={hasError} htmlFor={input.id}>{input.label}</InputLabelCustom>
                        <InputBaseCustom
                          error={hasError}
                          id={input.id}
                          name={input.id}
                          type={input.type}
                          value={values[input.id]}
                          onChange={super.handleClearInitialErrorOnChange(handleChange, input.id !== "emailId")}
                          onBlur={super.handleClearInitialErrorOnBlur(handleBlur)}
                          style={hasError ? styles.errorText : {}}
                        />
                        {hasError && (<Typography color="error">{errors[input.id] ?? this.state.initialErrors[input.id]}</Typography>)}
                    </Box>
                )})}
            </AgentInformationContainer>
            <AgentAccountContainer>
                <Box className="agentAccount_field">
                    <InputLabelCustom error={usernameHasError} htmlFor="username">Username</InputLabelCustom>
                    <InputBaseCustom
                      disabled={!!this.props.editAgent}
                      id="username"
                      name="username"
                      type="text"
                      value={values.username}
                      onChange={super.handleClearInitialErrorOnChange(handleChange)}
                      onBlur={super.handleClearInitialErrorOnBlur(handleBlur)}
                      style={usernameHasError ? styles.errorText : {}}
                    />
                    {
                      usernameHasError
                      && (<Typography color="error">{errors.username ?? this.state.initialErrors.username}</Typography>)
                    }
                </Box>
                <Box className="agentAccount_field">
                  {this.renderPassword(
                    values,
                    super.handleClearInitialErrorOnChange(handleChange, false),
                    super.handleClearInitialErrorOnBlur(handleBlur),
                    passwordHasError,
                    errors.password ?? this.state.initialErrors.password,
                    )}
                </Box>
            </AgentAccountContainer>
            {
              !this.props.editAgent || this.state.resetPassword ?
              (
                 <RequiredPasswordContainer>
                 <Typography component="p">Create a password with following details</Typography>
                 <ul>
                 {AddAgent.PASSWORD_REQUIRED.map((require,idx) => (
                       <li key={idx}>
                       {require}
                       </li>
                       ))}
                 </ul>
                 </RequiredPasswordContainer>
              ) :
              (<></>)
            }
            <AgentModesContainer>
                <Typography className="agentMode__label" component="h4">{configJSON.labelAgentMode}</Typography>
                <Typography className="agentMode__shortDes" component="p">{configJSON.shortDescriptionAgentMode}</Typography>
                <Box className="agentMode__switches">
                    {AddAgent.AGENT_MODES_CONSTANT.map(mode => (
                      <Box className="switchContainer" key={mode.id}>
                          <Typography component="p" className="switch__label">
                              {mode.label}
                          </Typography>
                          <SwitchCustom
                            id={mode.id}
                            name={mode.id}
                            checked={values[mode.id]}
                            onChange={super.handleModeChange(mode.id,setValues)}
                            onBlur={super.handleClearInitialErrorOnBlur(handleBlur)}
                          />
                      </Box>
                    ))}
                </Box>
            </AgentModesContainer>
            <Box>
                <ButtonCustom
                  type="submit"
                  disabled={
                    this.some(
                      !this.props.editAgent,
                      this.state.resetPassword && values.password === "",
                    ) && isSaveDisable
                  }
                >
                    Save
                </ButtonCustom>
            </Box>
          </FormGroup>
        </Form>
      )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Formik
              initialValues={this.state.formData}
              initialErrors={this.state.initialErrors}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {this.renderForm}
            </Formik>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
  errorText: {
    color: "red"
  },
  passwordLabelContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    columnGap: 250,
    alignItems: "center",
  },
  resetButton: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "none",
    color: "white",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    borderRadius: 8,
    padding: "2px 16px",
    marginBottom: 10,
    marginTop: 10,
  },
} as const

export const AgentInformationContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    gridRowGap: "20px",
    marginTop: "9px"
}))

export const AgentModesContainer = styled(Box)(() => ({
    color: '#334155',
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Poppins",
    lineHeight: "26px",
    marginTop: "32px",
    "& .agentMode__label": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "32px",
        marginBottom: "14px",
        color: "#334155"
    },
    "& .agentMode__shortDes": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
        color: "#334155"
    },
    "& .agentMode__switches": {
        margin: "14px 0 30px",
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
        height: "100%",
        maxHeight: "274px"
    },
    "& .switchContainer:last-child": {
        border: 0
    },
    "& .switchContainer": {
        maxWidth: "520px",
        height: "52px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E2E8F0",
        "& .switch__label": {
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "26px",
            color: "#0F172A"
        }
    }
}))

export const ButtonCustom = styled(Button)(() => ({
    maxWidth: "520px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "8px",
    padding: "16px",
    color: "#FFFFFF",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    marginTop: "108px",
    marginBottom: "93px",
    "&:disabled": {
      backgroundColor: "#E2E8F0",
      borderColor: "#CBD5E1",
      color: "#334155",
    },
    "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#0099FF",
        borderColor: "#0099FF",
    }
}))

export const AgentAccountContainer = styled(Box)(() => ({
    marginTop: "20px",
    marginBottom: "37px",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px"
}))

export const RequiredPasswordContainer = styled(Box)(() => ({
    "& p":{
        color: "#334155",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px"
    },
    "& ul": {
        marginTop: "10px",
        marginBottom: "0",
        paddingLeft: "25px",
        "& li":{
            color: "#334155",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px"
        }
    }
}))



export const InputLabelCustom = styled(InputLabel)(() => ({
    color: '#334155',
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Poppins",
    lineHeight: "26px",
}))

export const InputBaseCustom = styled(InputBase)(({ theme }) => ({
    maxWidth: "520px",
    width: "100%",
    'label + &': {
        marginTop: "4px",
    },
    '& .MuiInputBase-input': {
        maxWidth: "520px",
        height: "34px",
        borderRadius: 8,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        border: '1px solid',
        borderColor: '#CBD5E1',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
    },
}));

const SwitchCustom = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: "linear-gradient(99.09deg, #1676C0 2.64%, #13A9EC 100%)",
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#FFFFFF"
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E2E8F0',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

// Customizable Area End
