Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "interactivevoiceresponseivr";
exports.labelBodyText = "interactivevoiceresponseivr Body";
exports.deleteAPiMethod = "DELETE";
exports.patchAPiMethod = "PATCH";

exports.btnExampleTitle = "CLICK ME";

exports.click2call = "Click 2 Call";
exports.fromNumber = "From Number";
exports.toNumber = "To Number";
exports.call = "Call";
exports.kevinKare = "Kevin Kare";
exports.ringing = "Ringing";
exports.number = "555-567-5309";
exports.searchGroup = "Search Group";
exports.searchContact = "Search Contact";
exports.open = "Open";
exports.done = "Done";
exports.getDIDNumberApiEndPoint = "bx_block_interactivevoiceresponseivr/did_numbers";
exports.getGroupListApiEndPoint = "bx_block_leadmanagement3/contact_groups";
exports.getContactListApiEndPoint = "bx_block_leadmanagement3/contacts";
exports.createCallApiEndPoint = "bx_block_cfkukuapiintegration/kookoo/outbound_call";

exports.ivrFlow = "IVR Flows";
exports.createIvrFlows = "Create IVR Flows";
exports.ivrFlowDataMainText = "No Data Available";
exports.ivrFlowDataSubText = "There is no data show you right now.";
exports.flowName = "Flow Name";
exports.selectFlowType = "Select Flow Type";
exports.flowType = "Flow Type";
exports.custom = "Custom";
exports.transfer = "Transfer";
exports.appURL = "App URL";
exports.save = "Save";
exports.ivrFlowApiEndPoint = "bx_block_interactivevoiceresponseivr/ivr_forms";
exports.nameErrorMessage = "The Flow name entered is already in use."
exports.noResultFound = "No Result found";
exports.noIvrFlowMatched = "No IVR Flow matched your Criteria. Try Searching for something else.";
exports.ivrFlowId = "IVR Flow ID";
exports.createdDate = "Created Date";
exports.lastModifiedDate = "Last Modified Date";
exports.design = "Design";
exports.delete = "Delete";
exports.downloadIvrFlow = "Download IVR Flows:";
exports.csv = "CSV";
exports.xlsx = "XLSX";
exports.download = "Download";
// Customizable Area End