import React from "react";
import { styled } from "@material-ui/core/styles";
import { Box, LinearProgress } from "@material-ui/core";

export default function CustomLinearDeterminate({
  isShow,
}: {
  isShow: boolean;
}) {
  const StyledDiv = styled(Box)({
    width: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "transparent",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#0099FF",
    },
  });

  return isShow ? (
    <StyledDiv>
      <LinearProgress />
    </StyledDiv>
  ) : (
    <></>
  );
}
