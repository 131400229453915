// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { expireTokenHandling } from "../../../components/src/Utilities";

export const configJSON = require("./config");
export const images = require("./assets");

type FormData = {
  flowName: string;
  selectFlowType: string;
  custom: boolean;
  transfer: boolean;
  appURL: string;
};

const initialData: FormData = {
  flowName: "",
  selectFlowType: "none",
  custom: false,
  transfer: false,
  appURL: "",
};

type ErrorData = {
  flowName: string;
};

const initialErrors: ErrorData = {
  flowName: "",
};

type SuccessData = {
  message: string;
};

type APIErrorData = {
  errors: string[];
};

type IvrFlowResponse = {
  data: {
    id: string;
    attributes: {
      id: number;
      name: string;
      flow_type: string;
      app_url: string;
      custom: boolean;
      transfer: boolean;
    };
  };
};

type ResponseData = SuccessData | APIErrorData | IvrFlowResponse;
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  formData: FormData;
  formErrors: ErrorData;
  editIvrFlowId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddIvrFlowController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      formData: initialData,
      formErrors: initialErrors,
      editIvrFlowId: 0,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  static SELECT_FLOW_TYPE = [
    {
      id: "voice",
      label: "Voice",
    },
    {
      id: "chat",
      label: "Chat",
    },
  ] as const;

  crateIvrFlowApiCallId: string = "";
  getIvrFlowApiCallId: string = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const editId = message.getData("EditIvrFlow");
      this.setState({ editIvrFlowId: Number(editId) }, () =>
        this.getIvrFlowAPICall()
      );
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  handleAPICall = async (
    apiEndPoint: string,
    method: string,
    body?: string
  ) => {
    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    if (body) {
      createApiMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleReceiveFunction = (
    apiRequestCallId: string,
    responseJson: ResponseData
  ) => {
    if (apiRequestCallId === this.crateIvrFlowApiCallId) {
      this.receiveCreateIvrFlowAPICall(responseJson);
    }
    if (apiRequestCallId === this.getIvrFlowApiCallId) {
      this.receiveGetIvrFlowAPICall(responseJson);
    }
  };

  goToNavigation = (route: string) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    this.send(navigationMsg);
  };

  handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>,
    isSwitch?: boolean,
    checked?: boolean
  ) => {
    const name = event.target.name as keyof FormData;
    let value = event.target.value;
    if (name === "flowName") {
      if ((value as string).length > 40) {
        return;
      }
      this.setState({ formErrors: { ...this.state.formErrors, flowName: "" } });
    }
    if (name === "custom" && checked) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          transfer: false,
        },
      }));
    }
    if (name === "transfer" && checked) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          custom: false,
        },
      }));
    }
    if (isSwitch) {
      value = checked;
    }
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  some(...values: boolean[]) {
    return values.some((value) => value);
  }

  isSaveBtnDisabled = () => {
    return this.some(
      !this.state.formData.flowName,
      this.state.formData.selectFlowType === "none"
    );
  };

  handleSave = () => {
    const formData = this.state.formData;
    const ivr_form = {
      name: formData.flowName,
      flow_type: formData.selectFlowType,
      custom: formData.custom,
      transfer: formData.transfer,
      app_url: formData.appURL,
    };

    this.createIvrFlowAPICall(JSON.stringify({ ivr_form }));
  };

  createIvrFlowAPICall = async (body: string) => {
    this.crateIvrFlowApiCallId = await this.handleAPICall(
      this.state.editIvrFlowId
        ? `${configJSON.ivrFlowApiEndPoint}/${this.state.editIvrFlowId}`
        : configJSON.ivrFlowApiEndPoint,
      this.state.editIvrFlowId
        ? configJSON.patchAPiMethod
        : configJSON.exampleAPiMethod,
      body
    );
  };

  receiveCreateIvrFlowAPICall = (responseJson: ResponseData) => {
    if (responseJson && (responseJson as SuccessData).message) {
      this.goToNavigation("IvrFlow");
    } else if ("errors" in responseJson) {
      let initialAPIErrors: ErrorData = { ...initialErrors };
      for (const value of responseJson.errors) {
        if (value.search("Flow name") >= 0) {
          initialAPIErrors.flowName = configJSON.nameErrorMessage;
        }
      }
      this.setState((prev) => ({ ...prev, formErrors: initialAPIErrors }));
    }
  };

  getIvrFlowAPICall = async () => {
    this.getIvrFlowApiCallId = await this.handleAPICall(
      `${configJSON.ivrFlowApiEndPoint}/${this.state.editIvrFlowId}`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetIvrFlowAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as IvrFlowResponse;
    if (responseJsonData && responseJsonData.data) {
      const apiData = responseJsonData.data.attributes;
      this.setState({
        formData: {
          flowName: apiData.name,
          selectFlowType: apiData.flow_type,
          custom: apiData.custom,
          transfer: apiData.transfer,
          appURL: apiData.app_url,
        },
      });
    }
  };
  // Customizable Area End
}
