import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";

interface myProps {
  open: boolean;
  onDone?: () => void;
  onClose?: () => void;
  rootClassName?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(15,23,42,0.4)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
    "& .MuiDialog-paperScrollPaper": {
      borderRadius: "8px 8px 32px 8px",
      width: "100%",
    },
    "& .MuiDialogTitle-root": {
      padding: "26px 58px 26px 40px",
    },
  },
  content: {
    padding: "40px !important",
    "& .MuiInput-underline:before": {
      content: "none",
    },
    "& .MuiInput-underline:after": {
      content: "none",
    },
    "& .textField": {
      "&.MuiFormControl-root": {
        maxWidth: "436px",
      },
      "& .MuiInputBase-input": {
        border: "1px solid #CBD5E1",
        padding: "16px 8px",
        borderRadius: "8px",
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "24px",
    padding: "0px",
    "&.MuiIconButton-root.Mui-disabled": {
      opacity: 0.4,
    },
  },
  title: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    textAlign: "center",
    marginBottom: "16px",
  },
  text: {
    color: "#0F172A",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  loader: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "70px",
    },
  },
  actions: {
    padding: "24px 40px",
    gap: "16px",
    justifyContent: "center",
    "& .MuiButton-root": {
      textTransform: "none",
      borderRadius: "8px",
      padding: "12px 16px",
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      "&.Mui-disabled": {
        background: "#F1F5F9",
        border: "none",
      },
    },
    "& .MuiButton-outlined": {
      border: "1px solid #CBD5E1",
      background: "#F8FAFC",
      color: "#0099FF",
      fontWeight: 600,
      whiteSpace: "nowrap",
      "&.Mui-disabled": {
        color: "#99D6FF",
      },
    },
    "& .MuiButton-contained": {
      background: "#DC2626",
      color: "#FFFFFF",
      fontWeight: 700,
      "&.Mui-disabled": {
        color: "#64748B",
      },
    },
    "@media (max-width:599px)": {
      flexDirection: "column",
      "& .MuiButton-root": {
        width: "100%",
      },
      "& .MuiButton-contained": {
        maxWidth: "none !important",
        width: "100%",
      },
    },
  },
}));

export default function DeleteConfirmationDialog({
  open,
  onDone,
  onClose,
  rootClassName,
}: myProps) {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="customized-delete-dialog-title"
      open={open}
      className={`${classes.root} ${rootClassName}`}
      maxWidth="xs"
    >
      <DialogContent dividers className={classes.content}>
        <div className={classes.loader}>
          <img src={require("./wrong_circle_solid.svg")} />
        </div>
        <Typography className={classes.title}>Are you sure?</Typography>
        <Typography className={classes.text}>Do you want to delete?</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          data-test-id="deleteButton"
          variant="contained"
          onClick={onDone}
        >
          Delete
        </Button>
        <Button
          data-test-id="cancelButton"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
