import React from "react";
import { Box, Link, styled } from "@material-ui/core";
// @ts-ignore
import image from "../assets/logoCallifony.png";
// @ts-ignore
import imgLogo from "../assets/a.jpg";
// @ts-ignore
import arrowDown from "../assets/back-up-down-svgrepo-com.svg";

interface IPropHeader {
  navigation?: {
    navigate: (key: string) => void;
  };
  id?: string;
  isLandingPage?: boolean;
}

const StyledBox = styled(Box)({
  height: "94px",
  width: "100%",
  marginLeft: "120px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 32px",
  marginBottom: "2px",
  boxShadow: "0px 4px 8px 0px #00000008",
  "@media (max-width: 1024px)": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "@media (max-width: 723px)": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "@media (max-width: 600px)": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "0px",
    boxShadow: "none",
    padding: 0
  },

  "@media (max-width: 447px)": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "0px",
    padding: 0
  },

  "@media (max-width: 375px)": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "0px",
    padding: 0
  }
});

const LogoBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  "@media (max-width: 447px)": {
    padding: 0
  },

  "@media (max-width: 375px)": {
    padding: 0
  }
});

const ImageBox = styled(Box)({
  display: "flex",
  border: "1px solid #0B5F97",
  borderRadius: "50%",
  marginLeft: "11px",
});

const GreetingBox = styled(Box)({
  width: "20%",
  padding: "25px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "5px",
  "& .company__name span":{
    whiteSpace: "nowrap"
  },
  "@media (max-width: 1024px)": {
    width: "20%",
    "& .company__name span":{
      whiteSpace: "normal"
    },
  },
  "@media (max-width: 601px)": {
    "& .company__name": {
      display: "none"
    },
  },
  "@media (max-width: 410px)": {
    paddingLeft: "0",
    paddingRight: "10px"
  },
});

export default function Header(props: IPropHeader) {
  const handleLogout = () => {
    if (props && props.navigation) {
      window.localStorage.removeItem("userId");
      window.localStorage.removeItem("authToken");
      window.localStorage.removeItem("isDashboard");
      window.localStorage.removeItem("name");
      window.localStorage.removeItem("data");
      props.navigation.navigate("LandingPage");
    }
  };

  const handleRenderHeader = () => {
    return (
      <StyledBox id="header">
        <LogoBox>
          <Link href="/Dashboard">
            <img src={image} />
          </Link>
        </LogoBox>
        <GreetingBox>
          <Box className="company__name">
            <span style={webStyles.msgHello}>
              👋 Hello, <span style={webStyles.companyTypo}>Samsung</span>
            </span>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <ImageBox
              data-testid="btnLogout"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleLogout();
              }}
            >
              <img
                style={{ borderRadius: "50%", padding: "2px" }}
                src={imgLogo}
              />
            </ImageBox>
            <img
              className="dropDown"
              src={arrowDown}
              width={15}
              height={15}
            />
          </Box>
        </GreetingBox>
      </StyledBox>
    );
  };

  return handleRenderHeader();
}

const webStyles = {
  span: {
    color: "#3C3C3C",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
    marginRight: "5px",
    marginLeft: "3px"
  },
  peopleCount: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.12px"
  },
  spanLogged: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: "15px"
  },
  spanCountIn: {
    color: "#3FD099",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px"
  },
  spanCountOut: {
    color: "#F2736D",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px"
  },
  msgHello: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  },
  companyTypo: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px"
  }
};

const StyledHeader = styled(Box)({
  width: "100%",
  "& #container__header": {
    padding: "24px 40px "
  }
});
