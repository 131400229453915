import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import createRequestMessage from "../../fileattachment/src/Helpers/create-request-message";
import { expireTokenHandling } from "../../../components/src/Utilities";

// Customizable Area Start

export type Manager = {
  id: string
  type: string
  attributes: {
    id: number
    name: string
    phone_number: number
    alt_phone_number: number
    extension: string
    email: string
    isselected: boolean
    username: string
    password_digest: string
    departments: any
  }
}

type ManagersData = {
  managers: {
    data: Manager[];
  };
  current_page: number;
  total_pages: number;
  total_managers: number;
  search:boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  managerData: ManagersData;
  selectedAgent?: Manager;
  perPage: number;
  selectedMode: boolean;
  selectAll:boolean;
  isLoading: boolean;
  selectedId: string[];
  bulkDeleteConfirmation: boolean;
  deleteConfirmationId: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ManagerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllManagers = "";
  deleteManagerApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      managerData: {
        managers: {
          data: [],
        },
        current_page: 1,
        total_pages: 1,
        total_managers: 0,
        search:false
      },
      perPage: 10,
      selectedId: [],
      selectedMode: false,
      selectAll:false,
      isLoading: true,
      bulkDeleteConfirmation: false,
      deleteConfirmationId: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    // Customizable Area Start
    const token = await getStorageData("authToken")
    if (typeof token !== "string" || token === "") {
      const msg = new Message(
        getName(MessageEnum.NavigationNavigate)
      )
      msg.addData(
        getName(MessageEnum.NavigationNavigateData),
        {
          route: "LandingPage",
        }
      )
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      )
      this.send(msg)
    }

    else {
      await this.loadAgentsAPI()

    }
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const id = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const successData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      expireTokenHandling(successData)
      if (id === this.getAllManagers) {
        this.handleLoadAgents(successData)
      } else if (id === this.deleteManagerApiId) {
        await this.handleAgentDeleted()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  loadAgentsAPI = async (page: number = 1,search:string = "") => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Token: await getStorageData("authToken"),
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllManagers = requestMessage.messageId;
    createRequestMessage({
      requestMessage,
      endPoint: configJSON.getManagersEndpoint + `?page=${page}&per_page=${this.state.perPage}&query=${search}`,
      method: configJSON.validationApiMethodType,
      header: header,
    });
  }

  handleLoadAgents = (data: ManagersData) => {
    if (data.managers) {
      const updatedData: ManagersData = {
        ...data,
        managers: {
          ...data.managers,
          data: data.managers.data.map(manager => ({
            ...manager,
            attributes: {
              ...manager.attributes,
              isselected: false 
            }
          }))

        }
      }
      this.setState(prev => ({ ...prev, managerData: updatedData, isLoading: false }))
    }
  }

  handleSelectAll=(checked:boolean)=>
  {

    this.setState({selectAll:!checked},()=>this.handleSelectAlldelete(this.state.selectAll))
    
  }
  handleSelectAlldelete=(selectall:boolean)=>
  {
    const updatedDataWithoutIds = {
      ...this.state.managerData,
      managers: {
        ...this.state.managerData.managers,
        data: this.state.managerData.managers.data.map(manager => ({
          ...manager,
          attributes: {
            ...manager.attributes,
            isselected: selectall,
          }
        }))
      }
  }
  this.setState({managerData:updatedDataWithoutIds},()=>  this.handleSelectId())

  }

  updateSelectedData = (id: any) => {
    const managerToUpdate = this.state.managerData.managers.data.find(manager => manager.id === id);
    if (managerToUpdate) {
      managerToUpdate.attributes.isselected = !managerToUpdate.attributes.isselected;
    }
    const updateddata = {
      ...this.state.managerData,
      managers: {
        ...this.state.managerData.managers,
        data: [...this.state.managerData.managers.data]
      }
    };
    this.setState({ managerData: updateddata })
    this.handleSelectId()
  }
  handleSelectId=()=>
  {
    const filteredData = this.state.managerData.managers.data.filter(manager => manager.attributes.isselected);
    const ids = filteredData.map(manager => manager.id);
    this.setState({ selectedId: ids }, () => this.checkAllSelected())

  }
  handleBulkDelete=async()=>
  {
    this.setState({ isLoading: true, bulkDeleteConfirmation: false })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.deleteManagerApiId = requestMessage.messageId
    createRequestMessage({
      requestMessage,
      endPoint: `${configJSON.createManagerEndpoint}/destroy?manager_ids=[${this.state.selectedId}]`,
      method: configJSON.deleteAPIMethod,
      token: await getStorageData("authToken"),
    })
    this.handleSelect()
  }
  checkAllSelected=()=>
  {
    if(this.state.selectedId.length==this.state.managerData.managers.data.length)
    {
      this.setState({selectAll:true })
    }
  else{this.setState({selectAll:false })}
  }
  handleSelect = () => {
    this.setState({ selectedMode: !this.state.selectedMode,selectAll:false },()=>this.handleSelectAlldelete(false))
  }
  handlePageChange = async (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    this.setState({ selectedMode: false,selectAll:false })

    await this.loadAgentsAPI(page + 1)
  }

  handleRowPerPageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prev => ({
      ...prev,
      perPage: +event.target.value,
    }))
    this.setState({ selectedMode: false,selectAll:false })
    await this.loadAgentsAPI()
  };
  handleSearch =async (value:string)=>

  {
    if(value.length==0)
    {
      await this.loadAgentsAPI()

    }
    else if (value.length > 2)
    {
      await this.loadAgentsAPI(1,value)
      
    }

  }

  handleAddAgent = () => {
    const msg = new Message(
      getName(MessageEnum.NavigationNavigate)
    )
    msg.addData(
      getName(MessageEnum.NavigationNavigateData),
      {
        route: "ManagerAction",
      }
    )
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )

    this.send(msg)
  }

  handleBulkDeleteConfirmationDialog = () => {
    this.setState({ bulkDeleteConfirmation: !this.state.bulkDeleteConfirmation })
  }

  handleDeleteConfirmationDialog = (agentId: string) => {
    this.setState({ deleteConfirmationId: agentId })
  }

  handleDeleteManager = async (id: string) => {
    this.setState({ isLoading: true, deleteConfirmationId: "" })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.deleteManagerApiId = requestMessage.messageId
    createRequestMessage({
      requestMessage,
      endPoint: `${configJSON.createManagerEndpoint}/destroy?manager_ids=[${id}]`,
      method: configJSON.deleteAPIMethod,
      token: await getStorageData("authToken"),
    })
  }

  handleAgentDeleted = async () => {
    await this.loadAgentsAPI()
  }

  handleEditManager = (data: Manager) => {

    this.setState(prev => ({ ...prev, selectedAgent: data }))

    const msg = new Message(
      getName(MessageEnum.NavigationMessage)
    )
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EditManager",
    )
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData("editManager", data);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg)

    return true;

  }
  getdepartments = (data: any) => {
    const departmentNames = data.departments.map((department: { name: any; }) => department.name);

    return departmentNames.join(', ');

  }
  // Customizable Area End
}
