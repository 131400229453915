import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";

interface myProps {
  open: boolean;
  loading: boolean;
  error: boolean;
  onDone: () => void;
  onClose: () => void;
  downloadLink: string;
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(15,23,42,0.4)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "0px 8px 32px 0px #0000000F",
    },
    "& .MuiDialog-paperScrollPaper": {
      borderRadius: "8px 8px 32px 8px",
      width: "100%",
    },
  },
  content: {
    padding: "60px 40px 40px !important",
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "16px",
    padding: "0px",
    "&.MuiIconButton-root.Mui-disabled": {
      opacity: 0.4,
    },
  },
  loader: {
    marginBottom: "40px",
    display: "flex",
    justifyContent: "center",
  },
  text: {
    color: "#0F172A",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  actions: {
    padding: "24px 40px",
    gap: "16px",
    justifyContent: "space-between",
    "&.MuiDialogActions-spacing > :not(:first-child)": {
      margin: 0,
    },
    "& .MuiButton-root": {
      textTransform: "none",
      borderRadius: "8px",
      padding: "16px",
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      "&.Mui-disabled": {
        background: "#F1F5F9",
        border: "none",
      },
    },
    "& .MuiButton-outlined": {
      border: "1px solid #CBD5E1",
      background: "#F8FAFC",
      color: "#0099FF",
      fontWeight: 600,
      whiteSpace: "nowrap",
      "&.Mui-disabled": {
        color: "#99D6FF",
      },
    },
    "& .MuiButton-contained": {
      background: "#0099FF",
      color: "#FFFFFF",
      fontWeight: 700,
      maxWidth: "330px",
      width: "100%",
      "&.Mui-disabled": {
        color: "#64748B",
      },
      "@media (max-width:669px)": {
        maxWidth: "265px",
      },
    },
    "@media (max-width:599px)": {
      flexDirection: "column",
      "& .MuiButton-root": {
        width: "100%",
      },
      "& .MuiButton-contained": {
        maxWidth: "none !important",
        width: "100%",
      },
    },
  },
}));

export default function CustomBulkUploadSuccessDialog({
  open,
  loading,
  onDone,
  onClose,
  downloadLink,
  error,
}: myProps) {
  const classes = useStyles();

  const handleDownloadBtn = async () => {
    window.open(downloadLink);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.root}
    >
      <DialogContent dividers className={classes.content}>
        <IconButton
          className={classes.closeButton}
          disabled={loading}
          onClick={error ? onClose : onDone}
        >
          <img src={require("./button_close_icon.png")} />
        </IconButton>
        {loading ? (
          <>
            <div className={classes.loader}>
              <FacebookCircularProgress />
            </div>
            <div className={classes.text}>Your Document is uploading</div>
          </>
        ) : (
          <>
            <div className={classes.loader}>
              <img
                src={require(error
                  ? "./wrong_circle_solid.svg"
                  : "./right_circle_solid.svg")}
              />
            </div>
            <div className={classes.text}>
              {error
                ? "Your Document has not been uploaded !"
                : "Your Document has been uploaded Successfully"}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          disabled={loading || error}
          onClick={handleDownloadBtn}
        >
          Download Report
        </Button>
        <Button
          variant="contained"
          disabled={loading || error}
          onClick={onDone}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStylesFacebook = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
    },
    bottom: {
      color: "rgba(0, 153, 255, 0.15)",
    },
    top: {
      color: "#0099FF",
      animationDuration: "550ms",
      position: "absolute",
      left: "0",
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);

function FacebookCircularProgress(props: CircularProgressProps) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={105}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={105}
        thickness={4}
        {...props}
      />
    </div>
  );
}
