// Customizable Area Start
import React, { memo } from "react";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { expireTokenHandling } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  ActionButton,
  CustomTooltip,
  StyledCheckbox,
} from "../../groups2/src/Agents.web";
import { MoreVert } from "@material-ui/icons";
export const configJSON = require("./config");
export const images = require("./assets");

export type TTabIndex = "contacts" | "contactGroup";

type ErrorData = {
  errors: string[];
};
type SuccessData = {
  message: string;
};
type ContactGroupData = {
  id: number;
  name: string;
  isSelected?: boolean;
};
export type GetGroupData = {
  contact_groups: ContactGroupData[];
  current_page: number;
  total_pages: number;
  total_count: number;
  search: boolean;
};
export type GetContactData = {
  contacts: {
    data: {
      id: number;
      attributes: {
        name: string;
        company: string;
        contact_number: string;
        email: string;
        group_name: string;
      };
      isSelected?: boolean;
    }[];
  };
  current_page: number;
  total_contacts: number;
  search: boolean;
};
type ResponseData =
  | ErrorData
  | SuccessData
  | GetGroupData
  | ContactGroupData
  | GetContactData;
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  tabIndexValue: TTabIndex;
  perPage: number;
  search: string;
  openId: string;
  selectedMode: boolean;
  selectAll: boolean;
  selectedIds: number[];
  bulkDeleteConfirmation: boolean;

  showAddGroupDialog: boolean;
  contact_group: { name: string };
  contactGroupNameError: string;
  groupList: GetGroupData;
  selectedGroupId: number;
  deleteConfirmationGroupId: number;

  contactList: GetContactData;
  deleteConfirmationContactId: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AddressBookController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
      tabIndexValue: "contacts",
      perPage: 10,
      search: "",
      openId: "",
      selectedMode: false,
      selectAll: false,
      selectedIds: [],
      bulkDeleteConfirmation: false,

      showAddGroupDialog: false,
      contact_group: { name: "" },
      contactGroupNameError: "",
      groupList: {
        contact_groups: [],
        current_page: 0,
        search: false,
        total_count: 0,
        total_pages: 0,
      },
      selectedGroupId: 0,
      deleteConfirmationGroupId: 0,

      contactList: {
        contacts: {
          data: [],
        },
        current_page: 0,
        search: false,
        total_contacts: 0,
      },
      deleteConfirmationContactId: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleConditionalAPICall();
  }

  goToNavigation = (route: string, editContactId?: number) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    if (editContactId) {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData("EditContact", editContactId);
      navigationMsg.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
    }

    this.send(navigationMsg);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  handleAPICall = async (
    apiEndPoint: string,
    method: string,
    httpBody?: Object
  ) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("authToken"),
    };

    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if (httpBody)
      createApiMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleReceiveFunction = (
    apiRequestCallId: string,
    responseJson: ResponseData
  ) => {
    switch (apiRequestCallId) {
      case this.createGroupApiCallId:
        this.receiveCreateGroupAPICall(responseJson);
        break;
      case this.getGroupApiCallId:
        this.receiveGetGroupAPICall(responseJson);
        break;
      case this.getGroupByIdApiCallId:
        this.receiveGetGroupByIdAPICall(responseJson);
        break;
      case this.deleteGroupApiCallId:
        this.receiveDeleteGroupAPICall(responseJson);
        break;
      case this.getContactApiCallId:
        this.receiveGetContactAPICall(responseJson);
        break;
      case this.deleteContactApiCallId:
        this.receiveDeleteContactAPICall(responseJson);
        break;
      default:
        break;
    }
  };

  renderNoData = () => {
    return this.state.isLoading ? (
      <></>
    ) : (
      <Box className="addressBookDataContainer" data-test-id="no-data">
        <Box className="addressNoDataInnerContainer">
          <img src={images.clipboards} className="noDataImage" />
          <Typography className="addressNoDataMainText">
            {configJSON.addressNoDataMainText}
          </Typography>
          <Typography className="addressNoDataSubText">
            {configJSON.addressNoDataSubText}
          </Typography>
        </Box>
      </Box>
    );
  };

  renderNoSearchData = () => {
    return (
      <Box className="addressBookDataContainer" data-test-id="no-search-data">
        <Box className="addressNoDataInnerContainer">
          <img src={images.noResultFound} className="noDataImage" />
          <Typography className="addressNoDataMainText">
            {configJSON.noResultFound}
          </Typography>
          <Typography className="addressNoDataSubText">
            {this.state.tabIndexValue === "contacts"
              ? configJSON.noContactMatched
              : configJSON.noContactGroupMatched}
          </Typography>
        </Box>
      </Box>
    );
  };

  webStyle = {
    tooltipButton: {
      textTransform: "none",
      justifyContent: "start",
      color: "#0F172A",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "22px",
    },
    redText: {
      color: "#DC2626",
    },
  } as const;

  handleChangeTab = (tabIndex: TTabIndex) => {
    if (tabIndex !== this.state.tabIndexValue)
      this.setState(
        {
          tabIndexValue: tabIndex,
          search: "",
        },
        () => this.handleConditionalAPICall()
      );
  };

  handleToggleAction = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { name } = event.currentTarget;
    if (!this.state.selectedMode) {
      this.setState((prevState) => ({
        openId: prevState.openId === name ? "" : name,
      }));
    }
  };

  handleCloseAction = () => {
    this.setState((prev) => ({ ...prev, openId: "" }));
  };

  handleConditionalAPICall = (page = 1) => {
    if (this.state.tabIndexValue === "contacts") {
      this.getContactListAPICall(page);
    } else {
      this.getGroupListAPICall(page);
    }
  };

  handleSearch = async (value: string) => {
    this.setState({ search: value }, () => {
      if (value.length == 0 || value.length > 2) {
        this.handleConditionalAPICall();
      }
    });
  };

  handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    this.handleConditionalAPICall(page + 1);
  };

  handleRowPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        perPage: +event.target.value,
      },
      () => this.handleConditionalAPICall()
    );
  };

  handleSelectOption = () => {
    this.setState(
      {
        selectedMode: !this.state.selectedMode,
        selectAll: false,
      },
      () =>
        this.state.tabIndexValue === "contacts"
          ? this.handleSelectAllContactDelete(false)
          : this.handleSelectAllGroupDelete(false)
    );
  };

  handleSelectAll = (checked: boolean) => {
    this.setState({ selectAll: !checked }, () =>
      this.state.tabIndexValue === "contacts"
        ? this.handleSelectAllContactDelete(this.state.selectAll)
        : this.handleSelectAllGroupDelete(this.state.selectAll)
    );
  };

  handleAllDelete = () => {
    this.setState({
      isLoading: true,
      bulkDeleteConfirmation: false,
    });
    this.state.tabIndexValue === "contacts"
      ? this.handleDeleteContact(this.state.selectedIds)
      : this.handleDeleteGroup(this.state.selectedIds);
  };

  handleBulkDeleteConfirmationDialog = () => {
    this.setState({
      bulkDeleteConfirmation: !this.state.bulkDeleteConfirmation,
    });
  };

  // Groups
  createGroupApiCallId = "";
  getGroupApiCallId = "";
  getGroupByIdApiCallId = "";
  deleteGroupApiCallId = "";

  renderGroupNoData = () => {
    return this.state.groupList.search
      ? this.renderNoSearchData()
      : this.renderNoData();
  };

  renderGroupView = () => {
    return this.state.groupList.contact_groups.length ? (
      <TableComponent
        data-test-id="tableComponent"
        groupList={this.state.groupList}
        handleCloseAction={this.handleCloseAction}
        handleDeleteConfirmationGroupDialog={
          this.handleDeleteConfirmationGroupDialog
        }
        handleEditGroup={this.handleEditGroup}
        handlePageChange={this.handlePageChange}
        handleRowsPerPageChange={this.handleRowPerPageChange}
        handleSelectOption={this.handleSelectOption}
        handleToggleAction={this.handleToggleAction}
        openId={this.state.openId}
        perPage={this.state.perPage}
        selectedMode={this.state.selectedMode}
        updateSelectedGroupData={this.updateSelectedGroupData}
        webStyle={this.webStyle}
      />
    ) : (
      this.renderGroupNoData()
    );
  };

  handleDialogChange = () => {
    this.setState(
      {
        showAddGroupDialog: !this.state.showAddGroupDialog,
      },
      () => {
        if (this.state.showAddGroupDialog)
          this.setState({
            contact_group: { name: "" },
            contactGroupNameError: "",
            selectedGroupId: 0,
          });
      }
    );
  };

  handleGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 40)
      this.setState({
        contact_group: { name: event.target.value },
        contactGroupNameError: "",
      });
  };

  handleDialogDone = async () => {
    const body = { contact_group: this.state.contact_group };
    this.createGroupApiCallId = await this.handleAPICall(
      this.state.selectedGroupId
        ? `${configJSON.createGroupApiEndPoint}/${this.state.selectedGroupId}`
        : configJSON.createGroupApiEndPoint,
      this.state.selectedGroupId
        ? configJSON.putAPiMethod
        : configJSON.exampleAPiMethod,
      body
    );
  };

  receiveCreateGroupAPICall = (responseJson: ResponseData) => {
    if ("errors" in responseJson) {
      for (const value of responseJson.errors) {
        if (value.search("Name") >= 0) {
          this.setState({
            contactGroupNameError: configJSON.groupNameExistError,
          });
        }
      }
    } else {
      this.setState({
        tabIndexValue: "contactGroup",
        search: "",
      });
      this.handleDialogChange();
      this.getGroupListAPICall();
    }
  };

  getGroupListAPICall = async (page = 1) => {
    this.setState({
      isLoading: true,
      openId: "",
      selectedMode: false,
      selectAll: false,
      selectedIds: [],
    });
    this.getGroupApiCallId = await this.handleAPICall(
      `${configJSON.getGroupApiEndPoint}?page=${page}&per_page=${
        this.state.perPage
      }&query=${this.state.search}&is_default=false`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetGroupAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetGroupData;
    if (responseJsonData && responseJsonData.contact_groups) {
      this.setState({
        groupList: {
          ...responseJsonData,
          contact_groups: responseJsonData.contact_groups.map((group) => ({
            ...group,
            isSelected: false,
          })),
        },
        isLoading: false,
      });
    } else {
      this.setState({
        groupList: {
          contact_groups: [],
          current_page: 0,
          search: false,
          total_count: 0,
          total_pages: 0,
        },
        isLoading: false,
      });
    }
  };

  handleEditGroup = async (editId: number) => {
    this.setState({ isLoading: true });
    this.getGroupByIdApiCallId = await this.handleAPICall(
      `${configJSON.getGroupApiEndPoint}/${editId}`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetGroupByIdAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as ContactGroupData;
    if (responseJsonData && responseJsonData.id) {
      this.setState({
        selectedGroupId: responseJsonData.id,
        contact_group: { name: responseJsonData.name },
        contactGroupNameError: "",
        showAddGroupDialog: true,
      });
    }
    this.setState({ isLoading: false });
  };

  handleDeleteGroup = async (deleteId: number | number[]) => {
    this.setState({ isLoading: true, deleteConfirmationGroupId: 0 });
    this.deleteGroupApiCallId = await this.handleAPICall(
      `${configJSON.getGroupApiEndPoint}/?group_ids=[${deleteId}]`,
      configJSON.deleteAPiMethod
    );
  };

  receiveDeleteGroupAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as SuccessData;
    if (responseJsonData && responseJsonData.message) {
      this.getGroupListAPICall();
    }
  };

  handleSelectAllGroupDelete = (selectAll: boolean) => {
    const newGroupData = this.state.groupList.contact_groups.map((group) => {
      group.isSelected = selectAll;
      return group;
    });
    const updatedData = {
      ...this.state.groupList,
      contact_groups: newGroupData,
    };
    this.setState({ groupList: updatedData });
    this.handleSelectGroupId();
  };

  handleSelectGroupId = () => {
    const filteredData = this.state.groupList.contact_groups.filter(
      (group) => group.isSelected
    );
    const groupIds = filteredData.map((group) => group.id);
    this.setState({ selectedIds: groupIds }, () =>
      this.checkAllSelectedGroup()
    );
  };

  checkAllSelectedGroup = () => {
    if (
      this.state.selectedIds.length ==
      this.state.groupList.contact_groups.length
    ) {
      this.setState({ selectAll: true });
    } else {
      this.setState({ selectAll: false });
    }
  };

  updateSelectedGroupData = (groupId: number) => {
    const newGroupData = this.state.groupList.contact_groups.map((group) => {
      if (group.id === groupId) {
        group.isSelected = !group.isSelected;
      }
      return group;
    });
    const updatedData = {
      ...this.state.groupList,
      contact_groups: newGroupData,
    };
    this.setState({ groupList: updatedData });
    this.handleSelectGroupId();
  };

  handleDeleteConfirmationGroupDialog = (groupId: number) => {
    this.setState({ deleteConfirmationGroupId: groupId });
  };

  // Contacts
  getContactApiCallId = "";
  deleteContactApiCallId = "";

  handleIfCondition = (
    condition: boolean,
    ifTrue: string,
    ifFalse: string | boolean
  ) => {
    return condition ? ifTrue : ifFalse;
  };

  handleSelectAllContactDelete = (selectAll: boolean) => {
    const newContactData = this.state.contactList.contacts.data.map(
      (contact) => {
        contact.isSelected = selectAll;
        return contact;
      }
    );
    const updatedData = {
      ...this.state.contactList,
      contacts: { data: newContactData },
    };
    this.setState({ contactList: updatedData });
    this.handleSelectContactId();
  };

  handleSelectContactId = () => {
    const filteredData = this.state.contactList.contacts.data.filter(
      (contact) => contact.isSelected
    );
    const contactIds = filteredData.map((contact) => contact.id);
    this.setState({ selectedIds: contactIds }, () =>
      this.checkAllSelectedContact()
    );
  };

  checkAllSelectedContact = () => {
    if (
      this.state.selectedIds.length ==
      this.state.contactList.contacts.data.length
    ) {
      this.setState({ selectAll: true });
    } else {
      this.setState({ selectAll: false });
    }
  };

  handleDeleteConfirmationContactDialog = (contactId: number) => {
    this.setState({ deleteConfirmationContactId: contactId });
  };

  updateSelectedContactData = (contactId: number) => {
    const newContactData = this.state.contactList.contacts.data.map(
      (contact) => {
        if (contact.id === contactId) {
          contact.isSelected = !contact.isSelected;
        }
        return contact;
      }
    );
    const updatedData = {
      ...this.state.contactList,
      contacts: { data: newContactData },
    };
    this.setState({ contactList: updatedData });
    this.handleSelectContactId();
  };

  getContactListAPICall = async (page = 1) => {
    this.setState({
      isLoading: true,
      openId: "",
      selectedMode: false,
      selectAll: false,
      selectedIds: [],
    });
    this.getContactApiCallId = await this.handleAPICall(
      `${configJSON.getContactApiEndPoint}?page=${page}&per_page=${
        this.state.perPage
      }&query=${this.state.search}`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetContactAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetContactData;
    if (responseJsonData && responseJsonData.contacts) {
      this.setState({
        contactList: {
          ...responseJsonData,
          contacts: {
            data: responseJsonData.contacts.data.map((contact) => ({
              ...contact,
              isSelected: false,
            })),
          },
        },
        isLoading: false,
      });
    } else {
      this.setState({
        contactList: {
          contacts: {
            data: [],
          },
          current_page: 0,
          search: false,
          total_contacts: 0,
        },
        isLoading: false,
      });
    }
  };

  handleDeleteContact = async (deleteId: number | number[]) => {
    this.setState({ isLoading: true, deleteConfirmationContactId: 0 });

    this.deleteContactApiCallId = await this.handleAPICall(
      `${configJSON.getContactApiEndPoint}/?contact_ids=[${deleteId}]`,
      configJSON.deleteAPiMethod
    );
  };

  receiveDeleteContactAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as SuccessData;
    if (responseJsonData && responseJsonData.message) {
      this.getContactListAPICall();
    }
  };

  // Customizable Area End
}

interface TableComponentProps {
  groupList: GetGroupData;
  selectedMode: boolean;
  updateSelectedGroupData: (id: number) => void;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditGroup: (id: number) => void;
  handleDeleteConfirmationGroupDialog: (id: number) => void;
  handleSelectOption: () => void;
  handleToggleAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseAction: () => void;
  webStyle: { [key: string]: React.CSSProperties };
  openId: string | null;
  perPage: number;
}

const TableComponent: React.FC<TableComponentProps> = memo((props) => {
  const {
    groupList,
    selectedMode,
    updateSelectedGroupData,
    handlePageChange,
    handleRowsPerPageChange,
    handleEditGroup,
    handleDeleteConfirmationGroupDialog,
    handleSelectOption,
    handleToggleAction,
    handleCloseAction,
    webStyle,
    openId,
    perPage,
  } = props;

  return (
    <TableContainer component={Paper} style={{ boxShadow: "none" }}>
      <Table>
        <TableHead>
          <TableRow>
            {selectedMode ? (
              <TableCell className="tableHeader" />
            ) : (
              <>
                <TableCell className="tableHeader" />
                <TableCell className="tableHeader" />
              </>
            )}
            <TableCell className="tableHeader">
              {configJSON.name}
            </TableCell>
            <TableCell className="tableHeader textAlignEnd">
              {configJSON.action}
            </TableCell>
            <TableCell className="tableHeader" />
            <TableCell className="tableHeader" />
          </TableRow>
        </TableHead>
        <TableBody>
          {groupList.contact_groups.map((group) => (
            <TableRow key={group.id} data-test-id="groupTableRow">
              {selectedMode ? (
                <TableCell component="th" scope="row">
                  <StyledCheckbox
                    onClick={() => updateSelectedGroupData(group.id)}
                    data-test-id={`checkbox-delete`}
                    checked={group.isSelected}
                  />
                </TableCell>
              ) : (
                <>
                  <TableCell component="th" scope="row" />
                  <TableCell component="th" scope="row" />
                </>
              )}
              <TableCell
                component="th"
                scope="row"
                data-test-id="rowDataName"
                className="tableRow"
              >
                {group.name}
              </TableCell>
              <TableCell className="tableRow textAlignEnd">
                <ClickAwayListener
                  mouseEvent={openId === `${group.id}` ? "onClick" : false}
                  onClickAway={handleCloseAction}
                >
                  <CustomTooltip
                    interactive
                    open={`${group.id}` === openId}
                    placement="left-start"
                    title={
                      <React.Fragment>
                        <Button
                          data-test-id="edit-group"
                          style={webStyle.tooltipButton}
                          onClick={() => handleEditGroup(group.id)}
                        >
                          {configJSON.edit}
                        </Button>
                        <Button
                          data-test-id="select-group"
                          style={webStyle.tooltipButton}
                          onClick={handleSelectOption}
                        >
                          {configJSON.select}
                        </Button>
                        <Button
                          data-test-id="delete-group"
                          style={{
                            ...webStyle.tooltipButton,
                            ...webStyle.redText,
                          }}
                          onClick={() =>
                            handleDeleteConfirmationGroupDialog(group.id)
                          }
                        >
                          {configJSON.delete}
                        </Button>
                      </React.Fragment>
                    }
                  >
                    <ActionButton
                      data-test-id="groupActionButton"
                      name={`${group.id}`}
                      size="small"
                      onClick={handleToggleAction}
                      style={{
                        background:
                          `${group.id}` === openId ? "#0099FF" : "white",
                      }}
                    >
                      <MoreVert
                        fontSize="medium"
                        style={{
                          zIndex: 2,
                          color: `${group.id}` === openId ? "white" : "#0F172A",
                        }}
                      />
                    </ActionButton>
                  </CustomTooltip>
                </ClickAwayListener>
              </TableCell>
              <TableCell component="th" scope="row" />
              <TableCell component="th" scope="row" />
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className="borderNone"
              colSpan={8}
              count={groupList.total_count}
              rowsPerPage={perPage}
              page={groupList.current_page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
});
