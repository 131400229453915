// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../fileattachment/src/Helpers/create-request-message";
import { expireTokenHandling } from "../../../components/src/Utilities";

export const configJSON = require("./config");

type SuccessData = {
  message: string;
};

type APIErrorData = {
  errors: string[];
};

type ContactGroupData = {
  id: number;
  name: string;
  is_default?: boolean;
};

export type GetGroupData = {
  contact_groups: ContactGroupData[];
  current_page: number;
  total_pages: number;
  total_count: number;
  search: boolean;
};

type GetContactDetails = {
  id: number;
  name: string;
  company: string;
  contact_number: string;
  email: string;
  contact_group_id: number;
};

type ResponseData =
  | GetGroupData
  | SuccessData
  | APIErrorData
  | GetContactDetails;

export type FormData = {
  name: string;
  company: string;
  contact_number: string;
  email: string;
  contact_group_id?: number;
};
export type TouchData = {
  contact_number: boolean;
  email: boolean;
};
export type ErrorData = {
  contact_number: string;
  email: string;
};

const initialData: FormData = {
  name: "",
  company: "",
  contact_number: "",
  email: "",
  contact_group_id: 0,
};

const initialErrors: ErrorData = {
  contact_number: "",
  email: "",
};

const initialTouch: TouchData = {
  contact_number: false,
  email: false,
};
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  editContactId?: number;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  formData: FormData;
  formErrors: ErrorData;
  formTouch: TouchData;
  groupList: ContactGroupData[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddContactController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      formData: initialData,
      formErrors: initialErrors,
      formTouch: initialTouch,
      groupList: [],
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiResponseId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const successData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      expireTokenHandling(successData);
      if (apiResponseId === this.getAllGroupAPICallId) {
        this.receiveGetGroup(successData);
      }
      if (apiResponseId === this.createContactAPICallId) {
        this.receiveCreateContact(successData);
      }
      if (apiResponseId === this.getContactDetailsAPICallId) {
        this.receiveGetContactDetails(successData);
      }
    }
  };

  getAllGroupAPICallId = "";
  createContactAPICallId = "";
  getContactDetailsAPICallId = "";

  async componentDidMount() {
    this.getGroupAPI();
  }

  goToScreen = (route: string) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationNavigate));
    navigationMsg.addData(getName(MessageEnum.NavigationNavigateData), {
      route,
    });
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMsg);
  };

  handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof FormData;
    let value = event.target.value;
    const regex = new RegExp(/^[a-zA-Z 0-9]*$/);
    if (name === "name" && !regex.test(value as string)) {
      return;
    }

    if (name === "contact_number") {
      const regex = new RegExp(/[A-z]/g);
      const newValue = (value as string).replace(regex, "");
      value = newValue;
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => this.checkValidation()
    );
  };

  handleOnBlur = (field: keyof TouchData) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        formTouch: {
          ...prevState.formTouch,
          [field]: true,
        },
      }),
      () => this.checkValidation()
    );
  };

  some(...values: boolean[]) {
    return values.some((value) => value);
  }

  isSaveBtnDisabled = () => {
    return this.some(
      !this.state.formData.name,
      !this.state.formData.contact_number
    );
  };

  setError = (field: keyof ErrorData, error: string) => {
    this.setState((prevState) => ({
      ...prevState,
      formErrors: {
        ...prevState.formErrors,
        [field]: error,
      },
    }));
  };

  checkValidation = () => {
    this.setState({ formErrors: initialErrors });
    let isValid = true;

    const numberRegex = new RegExp(
      /^\+?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4,6})$/
    );

    if (
      this.state.formTouch.contact_number &&
      !numberRegex.test(this.state.formData.contact_number)
    ) {
      this.setError("contact_number", configJSON.invalidNumber);
      isValid = false;
    }

    if (
      this.state.formTouch.contact_number &&
      !this.state.formData.contact_number
    ) {
      this.setError("contact_number", configJSON.requiredField);
      isValid = false;
    }

    const regex = new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    );

    if (
      this.state.formTouch.email &&
      this.state.formData.email &&
      !regex.test(this.state.formData.email)
    ) {
      this.setError("email", configJSON.invalidEmail);
      isValid = false;
    }

    return isValid;
  };

  handleSave = () => {
    const isValid = this.checkValidation();
    if (isValid) {
      const formData = this.state.formData;
      const contact: FormData = {
        name: formData.name,
        company: formData.company,
        contact_number: formData.contact_number,
        email: formData.email,
      };
      if (formData.contact_group_id) {
        contact.contact_group_id = formData.contact_group_id;
      }

      this.createContactAPI(JSON.stringify({ contact }));
    }
  };

  getGroupAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllGroupAPICallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage,
      endPoint: configJSON.getGroupApiEndPoint,
      method: configJSON.validationApiMethodType,
      header: header,
    });
  };

  receiveGetGroup = (successData: ResponseData) => {
    const responseData = successData as GetGroupData;
    if (responseData && responseData.contact_groups) {
      const defaultGroup = responseData.contact_groups.find(
        (group) => group.is_default
      );
      const getList = responseData.contact_groups.map((group) => ({
        id: group.id,
        name: group.name,
        is_default: group.is_default,
      }));
      const contact_group_id = defaultGroup && defaultGroup.id;
      this.setState((prev) => ({
        ...prev,
        groupList: getList,
        formData: {
          ...initialData,
          contact_group_id,
        },
      }));
    }
    if (this.props.editContactId) {
      this.getContactDetailsAPI();
    }
  };

  createContactAPI = async (body: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createContactAPICallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage,
      endPoint: this.props.editContactId
        ? `${configJSON.createContactApiEndPoint}/${this.props.editContactId}`
        : configJSON.createContactApiEndPoint,
      method: this.props.editContactId
        ? configJSON.putAPiMethod
        : configJSON.exampleAPiMethod,
      header: header,
      body,
    });
  };

  receiveCreateContact = (successData: ResponseData) => {
    if (successData && (successData as SuccessData).message) {
      this.goToScreen("AddressBook");
    } else if ("errors" in successData) {
      let initialAPIErrors: ErrorData = { ...initialErrors };
      for (const value of successData.errors) {
        if (value.search("Contact number") >= 0) {
          initialAPIErrors.contact_number = configJSON.numberInUse;
        }
        if (value.search("Email") >= 0) {
          initialAPIErrors.email = configJSON.emailInUse;
        }
      }
      this.setState((prev) => ({ ...prev, formErrors: initialAPIErrors }));
      return;
    }
  };

  getContactDetailsAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContactDetailsAPICallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage,
      endPoint: `${configJSON.getContactApiEndPoint}/${this.props.editContactId}`,
      method: configJSON.validationApiMethodType,
      header: header,
    });
  };

  receiveGetContactDetails = (successData: ResponseData) => {
    const responseData = successData as GetContactDetails;
    if (responseData && responseData.id) {
      this.setState((prev) => ({
        ...prev,
        formData: {
          name: responseData.name,
          company: responseData.company,
          contact_number: responseData.contact_number,
          email: responseData.email,
          contact_group_id: Number(responseData.contact_group_id),
        },
      }));
    }
  };
  // Customizable Area End
}
