import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    daySelected: any
    handleSelectDay: (day: Date) => void;
    handleSelectRangeDate: (rangeDay: any) => void;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class CalendarController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    handleTileClass({ date }: any){
        const {daySelected = []} = this.props
        const startDaySelected = daySelected?.length ? daySelected[0] : ""
        if(typeof startDaySelected !== "string"){
            if(startDaySelected > date)
                return 'lessThanToday'
            return null
        }
        return null
    }
    // Customizable Area End
}