// Customizable Area Start
import React from "react";
import { Typography, Box, styled, Container } from "@material-ui/core";
import AddressBookActionController, {
  Props,
  images,
  configJSON,
  TTabIndex,
} from "./AddressBookActionController";
import Layout from "../../../components/src/Layout.web";
import AddContact from "./AddContact.web";
import AddBulkAgent from "../../importexportdata/src/AddBulkAgent.web";

const TAB_ACTION: {
  id: TTabIndex;
  label: string;
}[] = [
  {
    id: "addContact",
    label: "Add Contact",
  },
  {
    id: "bulkContact",
    label: "Add Bulk Contact",
  },
];
// Customizable Area End

export default class AddressBookAction extends AddressBookActionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout menuItem="addressBook" navigation={this.props.navigation}>
        <MainContainer data-test-id="addressBookActionComponent">
          <Box className="addressBookActionHeader">
            <Box className="headerBox">
              <img src={images.group} width={64} height={48} />
              <Typography className="addressBookActionHeaderTitle">
                {configJSON.addressBook}
              </Typography>
            </Box>
          </Box>
          <Box className="addressBookActionTabContainer">
            <Container maxWidth="xl" className="tabContainer">
              <Box className="tabBox">
                {TAB_ACTION.map((tabs) => (
                  <Box
                    key={tabs.id}
                    className={`tabItem ${
                      tabs.id !== this.state.tabIndexValue
                        ? "tabItem-disable"
                        : ""
                    }`}
                    onClick={() => this.handleChangeTab(tabs.id)}
                    data-test-id="tabItem"
                  >
                    <Typography component="h3" className="labelAction">
                      {tabs.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Container>
            <Container
              className="actionContainer"
              data-test-id="actionContainer"
              style={{
                paddingTop:
                  this.state.tabIndexValue === "addContact" ? "20px" : "0px",
              }}
            >
              {this.state.tabIndexValue === "addContact" ? (
                <AddContact
                  id={this.props.id}
                  navigation={this.props.navigation}
                />
              ) : (
                <AddBulkAgent {...this.props} type="Contact" />
              )}
            </Container>
          </Box>
        </MainContainer>
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(() => ({
  gap: "20px",
  display: "grid",
  "& .headerBox": {
    display: "inline-flex",
    alignItems: "center",
    gap: "24px",
    flexWrap: "wrap",
  },
  "& .addressBookActionHeader": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
    padding: "5px",
  },
  "& .addressBookActionHeaderTitle": {
    color: "#281D19",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "32px",
  },
  "& .addressBookActionTabContainer": {
    display: "flex",
    fontFamily: "Poppins",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px 8px 0 0",
    "& .tabContainer": {
      padding: "0 40px",
      background: "#FFFFFF",
      borderRadius: "8px 8px 0 0",
      boxShadow: "0px 4px 8px 0px #00000008",
      "& .tabBox": {
        display: "flex",
        height: "100%",
      },
      "@media (max-width:600px)": {
        padding: "0 8px",
      },
    },
    "& .tabItem": {
      cursor: "pointer",
      display: "flex",
      height: "45px",
      margin: "18px 44px 18px 0px",
      alignItems: "center",
      "@media (max-width:600px)": {
        marginRight: "8px",
      },
    },
    "& .tabItem-disable": {
      "& .labelAction": {
        color: "#64748B",
      },
    },
    "& .labelAction": {
      background: "linear-gradient(99.09deg, #1676C0 2.64%, #13A9EC 100%)",
      backgroundClip: "text",
      color: "#0099FF",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      fontFamily: "Poppins",
      "@media (max-width:600px)": {
        fontSize: "18px",
      },
    },
  },
  "& .actionContainer": {
    maxWidth: "100%",
    margin: "2px 0 0",
    display: "flex",
    flexDirection: "column",
    padding: "20px 40px",
    borderRadius: "8px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 8px 0px #00000008",
    "@media (max-width:600px)": {
      padding: "8px",
    },
  },
}));
// Customizable Area End
