import React from "react";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import { styled } from "@material-ui/styles";
import { MenuItem, Select } from "@material-ui/core";

export default function CustomSelectMenu({
  list,
  name,
  value,
  onChange,
  disabled,
}: {
  list: { id: string; label: string }[];
  name: string;
  value?: string;
  onChange?: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => void;
  disabled?: boolean;
}) {
  const SelectStyled = styled(Select)({
    width: "100%",
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px #CBD5E1 solid",
      color: "#1E293B",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "24px",
      width: "100%",
      padding: "12px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-icon": {
      top: 1,
      right: 1,
      padding: "12px",
      background: "#F1F5F9",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      color: "#0F172A",
    },
  });

  return (
    <SelectStyled
      data-test-id="CustomSelectMenu"
      className="SelectCustom"
      id="CustomSelectMenu"
      name={name}
      value={value}
      onChange={onChange}
      IconComponent={ArrowDropDownRoundedIcon}
      MenuProps={{
        className: "menuProps",
      }}
      disabled={disabled}
    >
      {list.map((menu) => (
        <MenuItem
          key={menu.id}
          value={menu.id}
          style={{
            fontSize: "14px",
          }}
        >
          {menu.label}
        </MenuItem>
      ))}
    </SelectStyled>
  );
}
