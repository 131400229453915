// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { removeStorageData } from "../../../framework/src/Utilities";
import { expireTokenHandling, getLastPartOfURL } from "../../../components/src/Utilities";

export const configJSON = require("./config");
export const images = require("./assets");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  blogDetailsResponse: {
    id: string;
    images_and_videos: string;
    name: string;
    author_name: string;
    created_at: string;
    body: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BlogDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      blogDetailsResponse: {
        id: "",
        images_and_videos: "",
        name: "",
        author_name: "",
        created_at: "",
        body: "",
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson)

      if (responseJson && apiRequestCallId === this.blogDetailsApiCallId) {
        this.receiveBlogDetailsAPICall(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  blogId = getLastPartOfURL();
  blogDetailsApiCallId: string = "";

  async componentDidMount() {
    await this.getBlogDetailsData();
    await this.handleResetAuth();
    const headingElement = document.getElementById("myref");
    if (headingElement) headingElement.scrollIntoView({ behavior: "smooth" });
  }

  goToBlogListing = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "BlogListing");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleResetAuth = async () => {
    await removeStorageData("authToken");
    await removeStorageData("isDashboard");
    await removeStorageData("name");
    await removeStorageData("data");
    await removeStorageData("userId");
  };

  getBlogDetailsData = () => {
    const headers = { "Content-Type": configJSON.validationApiContentType };

    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blogDetailsAPIEndPoint}${this.blogId}`
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.blogDetailsApiCallId = createApiMsg.messageId;

    runEngine.sendMessage(createApiMsg.id, createApiMsg);
  };

  receiveBlogDetailsAPICall = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        blogDetailsResponse: responseJson.data.attributes,
      });
    }
  };
  // Customizable Area End
}
