// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { removeStorageData } from "../../../framework/src/Utilities";
import { expireTokenHandling } from "../../../components/src/Utilities";

export const configJSON = require("./config");
export const images = require("./assets");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchText: string;
  blogCategoryResponse: { id: string; name: string; isSelected: boolean }[];
  blogListingResponse: {
    id: string;
    name: string;
    category_name: string;
    author_name: string;
    images_and_videos: string;
    created_at: string;
    description_palin_text: string;
  }[];
  totalPage: number;
  isLoading: boolean;
  pageNumber: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BlogListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      searchText: "",
      blogCategoryResponse: [],
      blogListingResponse: [],
      totalPage: 0,
      isLoading: true,
      pageNumber: 1,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson)

      if (responseJson) {
        if (apiRequestCallId === this.blogListingApiCallId) {
          this.receiveBlogListingAPICall(responseJson);
        }
        if (apiRequestCallId === this.blogCategoryApiCallId) {
          this.receiveBlogCategoryAPICall(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  blogListingApiCallId: string = "";
  blogCategoryApiCallId: string = "";

  async componentDidMount() {
    await this.getBlogListingData();
    await this.getBlogCategoryData();
    await this.handleResetAuth();
    const headingElement = document.getElementById("myref");
    if (headingElement) headingElement.scrollIntoView({ behavior: "smooth" });
  }

  handleAPICall = (apiEndPoint: string) => {
    const headers = { "Content-Type": configJSON.validationApiContentType };

    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleResetAuth = async () => {
    await removeStorageData("authToken");
    await removeStorageData("isDashboard");
    await removeStorageData("name");
    await removeStorageData("data");
    await removeStorageData("userId");
  };

  goToScreen = (pageName: string, blogId?: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (blogId) {
      msg.addData(getName(MessageEnum.NavigationScreenNameMessage), blogId);
    }
    this.send(msg);
  };

  handleTagClick = (index: number) => {
    const newTagList = [...this.state.blogCategoryResponse];
    newTagList[index].isSelected = !newTagList[index].isSelected;
    this.setState({ blogCategoryResponse: newTagList, searchText: "" }, () =>
      this.getBlogListingData()
    );
  };

  handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 2) {
      const newTagList = this.state.blogCategoryResponse.map((tag) => {
        tag.isSelected = false;
        return tag;
      });
      this.setState(
        {
          searchText: event.target.value,
          blogCategoryResponse: newTagList,
        },
        () => this.getBlogListingData()
      );
    } else {
      this.setState({ searchText: event.target.value }, () => {
        if (this.state.searchText.length === 0) this.getBlogListingData();
      });
    }
  };

  getBlogListingData = (page = 1) => {
    this.setState({ isLoading: true, pageNumber: page });
    let endpoint = `${configJSON.blogListingAPIEndPoint}${page}`;
    if (this.state.searchText) {
      endpoint = endpoint + `&query=${this.state.searchText}`;
    }
    this.state.blogCategoryResponse
      .filter((category) => category.isSelected)
      .forEach((category) => {
        endpoint = endpoint + `&category_id[]=${category.id}`;
      });
    this.blogListingApiCallId = this.handleAPICall(endpoint);
  };

  receiveBlogListingAPICall = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        blogListingResponse: responseJson.data.map(
          (item: any) => item.attributes
        ),
        totalPage: Math.ceil(responseJson.data[0]?.attributes?.total_count / 6),
      });
    }
    this.setState({ isLoading: false });
    const headingElement = document.getElementById("myref");
    if (headingElement) headingElement.scrollIntoView({ behavior: "smooth" });
  };

  getBlogCategoryData = () => {
    this.blogCategoryApiCallId = this.handleAPICall(
      configJSON.blogCategoryAPIEndPoint
    );
  };

  receiveBlogCategoryAPICall = (responseJson: Array<{ id: string; name: string }>) => {
    if (responseJson.length) {
      this.setState({
        blogCategoryResponse: responseJson.map((item: { id: string; name: string }) => ({
          ...item,
          isSelected: false,
        })),
      });
    }
  };
  // Customizable Area End
}
