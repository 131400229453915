import React from "react";
import {
    Box,
    IconButton,
    Typography,
    styled,
    // Customizable Area Start
    Button,
    ClickAwayListener,
    Tooltip,
    withStyles,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MonthViewController, { Props } from "./MonthViewController.web";
import { arrowUp } from "./assets";
import { MoreVert } from "@material-ui/icons";
// Customizable Area End

export default class MonthView extends MonthViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MonthViewContainer>
                <Box className="month__header">
                    
                    <Typography className="month-name" component="h4">
                        {this.handleGetLabelMonth()}
                    </Typography>
                    <IconButton id="toogle" className={`${this.state.isShowToogle ? "" : "toogleDown"}`} aria-label="arrow up" onClick={() => this.handleClickToogle()}>
                        <img alt="arrow up" src={arrowUp} />
                    </IconButton>
                </Box>
                {
                    this.state.isShowToogle &&
                    <Box className="month__listHoliday">
                        {
                            this.props.holidayValue.map((holiday: any, index: any) => {
                                const { date, title, department, id } = holiday
                                return (
                                    <Box key={index} className="month__holiday">
                                        <Box width="100%">
                                            <Box className="holiday-information">
                                                <Typography className="holiday-title">
                                                    {title}
                                                </Typography>
                                                <Typography className="holiday-date">
                                                    {typeof date !== "string" ? `${this.formatDate(date[0])} - ${this.formatDate(date[date.length-1])}` : this.formatDate(date)}
                                                </Typography>
                                            </Box>
                                            <Box className="holiday-information">
                                                <Typography className="department" style={{ opacity: `${department ? 1 : 0}` }}>
                                                    Department: {department}
                                                </Typography>
                                                <Typography className="dayOfWeek">
                                                    {typeof date !== "string" ? `${this.getDayOfWeek(date[0])} - ${this.getDayOfWeek(date[date.length-1])}` : this.getDayOfWeek(date)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <ClickAwayListener
                                            mouseEvent={this.state.openId === `${id}` ? "onClick" : false}
                                            onClickAway={this.handleCloseAction}
                                        >
                                            <CustomTooltip
                                                interactive
                                                open={this.state.openId === `${id}`}
                                                placement="left-start"
                                                title={
                                                    <React.Fragment>
                                                        <Button
                                                            data-test-id={`edit-holiday-${id}`}
                                                            style={webStyle.tooltipButton}
                                                            onClick={() => this.props.handleEditHoliday(id)}
                                                        >
                                                            Edit Holiday
                                                        </Button>
                                                        <Button
                                                            data-test-id={`delete-holiday-${id}`}
                                                            style={{ ...webStyle.tooltipButton, ...webStyle.redText }}
                                                            onClick={() => this.props.handleDeleteHoliday(id)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </React.Fragment>
                                                }
                                            >
                                                <ActionButton
                                                    style={{ background: this.state.openId === `${id}` ? '#0099FF' : "transparent" }}
                                                    data-test-id={`action-${id}`}
                                                    name={id}
                                                    size="small"
                                                    onClick={this.handleToggleAction}
                                                >
                                                    <MoreVert fontSize="inherit" style={{ zIndex: 2, color: this.state.openId === `${id}` ? "white" : "rgba(0, 0, 0, 0.54)" }} />
                                                </ActionButton>
                                            </CustomTooltip>
                                        </ClickAwayListener>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                }
            </MonthViewContainer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const MonthViewContainer = styled(Box)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "& .month__header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "24px",
        background: "#EDEDED",
        borderBottom: "1px solid #CBD5E1",
        borderRadius: "8px 8px 0px 0px",
        "& .month-name": {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#334155"
        }
    },
    "& .toogleDown": {
        transform: "rotate(180deg)"
    },
    "& .month__listHoliday": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        maxHeight: "240px",
        overflowY: "auto",
        "& ::-webkit-scrollbar": {
            width: "10px"
        },
        "& ::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#EAEAEA"
        },
        "& ::-webkit-scrollbar-thumb": {
            background: "#ffffff",
            borderRadius: "10px",
            border: "3px solid #EAEAEA"
        },
        "& ::-webkit-scrollbar-thumb:hover": {
            background: "#efffff"
        },
        "& .month__holiday": {
            background: "#F8FAFC",
            padding: "12px 8px 12px 8px",
            display: "flex",
            alignItems: "center",
            gap: "10px"
        },
        "& .holiday-information": {
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            "& .holiday-title,.holiday-date": {
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#334155"
            },
            "& .department,.dayOfWeek": {
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#475569"
            },
            "& .dayOfWeek": {
                whiteSpace: "nowrap",
            }
        }
    }
}))

export const CustomTooltip = withStyles({
    tooltip: {
        width: 182,
        display: "flex",
        rowGap: 2,
        flexDirection: "column",
        marginTop: 20,
        backgroundColor: "white",
        marginRight: -2,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#E2E8F0",
        borderRadius: 8,
        boxShadow: "0px 4px 8px 0px #00000008",
    },
})(Tooltip)

export const ActionButton = styled(IconButton)(() => ({
    padding: 8,
    "&:before": {
      position: "absolute",
      content: '""',
      zIndex: 1,
      height: "calc(100%)",
      width: "calc(100%)",
      borderRadius: "50%",
      opacity: 0,
    },
    "&:focus": {
      opacity: 1,
      "&:before": {
        opacity: 1,
      },
    },
}))

const webStyle = {
    tooltipButton: {
      textTransform: "none",
      justifyContent: "start",
      fontSize: 14,
      fontWeight: 400,
      color: "#0F172A",
      fontFamily: "Poppins"
    },
    redText: {
      color: "red",
    },
} as const;
// Customizable Area End
