import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { CSSProperties } from "react";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  style?: CSSProperties;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isMobileOpen: boolean,
  isClosing: boolean
  // Customizable Area End
}
interface SS {}

export default class HeaderLandingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  links = [
    {
      id: "aboutUs",
      text: "About Us",
      link: "/"
    },
    {
      id: "products",
      text: "Products",
      link: "/"
    },
    {
      id: "solutions",
      text: "Solutions",
      link: "/"
    },
    {
      id: "integration",
      text: "Integration",
      link: "/"
    },
    {
      id: "developer",
      text: "Developer",
      link: "/"
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
        isMobileOpen: false,
        isClosing: false
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  handleDrawerClose = () => {
    this.setState({isClosing: true});
    this.setState({isMobileOpen: false});
  };

  handleDrawerTransitionEnd = () => {
    this.setState({isClosing: false});
  };

  handleDrawerToggle = () => {
    if (!this.state.isClosing) {
      this.setState({isMobileOpen: !this.state.isMobileOpen});
    }
  };
  // Customizable Area End
}
