import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import EditManagerController, { Props } from "./EditManagerController";
import Layout from "../../../components/src/Layout.web";
import { group } from "./assets";
import AddManager from "./AddManager.web";
// Customizable Area End

export default class EditManager extends EditManagerController{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      console.log("saSA",this.state),
      <Layout menuItem="manager" navigation={this.props.navigation}>
        <Box>
          <Box style={webStyle.header}>
            <Box display="inline-flex" alignItems="center">
              <img src={group}
                width={64}
                height={48}
              />
              <Typography style={webStyle.headerTitle}>Edit Manager</Typography>
            </Box>
          </Box>
        </Box>
        <Box style={webStyle.mainContentContainer}>
          {this.state.editManager?
          <AddManager editManager={this.state.editManager} navigation={this.props.navigation} />:null}
        </Box>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: 24,
    marginLeft: 24,
    marginRight: 24,
  },
  mainContentContainer: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 8,
    padding: 40,
  },
} as const
// Customizable Area End
