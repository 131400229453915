import { toast } from "react-toastify";
import MergeEngineUtilities from "../../blocks/utilities/src/MergeEngineUtilities";
import { getStorageData } from "../../framework/src/Utilities";

export function getLastPartOfURL() {
  let url = window.location.pathname;
  let parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  return lastPart;
}

export const expireTokenHandling = (response: any) => {
  if (
    response &&
    response.errors &&
    response.errors.length &&
    (response.errors[0].token ||
      (response.errors[0].message &&
        response.errors[0].message.includes("token invalid")))
  ) {
    toast.error("Session expired. Please login again.", {
      autoClose: 5000,
      toastId: "ToastSessionExpired",
    });
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/login";
    }, 2000);
  }
  if (!response) {
    toast.error("Something went wrong, Please try later.", {
      autoClose: 5000,
      toastId: "ToastSessionExpired",
    });
  }
  return;
};

export const RoleType = {
  All: "company agent manager",
  Company: "company",
  Agent: "agent",
  Manager: "manager",
};

export const checkUserAuth = async (type: string, prop: Object) => {
  const token = await getStorageData("authToken");
  const userRole = await getStorageData("role");
  if (typeof token !== "string" || token === "" || !type.includes(userRole)) {
    MergeEngineUtilities.navigateToScreen("LandingPage", prop);
  }
};
