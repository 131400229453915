import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

// Customizable Area Start
import * as Yup from 'yup'
import { FormikErrors, FormikHelpers } from "formik";
import createRequestMessage from "../../fileattachment/src/Helpers/create-request-message";
import { getStorageData } from "framework/src/Utilities";
import { Manager } from "./ManagerController";
import { expireTokenHandling } from "../../../components/src/Utilities";
const mobilePhoneRegex = configJSON.mobilePhoneRegex;

const alphanumericRegex = /^[a-zA-Z0-9\s]+$/
const numericSpecialCharRegex = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/
export const validationSchema = Yup.object({
  managerName: Yup.string()
    .matches(alphanumericRegex, {
      excludeEmptyString: true,
      message: configJSON.invalidCharacterError,
    })
    .required(configJSON.requiredField),
  mobile: Yup.string()
    .matches(mobilePhoneRegex, {
      excludeEmptyString: true,
      message: configJSON.invalidMobileNumber,
    })
    .required(configJSON.requiredField),

  extension: Yup.string()
    .matches(numericSpecialCharRegex, {
      excludeEmptyString: true,
      message: configJSON.invalidCharacterError,
    })
    .required(configJSON.requiredField),
  emailId: Yup.string().email("Invalid email-address!").required(configJSON.requiredField),

  username: Yup.string()
    .test("is-username", configJSON.usernameWhitespaceError, val => !/\s/.test(val))
    .required(configJSON.requiredField),
  password: Yup.string().min(6, configJSON.passwordGenericError)
    .test("is-password", configJSON.passwordGenericError, val => /\d/.test(val) && /[a-z]/.test(val) && /[A-Z]/.test(val) && /[^a-zA-Z0-9\s]/.test(val))
    .required(configJSON.requiredField),

})
export type FormData = {
  managerName: string;
  mobile: string;
  extension: string;
  emailId: string;
  username: string;
  password: string;

}
export type ErrorData = {
  errors: string[]
}
export type SuccessData = {
  message: string
}
export type IOption = { id: any; name: string };

export type IMainDepartmnetList = {
  departmentList: IOption[];
  selectedDepatmentList: IOption[];
};

// Customizable Area End

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    editManager?: Manager
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    formData: FormData
    initialErrors: FormikErrors<FormData>;
    resetPassword: boolean
    departmentData: IOption[];
    selectedDepartment:IOption[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class AddManagerController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    createManagerApiId = "";
    updateAgentApiId = "";
    getAllDepartmentAPICallId=""
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];

      this.state = {
        formData: {
          managerName: "",
          mobile: "",
          extension: "",
          emailId: "",
          username: "",
          password: "",
          
        },
        initialErrors: {},
        departmentData: [],
        selectedDepartment:[],
        resetPassword: false,
      };
      // Customizable Area End

      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount = async () => {
      // Customizable Area Start
      await this.getDepartmentAPI();
      if (this.props.editManager) {
        const ea = this.props.editManager
        
        this.setState(prev => ({
          ...prev,
          formData: {
            managerName: ea.attributes.name,
            mobile: ea.attributes.phone_number.toString(),
            extension: ea.attributes.extension,
            emailId: ea.attributes.email,
            username: ea.attributes.username,
            password: configJSON.testPass,

          },
        }))
      }
        

      

      // Customizable Area End
    }

    receive = (from: string, message: Message) => {
      // Customizable Area Start
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const messageId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )
        const successData = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        )
        expireTokenHandling(successData)
        if (messageId === this.createManagerApiId) {

           this.handleCreateUpdateManager(successData)
        } else if (messageId === this.updateAgentApiId) {
          this.handleCreateUpdateManager(successData, true)
        }
        else if (messageId === this.getAllDepartmentAPICallId) {
          this.receiveLoadDepartments(successData);
        }
      }
      // Customizable Area End
    }

    // Customizable Area Start
  
    handleSubmit = async (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
      
      let manager = {
        
        "name": values.managerName,
        "phone_number": values.mobile,
        "extension": values.extension,
        "email": values.emailId,
        "username": values.username,
        "password": values.password,
        "password_confirmation": values.password,
        "department_ids":this.state.selectedDepartment.map(item => item.id)
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      )
      const editManager = this.props.editManager
      if (!editManager) {
        this.createManagerApiId = requestMessage.messageId
        createRequestMessage({
          requestMessage,
          endPoint: configJSON.createManagerEndpoint,
          method: configJSON.postAPIMethod,
          token: await getStorageData("authToken"),
          body: JSON.stringify({manager}),
        })
      } else {
        let updateManager: object = manager
        if (!this.state.resetPassword) {
          let {password, password_confirmation, ...rest} = manager
          updateManager = rest
        }
        this.updateAgentApiId = requestMessage.messageId
        createRequestMessage({
          requestMessage,
          endPoint: `${configJSON.createManagerEndpoint}/${editManager.id}`,
          method: configJSON.putAPIMethod,
          token: await getStorageData("authToken"),
          body: JSON.stringify({manager: updateManager}),
        })
      }
    }

    handleCreateUpdateManager = (sData: ErrorData | SuccessData, isUpdate: boolean = false) => {
      if ("errors" in sData) {
        let initialErrors: FormikErrors<FormData> = {}
        for (const val of sData.errors) {
          if (val.search("Username") >= 0) {
            initialErrors.username = configJSON.usernameExistError
          } else if (val.search("Email") >= 0) {
            initialErrors.emailId = configJSON.emailaddExistError
          } else if (val.search("Phone number") >= 0) {
            initialErrors.mobile = configJSON.mobileExistError
          }
        }
        this.setState(prev => ({ ...prev, initialErrors: initialErrors }))
        return
      }
     
        this.props.navigation.goBack()
      
    }

    areAllTrue(...values: boolean[]) {
      return values.every(val => val)
    }

    some(...values: boolean[]) {
      return values.some(val => val)
    }
    
    handleClearInitialError(
      fieldName: keyof FormData,
    ) {
      this.setState(prev => {
        prev.initialErrors[fieldName] = undefined;
        return prev
      })
    }

    handleClearInitialErrorOnChange(
      handleChange: {
          (e: React.ChangeEvent<any>): void;
          <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
      },
      includeValidatedOnlyWhileTyping: boolean = true
    ) {
      return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let name = event.target.name as (keyof FormData)
        let value = event.target.value
        this.handleClearInitialError(name)
        try {
          if (includeValidatedOnlyWhileTyping && value !== "") {
            try {
              validationSchema.fields[name].validateSync(value)
            } catch (error) {
              if (
                (name !== "mobile")
                || (
                  (value.match(/\d/g)?.length ?? 0) >= 10
                  || !numericSpecialCharRegex.test(value)
                 )
              ) {
                throw error
              }
            }
          }
          handleChange(event)
        } catch (_) {
          // noop
        }
      }
    }

    handleClearInitialErrorOnBlur(
      handleBlur: {
          (e: React.FocusEvent<any>): void;
          <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
      },
    ) {
      return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement>) => {
        this.handleClearInitialError(event.target.name as (keyof FormData))
        handleBlur(event)
      }
    }
    handleAgentChange = (value: any) => {
      const newValue = [...(value as IOption[])];

      
      
      this.setState({ selectedDepartment: newValue }, () => {
        console.log("selectedDepartment:", this.state.selectedDepartment);
      });
    };
    receiveLoadDepartments = (successData: any) => {
      if (successData.departments) {
        const getdata = successData.departments.data.map(
          (department: { id: number; attributes: { name: string } }) => ({
            id: department.id,
            name: department.attributes.name,
          })
        );
        this.setState((prev) => ({
          ...prev,
          departmentData: getdata,
                   
       
          // mainAgentList: [{ agentList: getList, selectedAgentList: [] }],
        }));
        if (this.props.editManager) {
          const ea = this.props.editManager;
         
        const filteredArray = getdata.filter((item: { id: any; }) => {
          return ea.attributes.departments.some((secondItem: { id: any; }) => secondItem.id == item.id);
        });
        this.setState({selectedDepartment:filteredArray})
      }
      }
    };
    handleEditResetPassword(values: FormData) {
      return () => {
        this.setState(prev => ({ ...prev, formData: { ...values, password: "" }, resetPassword: true }))
      }
    }
    getDepartmentAPI = async () => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        Token: await getStorageData("authToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getAllDepartmentAPICallId = requestMessage.messageId;
      createRequestMessage({
        requestMessage,
        endPoint: configJSON.getDepartmentEndpoint,
        method: configJSON.validationApiMethodType,
        header: header,
      });
    };
    // Customizable Area End
}
