import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    month: any,
    value: any
    year: any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    allSelectedDate: string[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class CalendarViewController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
        this.state ={
            allSelectedDate: this.props.value.map((v: any) => v.date).join(",").split(",")
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.value !== this.props.value) {
            this.setState({
                allSelectedDate: this.props.value.map((v: any) => v.date).join(",").split(",")
            })
        }
    }
    handleGetActiveStartDate(month: number, year: string) {
        return new Date(Number(year), month, 1)
    }

    formatDate(date: Date){
        return date.toLocaleString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
    }
    
    handleTileClass({ date }: any, month: number) {
        if (new Date(date).getMonth() === month){
            if (this.state.allSelectedDate.includes(this.formatDate(date)))
                return "react-calendar__tile--active react-calendar__tile--rangeBothEnds"
            
        }
        return ""
    }
    formatLabelMonthYear(locale: any, date: any) {
        return date.toLocaleDateString(locale, { month: 'long' });
    }
    // Customizable Area End
}