Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ImportExportData";
exports.labelBodyText = "ImportExportData Body";

exports.btnExampleTitle = "CLICK ME";

exports.exportSuccess = "Your file exported to path: ";
exports.exportCsvLabel = "Export CSV";
exports.exportJsonLabel = "Export JSON";
exports.loadingText = "Loading...";

exports.jsonApiContentType = "application/json";
exports.csvApiContentType = "text/csv";
exports.exportDataEndpoint = "data_import_export/export";
exports.exportDataMethod = "GET";
exports.errorMsg = "Something went wrong."

exports.downloadFileApiEndPoint = "bx_block_calendar/holidays/sample_csv?format="
exports.verifyFileApiEndPoint = "bx_block_calendar/holidays/validate_file_headers"
exports.postDataMethod = "POST"
exports.bulkUploadApiEndPoint = "bx_block_calendar/holidays/bulk_upload"
exports.agentDownloadFileApiEndPoint = "bx_block_stickyagent2/agents/sample_csv?format="
exports.agentVerifyFileApiEndPoint = "bx_block_stickyagent2/agents/validate_file_headers"
exports.agentBulkUploadApiEndPoint = "bx_block_stickyagent2/agents/bulk_upload"
exports.managerDownloadFileApiEndPoint = "bx_block_account_groups/managers/sample_csv?format="
exports.managerVerifyFileApiEndPoint = "bx_block_account_groups/managers/validate_file_headers"
exports.managerBulkUploadApiEndPoint = "bx_block_account_groups/managers/bulk_upload"
exports.breakDownloadFileApiEndPoint = "bx_block_calendar/breaks/sample_csv?format="
exports.breakVerifyFileApiEndPoint = "bx_block_calendar/breaks/validate_file_headers"
exports.breakBulkUploadApiEndPoint = "bx_block_calendar/breaks/bulk_upload"
exports.contactDownloadFileApiEndPoint = "bx_block_leadmanagement3/contacts/sample_csv?format="
exports.contactVerifyFileApiEndPoint = "bx_block_leadmanagement3/contacts/validate_file_headers"
exports.contactBulkUploadApiEndPoint = "bx_block_leadmanagement3/contacts/bulk_upload"

exports.step1 = "Step 1 : Download sample template & Modify your file accordingly"
exports.downloadTemplate = "Download Template as:"
exports.csv = "CSV"
exports.xlsx = "XLSX"
exports.download = "Download"
exports.step2 = "Step 2 : Upload the file"
exports.step2Description = "Upload the edited template by drag & drop or click the button below."
exports.uploadMedia = "Upload media"
exports.fileTypeSupported = "File type supported: csv, xlsx"
exports.browseFileToUpload = "Browse file to upload"
exports.step2Description1 = "Please make sure you use same format as per the sample reference file."
exports.projectFile = "Project files"
exports.submit = "Submit"
exports.moreFileError = "Please select one file at a time for upload."
exports.invalidFileTypeError = "The selected file does not match the allowed file types of CSV and Xls."
// Customizable Area End