import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
type NavigationData = {}
// Customizable Area End

export const configJSON = require("./config");
export type TTabIndex = "addManager" | "bulkManager"
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    tabIndexValue: TTabIndex
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class ManagerActionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            tabIndexValue: "addManager"
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    handleChangeTab(tabIndex: TTabIndex) {
        if (tabIndex !== this.state.tabIndexValue)
            this.setState({ tabIndexValue: tabIndex })
    }
    async componentDidMount() {
        if(!window.localStorage.getItem('authToken'))
            this.props.navigation.navigate('LandingPage')
    }

    receive = async (from: string, message: Message) => {
      runEngine.debugLog("Message Received", message);

      if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
        const data = message.getData(
          getName(MessageEnum.NavigationNavigateData)
        )
        this.handleReceiveNavigation(data)
      }
    };

    handleReceiveNavigation = (_: NavigationData) => {

    }
    // Customizable Area End
}
