Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "blogpostsmanagement";
exports.labelBodyText = "blogpostsmanagement Body";
exports.BlogpostEndPoint = "bx_block_blogpostsmanagement/blogposts";
exports.btnExampleTitle = "CLICK ME";

exports.blogListingAPIEndPoint = "bx_block_posts/posts?per_page=6&page=";
exports.blogCategoryAPIEndPoint = "bx_block_posts/posts/post_categories";
exports.blogDetailsAPIEndPoint = "bx_block_posts/posts/";
// Customizable Area End