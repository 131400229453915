// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  styled,
  InputBase,
  SwitchProps,
  Switch,
  FormGroup,
  Select,
  MenuItem,
  IconButton,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import AddDepartmentController, {
  Props,
  images,
  configJSON,
} from "./AddDepartmentController.web";
import Layout from "../../../components/src/Layout.web";
import CustomRadioButton from "../../../components/src/CustomRadioButton.web";
// Customizable Area End

export default class AddDepartment extends AddDepartmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderForm = () => {
    return (
      <FormGroup
        style={{
          padding: "24px 40px",
          borderRadius: "8px",
          background: "#fff",
        }}
      >
        <DepartmentInformationContainer data-test-id="department-container">
          <Box>
            <InputLabel
              className="InputLabelCustom"
              error={!!this.state.formErrors.departmentName}
              htmlFor="departmentName"
            >
              Department Name*
            </InputLabel>
            <InputBase
              data-test-id="departmentName"
              className="InputBaseCustom"
              id="departmentName"
              name="departmentName"
              type="text"
              value={this.state.formData.departmentName}
              onChange={this.handleChange}
              error={!!this.state.formErrors.departmentName}
            />
            {!!this.state.formErrors.departmentName && (
              <Typography color="error">
                {this.state.formErrors.departmentName}
              </Typography>
            )}
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="connectionMode">
              Connection Mode*
            </InputLabel>
            <Select
              data-test-id="connectionMode"
              className="SelectCustom"
              id="connectionMode"
              name="connectionMode"
              value={this.state.formData.connectionMode}
              onChange={(e) => this.handleChange(e)}
              IconComponent={ExpandMoreRoundedIcon}
              MenuProps={{
                className: "menuProps",
              }}
            >
              {AddDepartment.CONNECTION_MODE_CONSTANT.map((mode) => (
                <MenuItem key={mode.id} value={mode.id} style={styles.menuItem}>
                  {mode.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="switchBox">
            <InputLabel className="InputLabelCustom" htmlFor="recordCalls">
              Record Calls*
            </InputLabel>
            <SwitchCustom
              data-test-id="recordCalls"
              id="recordCalls"
              name="recordCalls"
              checked={this.state.formData.recordCalls}
              onChange={(e, c) => this.handleChange(e, true, c)}
            />
          </Box>
          <Box>
            <InputLabel
              className="InputLabelCustom"
              htmlFor="ringingTime.first"
              style={{ padding: "12px 0" }}
            >
              Ringing Time*
            </InputLabel>
            <div className="timeBox">
              <InputLabel
                className="InputLabel2Custom"
                htmlFor="ringingTime.first"
              >
                SS
              </InputLabel>
              <div>
                <InputBase
                  data-test-id="ringingTimeFirst"
                  className="TimeInputBaseCustom"
                  id="ringingTime.first"
                  name="first"
                  type="text"
                  placeholder="-"
                  value={this.state.formData.ringingTime.first}
                  onChange={(e) => this.handleNumberChange(e, "ringingTime")}
                />
                <InputBase
                  data-test-id="ringingTimeSecond"
                  className="TimeInputBaseCustom"
                  id="ringingTime.second"
                  name="second"
                  type="text"
                  placeholder="-"
                  value={this.state.formData.ringingTime.second}
                  onChange={(e) => this.handleNumberChange(e, "ringingTime")}
                />
              </div>
            </div>
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="fallBackRules">
              FallBack Rules*
            </InputLabel>
            <Select
              data-test-id="fallBackRules"
              className="SelectCustom"
              id="fallBackRules"
              name="fallBackRules"
              value={this.state.formData.fallBackRules}
              onChange={(e) => this.handleChange(e)}
              IconComponent={ExpandMoreRoundedIcon}
              MenuProps={{
                className: "menuProps",
              }}
            >
              {AddDepartment.FALLBACK_RULES_CONSTANT.map((rules) => (
                <MenuItem
                  key={rules.id}
                  value={rules.id}
                  style={styles.menuItem}
                >
                  {rules.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="agent-box">
            <div className="agent-text">
              Agents *{" "}
              <span>(Choose specific levels for different Agents)</span>
            </div>
            {this.state.mainAgentList.map((list, i) => {
              const isLastAgent = i === this.state.mainAgentList.length - 1;
              return (
                <div className="agent-listing" key={`list_${i}`}>
                  <div className="priority-div">
                    <div className="agent-text-1">Priority</div>
                    <div className="priority-box">{i + 1}</div>
                  </div>
                  <div
                    className="agent-div"
                    style={!i || !isLastAgent ? { marginRight: "42px" } : {}}
                  >
                    <div className="agent-text-1">Agents</div>
                    <div className="agent-sd-box">
                      <div className="agent-search-box">
                        <div className="search-box">
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            disabled={!isLastAgent}
                            clearOnBlur
                            disableClearable
                            value={list.selectedAgentList}
                            options={list.agentList}
                            getOptionLabel={(option: {
                              name: string;
                              id: number;
                            }) => {
                              return option.name;
                            }}
                            onChange={(_, newValue) => {
                              this.handleAgentChange(newValue, i);
                            }}
                            renderInput={(params) => (
                              <div>
                                <TextField
                                  {...params}
                                  placeholder="Select Agent ..."
                                />
                                <SearchRoundedIcon className="search" />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      {!!i && isLastAgent && (
                        <IconButton
                          data-test-id="delete-agent"
                          onClick={this.handleDeleteAgent}
                        >
                          <DeleteOutlineRoundedIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <Button
              data-test-id="add-another-levels"
              className="add-another-levels"
              onClick={this.handleAddAnotherLevels}
              disabled={
                !!this.state.mainAgentList.length &&
                !this.state.mainAgentList[this.state.mainAgentList.length - 1]
                  .selectedAgentList.length
              }
            >
              <AddCircleOutlineRoundedIcon />
              Another Levels
            </Button>
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="configureTimings">
              Configure Timings*
            </InputLabel>
            <Select
              data-test-id="configureTimings"
              className="SelectCustom"
              id="configureTimings"
              name="configureTimings"
              value={this.state.formData.configureTimings}
              onChange={(e) => this.handleChange(e)}
              IconComponent={ExpandMoreRoundedIcon}
              MenuProps={{
                className: "menuProps",
              }}
            >
              {AddDepartment.CONFIGURE_TIMINGS_CONSTANT.map((timings) => (
                <MenuItem key={timings} value={timings} style={styles.menuItem}>
                  {timings}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel
              className="InputLabelCustom"
              style={{ padding: "12px 0" }}
            >
              Days:*
            </InputLabel>
            <CustomRadioButton
              list={[
                { id: "1", label: "Weekday" },
                { id: "2", label: "Weekend" },
                { id: "3", label: "Custom" },
              ]}
              name="days"
              value={this.state.formData.days}
              onRadioChange={this.handleChange}
            />
            <div className="dayList">
              {this.state.formData.dayList.map((day, i) => (
                <Button
                  data-test-id="dayListButton"
                  key={day.name}
                  variant={day.isSelected ? "outlined" : "text"}
                  className="day-list-button"
                  disabled={this.state.formData.days !== "3"}
                  onClick={() => this.handleDayClick(i, !day.isSelected)}
                >
                  {day.name}
                </Button>
              ))}
            </div>
          </Box>
          <Box>
            <InputLabel
              className="InputLabelCustom"
              style={{ padding: "12px 0" }}
              error={this.some(
                !!this.state.formErrors.fromTime,
                !!this.state.formErrors.toTime
              )}
            >
              Time:*
            </InputLabel>
            <InputLabel className="InputLabel2Custom">From</InputLabel>
            <div className="timeBox">
              <div className="timeBox">
                <InputLabel
                  className="InputLabelCustom"
                  error={!!this.state.formErrors.fromTime}
                  htmlFor="fromTimeHH.first"
                >
                  HH
                </InputLabel>
                <div>
                  <InputBase
                    data-test-id="fromTimeHHFirst"
                    className="TimeInputBaseCustom"
                    id="fromTimeHH.first"
                    name="first"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.fromTimeHH.first}
                    onChange={(e) => this.handleNumberChange(e, "fromTimeHH")}
                    error={!!this.state.formErrors.fromTime}
                  />
                  <InputBase
                    data-test-id="fromTimeHHSecond"
                    className="TimeInputBaseCustom"
                    id="fromTimeHH.second"
                    name="second"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.fromTimeHH.second}
                    onChange={(e) => this.handleNumberChange(e, "fromTimeHH")}
                    error={!!this.state.formErrors.fromTime}
                  />
                </div>
              </div>
              <div className="timeBox">
                <InputLabel
                  className="InputLabelCustom"
                  error={!!this.state.formErrors.fromTime}
                  htmlFor="fromTimeHH.first"
                >
                  MM
                </InputLabel>
                <div>
                  <InputBase
                    data-test-id="fromTimeMMFirst"
                    className="TimeInputBaseCustom"
                    id="fromTimeMM.first"
                    name="first"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.fromTimeMM.first}
                    onChange={(e) => this.handleNumberChange(e, "fromTimeMM")}
                    error={!!this.state.formErrors.fromTime}
                  />
                  <InputBase
                    data-test-id="fromTimeMMSecond"
                    className="TimeInputBaseCustom"
                    id="fromTimeMM.second"
                    name="second"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.fromTimeMM.second}
                    onChange={(e) => this.handleNumberChange(e, "fromTimeMM")}
                    error={!!this.state.formErrors.fromTime}
                  />
                </div>
              </div>
            </div>
            <InputLabel
              className="InputLabel2Custom"
              style={{ marginTop: "20px" }}
            >
              To
            </InputLabel>
            <div className="timeBox">
              <div className="timeBox">
                <InputLabel
                  className="InputLabelCustom"
                  error={!!this.state.formErrors.toTime}
                  htmlFor="toTimeHH.first"
                >
                  HH
                </InputLabel>
                <div>
                  <InputBase
                    data-test-id="toTimeHHFirst"
                    className="TimeInputBaseCustom"
                    id="toTimeHH.first"
                    name="first"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.toTimeHH.first}
                    onChange={(e) => this.handleNumberChange(e, "toTimeHH")}
                    error={!!this.state.formErrors.toTime}
                  />
                  <InputBase
                    data-test-id="toTimeHHSecond"
                    className="TimeInputBaseCustom"
                    id="toTimeHH.second"
                    name="second"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.toTimeHH.second}
                    onChange={(e) => this.handleNumberChange(e, "toTimeHH")}
                    error={!!this.state.formErrors.toTime}
                  />
                </div>
              </div>
              <div className="timeBox">
                <InputLabel
                  className="InputLabelCustom"
                  error={!!this.state.formErrors.toTime}
                  htmlFor="toTimeMM.first"
                >
                  MM
                </InputLabel>
                <div>
                  <InputBase
                    data-test-id="toTimeMMFirst"
                    className="TimeInputBaseCustom"
                    id="toTimeMM.first"
                    name="first"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.toTimeMM.first}
                    onChange={(e) => this.handleNumberChange(e, "toTimeMM")}
                    error={!!this.state.formErrors.toTime}
                  />
                  <InputBase
                    data-test-id="toTimeMMSecond"
                    className="TimeInputBaseCustom"
                    id="toTimeMM.second"
                    name="second"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.toTimeMM.second}
                    onChange={(e) => this.handleNumberChange(e, "toTimeMM")}
                    error={!!this.state.formErrors.toTime}
                  />
                </div>
              </div>
            </div>
          </Box>
          <Box>
            <InputLabel
              className="InputLabelCustom"
              style={{ padding: "20px 0" }}
            >
              Department Option:*
            </InputLabel>
            <div className="switchBox">
              <InputLabel className="InputLabelCustom" htmlFor="stickyAgent">
                Sticky Agent
              </InputLabel>
              <SwitchCustom
                data-test-id="stickyAgent"
                id="stickyAgent"
                name="stickyAgent"
                checked={this.state.formData.stickyAgent}
                onChange={(e, c) => this.handleChange(e, true, c)}
              />
            </div>
            <div style={styles.radioButton}>
              <CustomRadioButton
                list={[
                  { id: "1", label: "Strict" },
                  { id: "2", label: "Loose" },
                ]}
                name="stickyAgentOption"
                value={this.state.formData.stickyAgentOption}
                onRadioChange={this.handleChange}
                disabled={!this.state.formData.stickyAgent}
              />
            </div>
            {this.state.formData.stickyAgentOption === "2" && (
              <div className="timeBox">
                <InputLabel
                  className="InputLabelCustom"
                  htmlFor="stickyAgentOptionTime.first"
                >
                  SS
                </InputLabel>
                <div>
                  <InputBase
                    data-test-id="stickyAgentOptionTimeFirst"
                    className="TimeInputBaseCustom"
                    id="stickyAgentOptionTime.first"
                    name="first"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.stickyAgentOptionTime.first}
                    onChange={(e) =>
                      this.handleNumberChange(e, "stickyAgentOptionTime")
                    }
                  />
                  <InputBase
                    data-test-id="stickyAgentOptionTimeSecond"
                    className="TimeInputBaseCustom"
                    id="stickyAgentOptionTime.second"
                    name="second"
                    type="text"
                    placeholder="-"
                    value={this.state.formData.stickyAgentOptionTime.second}
                    onChange={(e) =>
                      this.handleNumberChange(e, "stickyAgentOptionTime")
                    }
                  />
                </div>
              </div>
            )}
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="queueTimeout">
              Queue Timeout*
            </InputLabel>
            <Select
              data-test-id="queueTimeout"
              className="SelectCustom"
              id="queueTimeout"
              name="queueTimeout"
              value={this.state.formData.queueTimeout}
              onChange={(e) => this.handleChange(e)}
              IconComponent={ExpandMoreRoundedIcon}
              MenuProps={{
                className: "menuProps",
              }}
            >
              {AddDepartment.QUEUE_TIMEOUT_CONSTANT.map((timeout) => (
                <MenuItem
                  key={timeout.id}
                  value={timeout.id}
                  style={styles.menuItem}
                >
                  {timeout.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="addNation">
              Add Nation*
            </InputLabel>
            <Select
              data-test-id="addNation"
              className="SelectCustom"
              id="addNation"
              name="addNation"
              value={this.state.formData.addNation}
              onChange={(e) => this.handleChange(e)}
              IconComponent={ExpandMoreRoundedIcon}
              MenuProps={{
                className: "menuProps",
              }}
            >
              {AddDepartment.ADD_NATION_CONSTANT.map((nation) => (
                <MenuItem
                  key={nation.code}
                  value={nation.code}
                  style={styles.menuItem}
                >
                  {nation.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="addState">
              Add State*
            </InputLabel>
            <Select
              data-test-id="addState"
              className="SelectCustom"
              id="addState"
              name="addState"
              value={this.state.formData.addState}
              onChange={(e) => this.handleChange(e)}
              IconComponent={ExpandMoreRoundedIcon}
              MenuProps={{
                className: "menuProps",
              }}
            >
              {this.ADD_STATE_CONSTANT.map((state) => (
                <MenuItem key={state} value={state} style={styles.menuItem}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel className="InputLabelCustom" htmlFor="skillInput">
              Skills
            </InputLabel>
            <div className="list-box">
              {this.state.formData.skillList.map((skill, i) => (
                <div key={`${skill}_${i}`} className="input-box">
                  {skill}
                  <IconButton
                    data-test-id="deleteSkillBtn"
                    onClick={() => this.handleDeleteSkill(i)}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                </div>
              ))}
              <div className="input-box">
                <InputBase
                  data-test-id="skillInput"
                  id="skillInput"
                  name="skillInput"
                  placeholder="Enter Skill"
                  value={this.state.formData.skillInput}
                  onChange={this.handleChange}
                />
                <IconButton
                  data-test-id="addSkillBtn"
                  disabled={!this.state.formData.skillInput}
                  onClick={this.handleAddSkill}
                >
                  <AddRoundedIcon />
                </IconButton>
              </div>
            </div>
          </Box>
          <Box>
            <InputLabel
              className="InputLabelCustom"
              htmlFor="offlineCampaignNumbersInput"
            >
              Offline Campaign Numbers
            </InputLabel>
            <div className="list-box">
              {this.state.formData.offlineCampaignNumbersList.map(
                (number, i) => (
                  <div key={`${number}_${i}`} className="input-box">
                    {number}
                    <IconButton
                      data-test-id="deleteNumberBtn"
                      onClick={() => this.handleDeleteOfflineCampaignNumber(i)}
                    >
                      <ClearRoundedIcon />
                    </IconButton>
                  </div>
                )
              )}
              <div className="input-box">
                <InputBase
                  data-test-id="offlineCampaignNumbersInput"
                  id="offlineCampaignNumbersInput"
                  name="offlineCampaignNumbersInput"
                  className="number"
                  placeholder="Enter Number"
                  value={this.state.formData.offlineCampaignNumbersInput}
                  onChange={this.handleChange}
                />
                <IconButton
                  data-test-id="addNumberBtn"
                  disabled={!this.state.formData.offlineCampaignNumbersInput}
                  onClick={this.handleAddOfflineCampaignNumbersInput}
                >
                  <AddRoundedIcon />
                </IconButton>
              </div>
            </div>
          </Box>
          <Box>
            <Button
              data-test-id="saveButton"
              type="submit"
              className="ButtonCustom"
              onClick={this.handleSave}
              disabled={this.isSaveBtnDisabled()}
            >
              Save
            </Button>
          </Box>
        </DepartmentInformationContainer>
      </FormGroup>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout menuItem="manageDept" navigation={this.props.navigation}>
        <Box display="inline-flex" alignItems="center" gridGap={24}>
          <img src={images.group} width={64} height={48} />
          <Typography style={styles.departmentLabelContainer} component="h2">
            {configJSON.listDepartment}
          </Typography>
        </Box>
        {this.renderForm()}
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  departmentLabelContainer: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#281D19",
  },
  menuItem: {
    fontSize: "14px",
  },
  radioButton: {
    margin: "20px 0",
  },
} as const;

export const DepartmentInformationContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gridRowGap: "40px",
  maxWidth: "100%",
  marginTop: "9px",
  "& .agent-box": {
    background: "#F8FAFC",
    borderRadius: "16px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    maxWidth: "485px",
    "& .agent-text": {
      color: "#0099FF",
      fontWeight: 600,
      fontSize: "20px",
      fontFamily: "Poppins",
      lineHeight: "28px",
      "& span": {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
      },
    },
    "& .agent-listing": {
      display: "flex",
      alignItems: "flex-start",
      gap: "16px",
    },
    "& .agent-text-1": {
      color: "#64748B",
      fontWeight: 700,
      fontSize: "14px",
      fontFamily: "Poppins",
      lineHeight: "22px",
      marginBottom: "4px",
    },
    "& .priority-box": {
      background: "#FFFFFF",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      width: "60px",
      height: "56px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .agent-div": {
      width: "100%",
      "& .agent-sd-box": {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        "& .agent-search-box": {
          background: "#ffffff",
          width: "100%",
          padding: "12px 8px",
          border: "1px solid #CBD5E1",
          borderRadius: "8px",
          display: "flex",
          gap: "8px",
          flexWrap: "wrap",
          "& .search-box": {
            color: "#94A3B8",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            overflowWrap: "anywhere",
            width: "100%",
            position: "relative",
            "& .MuiInputBase-root": {
              color: "#94A3B8",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              gap: "8px",
              "&:before": {
                content: "none",
              },
              "&:after": {
                content: "none",
              },
              "& .MuiAutocomplete-input": {
                minWidth: "114px",
              },
              "& .MuiAutocomplete-tag": {
                margin: "0",
                background: "#F1F5F9",
              },
              "& .MuiChip-label": {
                color: "#334155",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
              },
            },
            "& .MuiIconButton-root": {
              padding: 0,
            },
            "& .search.MuiSvgIcon-root": {
              color: "#94A3B8",
              background: "#ffffff",
              position: "absolute",
              right: 0,
              bottom: "4px",
            },
          },
        },
        "& .MuiButtonBase-root": {
          padding: "0",
          marginRight: "13px",
          "& .MuiSvgIcon-root": {
            color: "#0099FF",
          },
        },
      },
    },
    "& .add-another-levels": {
      width: "fit-content",
      padding: 0,
      textTransform: "none",
      color: "#0099FF",
      fontWeight: 600,
      fontSize: "20px",
      fontFamily: "Poppins",
      lineHeight: "28px",
      "& .MuiButton-label": {
        gap: "18px",
      },
    },
  },
  "& .day-list-button": {
    "&.MuiButton-root": {
      textTransform: "none",
      color: "#64748B",
      fontWeight: 400,
      fontSize: "14px",
      fontFamily: "Poppins",
      lineHeight: "22px",
      padding: "2px 4px",
      minWidth: "48px",
      borderRadius: "20px",
      border: "1px solid transparent",
    },
    "&.MuiButton-outlined": {
      border: "1px solid #99D6FF",
      background: "#E5F5FF",
      color: "#0099FF",
    },
  },
  "& .list-box": {
    maxWidth: "504px",
    marginTop: "4px",
    padding: "12px 8px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
    "& .input-box": {
      background: "#F1F5F9",
      borderRadius: "26px",
      padding: "5px 8px 5px 12px",
      display: "flex",
      alignItems: "center",
      color: "#334155",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      overflowWrap: "anywhere",
      "& .number.MuiInputBase-root": {
        maxWidth: "100px",
      },
      "& .MuiInputBase-root": {
        maxWidth: "65px",
        color: "#94A3B8",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
      },
      "& .MuiInputBase-input": {
        padding: "4px 0",
      },
      "& .MuiIconButton-root": {
        padding: 0,
        marginLeft: "4px",
        "&.Mui-disabled": {
          "& .MuiSvgIcon-root": {
            color: "#94A3B8",
          },
        },
      },
      "& .MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
        color: "#334155",
      },
    },
  },
  "& .ButtonCustom": {
    maxWidth: "520px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "8px",
    padding: "16px",
    color: "#FFFFFF",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    marginTop: "10px",
    marginBottom: "93px",
    "&:disabled": {
      backgroundColor: "#E2E8F0",
      borderColor: "#CBD5E1",
      color: "#334155",
    },
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0099FF",
      borderColor: "#0099FF",
    },
  },
  "& .InputLabelCustom": {
    color: "#334155",
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Poppins",
    lineHeight: "26px",
  },
  "& .MuiFormLabel-root.Mui-error": {
    color: "#F87171",
  },
  "& .InputLabel2Custom": {
    color: "#334155",
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Poppins",
    lineHeight: "26px",
  },
  "& .InputBaseCustom": {
    maxWidth: "520px",
    width: "100%",
    marginTop: "4px",
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: "#F87171",
      },
    },
    "& .MuiInputBase-input": {
      maxWidth: "520px",
      height: "34px",
      borderRadius: 8,
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#CBD5E1",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
    },
  },
  "& .TimeInputBaseCustom": {
    margin: "0 2px",
    "&.Mui-error": {
      "& .MuiInputBase-input": {
        borderColor: "#F87171",
      },
    },
    "& .MuiInputBase-input": {
      maxWidth: "10px",
      height: "34px",
      borderRadius: 8,
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#CBD5E1",
      fontSize: 16,
      width: "100%",
      padding: "10px 30px",
    },
  },
  "& .SelectCustom": {
    maxWidth: "520px",
    width: "100%",
    marginTop: "4px",
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
    "& .MuiInputBase-input": {
      maxWidth: "520px",
      height: "34px",
      borderRadius: 8,
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#CBD5E1",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-icon": {
      right: 1,
      padding: "0 7px",
      background: "white",
    },
  },
  "& .switchBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "440px",
    height: "52px",
  },
  "& .timeBox": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "10px",
    flexWrap: "wrap",
  },
  "& .dayList": {
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px 0px #00000008",
    padding: "24px 16px",
    maxWidth: "488px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "20px",
  },
  "& .menuProps": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "12px",
    minWidth: "494px",
  },
  "@media (max-width:499px)": {
    "& .agent-box": {
      padding: "0px",
      display: "flex",
      "& .agent-listing": {
        flexDirection: "column",
      },
    },
  },
}));

const SwitchCustom = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 28,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        background: "linear-gradient(99.09deg, #1676C0 2.64%, #13A9EC 100%)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#FFFFFF",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    borderRadius: 40,
    backgroundColor: "#E2E8F0",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Customizable Area End
