import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { expireTokenHandling } from "../../../components/src/Utilities";
// Customizable Area Start
type TTypeModal = 'addNew' | 'addBulk' | false

type IHoliday = {
    id: number
    title: string
    department_id: number
    company_id: number
    start_date: string
    end_date: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isLoading: boolean,
    token: string,
    listYear: number[],
    currentYear: number,
    modalType: TTypeModal,
    currentDepartment: string | number,
    listDepartment: {id: string, name: string}[],
    newHolidayTitle: string,
    daySelected: Date[] | null,
    listHoliday: any[]
    formAddHoliday: {
        title: string,
        start_date: string,
        end_date: string
    },
    isOpenSelectYear: boolean,
    editHolidayId: number
    deleteConfirmationId: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class HolidayController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getListHolidayId = ''
    getListYearSelectId = ''
    getAddHolidayId = ''
    getDepartmentId = ''
    deleteHolidayCallId = ""
    getHolidayCallId = ""
    constructor(props: Props) {
        super(props);
        this.handleSelectDay = this.handleSelectDay.bind(this);
        this.receive = this.receive.bind(this);
        this.handleSelectRangeDate = this.handleSelectRangeDate.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];
        this.state = {
            isLoading: true,
            token: "",
            listYear: [],
            currentYear: new Date().getFullYear(),
            modalType: false,
            listDepartment: [],
            currentDepartment: 0,
            newHolidayTitle: "",
            daySelected: [],
            listHoliday: [],
            formAddHoliday: {
                title: "",
                start_date: "",
                end_date: ""
            },
            isOpenSelectYear: false,
            editHolidayId: 0,
            deleteConfirmationId: ""
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        expireTokenHandling(responseJson)

        this.receiveDepartmentList(apiRequestCallId, responseJson)
        this.receiveDeleteHoliday(apiRequestCallId, responseJson)
        this.receiveGetHoliday(apiRequestCallId, responseJson)
        if (apiRequestCallId === this.getListHolidayId) {
            if (!responseJson.length)
                this.setState({ listHoliday: [] })
            else {
                this.setState({ listHoliday: this.mappingGetDateApi(responseJson) })
            }
            this.setState({ isLoading: false })
        }
        if (apiRequestCallId === this.getListYearSelectId) {
            const { years } = responseJson || []
            const currentYearMoment = new Date().getFullYear()
            if (!years.length) {
                const year = currentYearMoment
                this.setState({ listYear: [year], currentYear: year, isLoading: false, listHoliday: [] })
            }
            else
                if (years.includes(currentYearMoment)) {
                    this.setState({ listYear: years, currentYear: this.state.currentYear })
                    this.getListHoliday(this.state.token, this.state.currentYear)
                } else {
                    this.setState({ listYear: years, currentYear: years[years.length - 1] })
                    this.getListHoliday(this.state.token, years[years.length - 1])
                }

        }
        if (apiRequestCallId === this.getAddHolidayId) {
            const { holiday } = responseJson
            if (!holiday) {
                this.resetFormData();
                this.handleCloseModalAdd()
            }
            this.getListYear(this.state.token)
            this.getListHoliday(this.state.token, this.state.currentYear)
            this.resetFormData();
            this.handleCloseModalAdd()
        }
    }

    async componentDidMount(): Promise<void> {
        if (!window.localStorage.getItem('authToken'))
            this.props.navigation.navigate('LandingPage')
        const token = window.localStorage.getItem("authToken") || ""
        this.setState({ token })
        this.getListYear(token)
        this.getListDepartment(token)
        window.addEventListener('wheel', this.handleWheel, { passive: false });
    }
    async componentWillUnmount() {
        window.removeEventListener('wheel', this.handleWheel);
    }
    handleWheel = (e: any) => {
        if (this.state.isOpenSelectYear && !this.dropdownContainsTarget(e.target))
            e.preventDefault()
    };

    dropdownContainsTarget(target: any) {
        const dropdown = document.querySelector('.MuiMenu-list');
        const dropdownItem = document.querySelectorAll('#menu- .MuiMenu-list .MuiListItem-root');
        return dropdown && dropdown.contains(target) && dropdownItem.length > 4;
    }

    getListYear(token: string) {
        const header = {
            "Content-Type": configJSON.applicationJsonApiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getListYearSelectId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getListYearApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getListDepartment(token: string) {
        const header = {
            "Content-Type": configJSON.applicationJsonApiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDepartmentId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.departmentEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    receiveDepartmentList = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.getDepartmentId) {
            if (responseJson && responseJson.departments) {
                this.setState({ listDepartment: responseJson.departments.data.map((dep: any) => ({
                    id: dep.id,
                    name: dep.attributes.name
                })) })
            }
        }
    }

    getListHoliday(token: string, currentYear?: number) {
        const header = {
            "Content-Type": configJSON.applicationJsonApiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getListHolidayId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.holidayApiEndPoint}?year=${currentYear ? currentYear : this.state.currentYear}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addNewHoliday(token: string, httpBody: any, holidayId?: number) {
        const header = {
            "Content-Type": configJSON.applicationJsonApiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAddHolidayId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            holidayId ? `${configJSON.holidayApiEndPoint}/${holidayId}` : configJSON.holidayApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            holidayId ? configJSON.putApiMethodType : configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    mappingGetDateApi(dataApi: any) {
        const isEmpty = dataApi.filter((data: Array<Object>) => data.length)
        if (!isEmpty.length) {
            if (this.state.listYear.length) {
                this.setState({currentYear: this.state.listYear[0]})
                this.getListHoliday(this.state.token, this.state.listYear[0])
            } else {
                this.getListYear(this.state.token)
            }
            return []
        }
        return dataApi?.map((data: any) => this.mappingHolidayApi(data))
    }
    mappingHolidayApi(listHoliday: any) {
        const validHoliday: any = [];
        if (listHoliday?.length) {
            listHoliday.forEach((holiday: any) => {
                if (new Date(holiday.start_date).getTime() !== new Date(holiday.end_date).getTime()) {
                    const map = this.mappingRangeDate(holiday.start_date, holiday.end_date);
                    validHoliday.push({
                        id: holiday.id,
                        date: map,
                        title: holiday.title,
                        department: holiday.department_name
                    })
                } else {
                    validHoliday.push({
                        id: holiday.id,
                        date: holiday.start_date,
                        title: holiday.title,
                        department: holiday.department_name
                    })
                }
            })
        }
        return validHoliday
    }
    mappingRangeDate(startDate: any, endDate: any) {
        const dates = [];
        let currentDate = new Date(startDate);
        currentDate.setHours(0, 0, 0, 0);

        while (currentDate <= new Date(endDate)) {
            dates.push(this.formatDate(new Date(currentDate)));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }
    formatDate(date: any) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    }
    handleChangeYear(e: any) {
        const currentYear = e.target.value;
        this.setState({ currentYear, isLoading: true })
        this.getListHoliday(this.state.token, currentYear)
    }
    handleSelectDepartment(e: any) {
        this.setState({ currentDepartment: e.target.value })
    }
    handleOpenModalAdd(typeModal: TTypeModal) {
        this.setState({ modalType: typeModal })
    }
    handleCloseModalAdd() {
        this.setState({ modalType: false });
        this.resetFormData();
    }
    handleTitleField(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({
            newHolidayTitle: e.target.value, formAddHoliday: {
                ...this.state.formAddHoliday,
                title: e.target.value
            }
        })
    }
    handleSelectDay(day: Date) {
        this.setState({
            daySelected: [day], formAddHoliday: {
                ...this.state.formAddHoliday,
                start_date: this.formatDate(day),
                end_date: this.formatDate(day)
            }
        })
    }
    handleSelectRangeDate(rangeDay: any) {
        if (rangeDay)
            this.setState({
                daySelected: rangeDay, formAddHoliday: {
                    ...this.state.formAddHoliday,
                    start_date: this.formatDate(rangeDay[0]),
                    end_date: this.formatDate(rangeDay[1])

                }
            })
    }
    handleCheckDisbaleAddNewHoliday() {
        return !(this.state.daySelected?.length && this.state.newHolidayTitle)
    }
    handleAddNewHoliday() {
        this.setState({ isLoading: true })
        const httpBody = {
            holiday: {...this.state.formAddHoliday, department_id: this.state.currentDepartment ? this.state.currentDepartment : ""}
        }
        this.addNewHoliday(this.state.token, httpBody, this.state.editHolidayId)
    }
    resetFormData() {
        this.setState({ newHolidayTitle: "", currentDepartment: 0, daySelected: null, editHolidayId: 0 })
    }

    handleDeleteConfirmationDialog = (holidayId: string) => {
        this.setState({ deleteConfirmationId: holidayId })
    }

    deleteHoliday = (holidayId: string) => {
        this.setState({ isLoading: true, deleteConfirmationId: "" })

        const header = {
            "Content-Type": configJSON.applicationJsonApiContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteHolidayCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.holidayApiEndPoint}/${holidayId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    receiveDeleteHoliday = (apiRequestCallId: string, responseJson: {message: string}) => {
        if (apiRequestCallId === this.deleteHolidayCallId) {
            if (responseJson && responseJson.message) {
                this.getListYear(this.state.token)
            }
        }
    }

    handleEditHoliday = (holidayId: string) => {
        const header = {
            "Content-Type": configJSON.applicationJsonApiContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getHolidayCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.holidayApiEndPoint}/${holidayId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    receiveGetHoliday = (apiRequestCallId: string, responseJson: {holiday: IHoliday}) => {
        if (apiRequestCallId === this.getHolidayCallId) {
            if (responseJson && responseJson.holiday) {
                const {title, department_id, start_date, end_date, id} = responseJson.holiday
                this.setState({ 
                    modalType: "addNew", 
                    formAddHoliday: { title, start_date, end_date }, 
                    newHolidayTitle: title, 
                    currentDepartment: department_id ? department_id : 0, 
                    daySelected: [new Date(start_date), new Date(end_date)],
                    editHolidayId: id
                })
            }
        }
    }
    // Customizable Area End
}