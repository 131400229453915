import React, { Fragment } from "react";
import {
    Box,
    Button,
    Container,
    FormControl,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
    styled,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import HolidayController, { Props } from "./HolidayController.web";
import {
    ExpandMore,
    ControlPoint,
    Close
} from "@material-ui/icons";
import { holidayIcon, noHoliday, uploadIcon } from "./assets";
import CalendarCustom from "./Calendar.web";
import 'react-calendar/dist/Calendar.css';
import CalendarView from "./CalendarView.web";
import MonthView from "./MonthView.web";
import AddBulkHoliday from "../../importexportdata/src/AddBulkHoliday.web";
import Loader from "../../../components/src/Loader.web";
import Layout from "../../../components/src/Layout.web";
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";
// Customizable Area End

export default class Holiday extends HolidayController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderLabelModal() {
        return this.state.editHolidayId ? "Edit Holiday" : "Add New Holiday"
    }
    renderModalContent() {
        if (this.state.modalType === 'addNew') return <Fragment>
            <Loader loading={this.state.isLoading} />
            <Typography className="short__description">
                Fill in below details to add a Holiday in your list
            </Typography>
            <FormControl fullWidth>
                <Typography className="label" variant="body1" gutterBottom>
                    Title {<span style={{ color: '#F87171' }}>*</span>}
                </Typography>
                <TextField placeholder="Enter Holiday Title" id="titleInput" variant="outlined" value={this.state.newHolidayTitle} onChange={(e) => this.handleTitleField(e)} />
            </FormControl>
            <FormControlSelect>
                <Typography className="label" gutterBottom>
                    Department (Optional)
                </Typography>
                <Select
                    id="selectDepartment"
                    value={this.state.currentDepartment}
                    onChange={(e) => this.handleSelectDepartment(e)}
                >
                    <MenuItem value={0}>
                        Select Department
                    </MenuItem>
                    {this.state.listDepartment.map(department => (
                        <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>
                    ))}
                </Select>
            </FormControlSelect>
            <SelectDateStyled>
                <Typography className="labelInput" component="h4">
                    Select Dates
                </Typography>
                <Box className="calendar__box" id="calendar">
                    <CalendarCustom daySelected={this.state.daySelected} handleSelectRangeDate={this.handleSelectRangeDate} handleSelectDay={this.handleSelectDay} />
                </Box>
            </SelectDateStyled>
        </Fragment>
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Layout menuItem="holiday" navigation={this.props.navigation}>
                <Loader loading={this.state.isLoading} />
                <ContainerStyled listHoliday={this.state.listHoliday}>
                    <Container maxWidth="xl" className="container">
                        <HeaderContainer>
                            <Box className="header__year">
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <img src={holidayIcon} alt="icon" width={62} />
                                    <Typography className="labelContainer" component="h2">
                                        Holidays
                                    </Typography>
                                </Box>
                                <SelectContainer>
                                    <Select
                                        onOpen = {() => {
                                            this.setState({isOpenSelectYear: true})
                                        }}
                                        onClose = {() => {
                                            this.setState({isOpenSelectYear: false})
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 44 * 3.4
                                                }
                                            },
                                            disableScrollLock: true,
                                        }}
                                        disabled={this.state.listYear.length <= 1}
                                        IconComponent={ExpandMore}
                                        id="selectYear"
                                        value={this.state.currentYear}
                                        onChange={(e) => this.handleChangeYear(e)}
                                    >
                                        {this.state.listYear.map(year => (
                                            <MenuItem
                                                style={{ color: "#0F172A", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", lineHeight: "22px" }}
                                                key={year}
                                                value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </SelectContainer>
                            </Box>
                            <ActionContainer>
                                <Box id="addNew" className="buttonIcon" onClick={() => this.handleOpenModalAdd('addNew')}>
                                    <ControlPoint />
                                    <Typography component="p" className="textButton">
                                        Add Holiday
                                    </Typography>
                                </Box>
                                <Box id="uploadBulk" className="buttonIcon" onClick={() => this.handleOpenModalAdd('addBulk')}>
                                    <img src={uploadIcon} alt="iconUpload" width={16} height={16} />
                                    <Typography component="p" className="textButton">
                                        Upload Bulk Holidays
                                    </Typography>
                                </Box>
                            </ActionContainer>
                            {this.state.modalType === 'addBulk' ? <AddBulkHoliday handleCloseModalAdd={() => this.handleCloseModalAdd()} fileUploadSuccessfully={() => {
                                this.getListYear(this.state.token)
                                this.handleCloseModalAdd()
                            }} /> :
                            <ModalContainer data-test-id="model_container" id="modal" open={Boolean(this.state.modalType)} onClose={() => this.handleCloseModalAdd()}>
                                <Box className="modal__container">
                                    <Box className="modal__header">
                                        <Typography component="h4">{this.renderLabelModal()}</Typography>
                                        <Button startIcon={<Close />} id="btnCloseModal" onClick={() => this.handleCloseModalAdd()} />
                                    </Box>
                                    <Box className="modal__content">
                                        {this.renderModalContent()}
                                        <Box className="modal__footer">
                                            <Box className="modal__action">
                                                <Button id="cancelBtn" onClick={() => this.handleCloseModalAdd()}>
                                                    Cancel
                                                </Button>
                                                <Button disabled={this.handleCheckDisbaleAddNewHoliday()} id="doneBtn" onClick={() => this.handleAddNewHoliday()}>
                                                    Done
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </ModalContainer>}
                        </HeaderContainer>
                        {!this.state.listHoliday.length && !this.state.isLoading ? (
                            <NoHolidayContainer>
                                <img alt="no_holiday" src={noHoliday} width={200} />
                                <Box className="text_description">
                                    <Typography component="strong">
                                        No Holiday Available
                                    </Typography>
                                    <Typography component="div">
                                        There is no data show you right now
                                    </Typography>
                                </Box>
                            </NoHolidayContainer>
                        ) :
                            <ListHolidayContainer listHoliday = {this.state.listHoliday}>
                                <Box className="listHoliday-month">
                                    {
                                        this.state.listHoliday.map((holiday, index) => {
                                            if (holiday?.length)
                                                return <MonthView data-test-id="month_view" month={index} holidayValue={holiday} handleDeleteHoliday={this.handleDeleteConfirmationDialog} handleEditHoliday={this.handleEditHoliday} />
                                        })
                                    }
                                </Box>
                                <Box className="listHoliday-calendar">
                                    {
                                        this.state.listHoliday.map((holiday, index) => (
                                            <Box className="calendar__item" key={index}>
                                                <CalendarView month={index} value={holiday} year={this.state.currentYear || null} />
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </ListHolidayContainer>
                        }
                    </Container>
                </ContainerStyled>
                <DeleteConfirmationDialog 
                    open={!!this.state.deleteConfirmationId}
                    onDone={() => this.deleteHoliday(this.state.deleteConfirmationId)}
                    onClose={() => this.handleDeleteConfirmationDialog("")}
                />
            </Layout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const SelectDateStyled = styled(Box)(({ theme }) => ({
    margin: "16px 0",
    "& .labelInput": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#64748B",
        textAlign: "left"
    },
    "& .calendar__box": {
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
        marginTop: "4px",
        padding: "8px 0 20px"
    }
}))

export const ListHolidayContainer = styled(Box)(({ theme, listHoliday }: any) => ({
    display: "flex",
    width: "100%",
     gap: "24px",
    marginBottom: "24px",
    "& .listHoliday-month": {
        flex: "0 0 calc(100%/12*4)",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: "8px",
        padding: "8px",
        height: "100%",
        display: listHoliday.length ? "flex" : "none",
        flexDirection: "column",
        gap: "8px",
        boxSizing: "border-box",
    },
    "& .listHoliday-calendar": {
        flex: "0 0 calc(100%/12*8 - 24px)",
        display: "flex",
        flexWrap: "wrap",
        height: "100%",
        gap: "24px",
        "& .calendar__item": {
            background: "#FFFFFF",
            flexBasis: "calc(50% - 24px)",
            padding: "4px",
            border: "1px solid #E2E8F0",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px 0px #00000008"
        },
        [theme.breakpoints.down(1330)]: {
            "& .calendar__item": {
                flexBasis: "100%",
                padding: "12px"
            },
        }
    },
    [theme.breakpoints.down(1250)]: {
        "& .listholiday-month,.listholiday-calendar": {
            flex: "0 0 calc(50% - 12px)"
        }
    },
    [theme.breakpoints.down(1000)]: {
        "& .listholiday-month": {
            flex: "0 0 100%"
        },
        "& .listholiday-calendar": {
            flex: 0,
            display: "none"
        }

    }

}))


export const NoHolidayContainer = styled(Box)(({ theme }) => ({
    background: "#FFFFFF",
    marginBottom: "16px",
    height: "100vh",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px 0px #00000008",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    flex: 1,
    "& .text_description": {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        alignItems: "center",
        "& strong": {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#000000",
        },
        "& p": {
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#000000",
        },
    }
}))

export const HeaderContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "24px",
    "& .header__year": {
        display: "flex",
        flex: "0 0 calc(100%/12*4)"
    },
    [theme.breakpoints.down(1250)]: {
        flexDirection: "column",
        marginBottom: "20px",
        alignItems: "normal",
        gap: "8px",
        "& .header__year": {
            minWidth: "340px",
            justifyContent: "space-between"
        }
    }
}))

export const CommonBoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2px",
    boxShadow: "0px 4px 8px 0px #00000008",
    "& #header": {
        marginLeft: "0",
    },
    background: "#FFFFFF"
}))

export const ModalContainer = styled(Modal)(({ theme }) => ({
    "& .modal__container": {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "610px",
        backgroundColor: '#FFFFFF',
        borderRadius: "8px",
        boxShadow: "0px 25px 50px 0px #00000017",
        width: "100%",
        maxHeight: '90%',
        [theme.breakpoints.down(620)]: {
            width: "95%"
        }
    },
    "& .modal__header": {
        padding: "24px 40px",
        borderBottom: "1px solid #E2E8F0",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        boxSizing: "border-box",
        "& h4": {
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
            color: "#000000"
        },
        "& #btnCloseModal": {
            marginRight: "-26px",
            width: "36px",
            height: "24px",
            minWidth: "auto",
            "& .MuiButton-label": {
                width: "24px",
                "& .MuiSvgIcon-root": {
                    color: "#334155"
                }
            },
            "& .MuiButton-startIcon": {
                margin: "0px"
            }
        }
    },
    "& .modal__content": {
        padding: "40px",
        maxHeight: '50vh',
        overflowY: "auto",
        overflowX: "hidden",
        "& ::-webkit-scrollbar": {
            width: "10px"
        },
        "& ::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#EAEAEA"
        },
        "& ::-webkit-scrollbar-thumb": {
            background: "#ffffff",
            borderRadius: "10px",
            border: "3px solid #EAEAEA"
        },
        "& ::-webkit-scrollbar-thumb:hover": {
            background: "#efffff"
        },
        "& .short__description": {
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#0F172A",
            marginBottom: "16px"
        },
        "& .label": {
            color: "#64748B",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "22px",
            marginBottom: "4px"
        },
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            height: "56px",
            "& .MuiInputBase-input": {
                padding: "10px 8px"
            },
            "& .MuiInputBase-input::placeholder": {
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#64748B",
                opacity: 1
            },
            "& fieldset": {
                border: "1px solid #CBD5E1",
            }
        }
    },
    "& .modal__footer": {
        marginTop: "40px",
        borderTop: "1px solid #E2E8F0",
        boxSizing: "border-box",
        width: "calc(100% + 80px)",
        marginLeft: "-40px",
        "& .modal__action": {
            padding: "24px 40px 0px",
            marginBottom: "-16px",
            wdith: "100%",
            display: "flex",
            gap: "16px",
            "& button": {
                textTransform: "capitalize",
                height: "56px",
                flex: "0 0 calc(50% - 8px)",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#0099FF",
                borderRadius: "8px",
            },
            "& #cancelBtn": {
                border: "1px solid #0099FF"
            },
            "& #doneBtn": {
                border: "none",
                fontWeight: 700,
                color: "#FFFFFF",
                background: "#0099FF",
            },
            "& #doneBtn.Mui-disabled": {
                background: "#F1F5F9",
                fontWeight: 700,
                color: "#64748B"
            }
        }
    }
}))
export const ActionContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flex: "0 0 calc(100%/12*8 - 24px)",
    flexDirection: "row-reverse",
    minWidth: "340px",
    gap: "24px",
    "& .buttonIcon": {
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        height: "44px",
        cursor: "pointer",
        "& .textButton": {
            fontSize: "16px",
            fontFamily: "Poppins",
            lineHeight: "24px",
        },
        padding: "10px 16px",
        gap: "8px",
        boxSizing: "border-box"
    },
    "& #addNew": {
        color: "#FFFFFF",
        maxWidth: "170px",
        width: "100%",
        backgroundColor: "#0099FF",
        "& .textButton": {
            fontWeight: 700,
            color: "#FFFFFF",
        },
        [theme.breakpoints.down(600)]: {
            justifyContent: "center",
            "& .textButton": {
                display: "none"
            },
        }
    },
    "& #uploadBulk": {
        display: "flex",
        height: "44px",
        maxWidth: "236px",
        width: "100%",
        backgroundColor: "transparent",
        border: "1px solid #0099FF",
        color: "#0099FF",
        "& .textButton": {
            fontWeight: 600,
            color: "#0099FF",
        },
        [theme.breakpoints.down(600)]: {
            justifyContent: "center",
            "& .textButton": {
                display: "none"
            },
        }
    },
    [theme.breakpoints.down('sm')]: {
        "& .buttonIcon": {
            fontSize: "12px"
        }
    }
}))

export const SelectContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    flex: 1,
    "& .MuiInputBase-root": {
        background: "#FFFFFF",
        width: "94px",
        height: "44px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .MuiInput-underline::before,.MuiInput-underline::after": {
        content: "none"
    },
    "& .MuiSelect-icon": {
        color: "#0F172A",
        marginRight: "4px"
    },
    "& #selectYear": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        color: "#0F172A",
        paddingLeft: "16px"
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
        background: "none"
    },
    "& #selectYear.MuiSelect-select:focus": {
        background: "transparent"
    },
    [theme.breakpoints.down("md")]: {
        marginLeft: "24px"
    }
}))

export const FormControlSelect = styled(FormControl)(({ theme }) => ({
    width: "100%",
    marginTop: "16px",
    "& .MuiInputBase-root": {
        width: "100%",
        height: "56px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .MuiInput-underline::before,.MuiInput-underline::after": {
        content: "none"
    },
    "& .MuiSelect-icon": {
        color: "#0F172A",
        marginRight: "4px"
    },
    "& #selectDepartment": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#64748B",
    },
    "& #selectDepartment.MuiSelect-select:focus": {
        background: "transparent"
    },
    "& .MuiList-root .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "transparent"
    },
}))

export const ContainerStyled = styled(Box)(({ theme, listHoliday }: any) => ({
    width: "100%",
    boxSizing: "border-box",
    background: "#F9F9F9",
    flex: 1,
    "& .container": {
        padding: 0,
        height: listHoliday.length ? "auto" : "100%",
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        "& .labelContainer": {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "32px",
            letterSpacing: "-0.005em",
            color: "#281D19 ",
            marginLeft: "24px",
        }
    },
}))
// Customizable Area End
