Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.getMethodType = "GET";
exports.headerApiEndPoint = "bx_block_content_management/landing_pages/header_section";
exports.logoImagesApiEndPoint = "bx_block_content_management/landing_pages/logo_listing";
exports.benefitApiEndPoint = "bx_block_content_management/landing_pages/benefits_card_section";
exports.textImageApiEndPoint = "bx_block_content_management/landing_pages/text_and_image_section";
exports.logoIntegrationApiEndPoint = "bx_block_content_management/landing_pages/logo_listing?logo_type=integration";
exports.resultsRevenueApiEndPoint = "bx_block_content_management/landing_pages/results_and_revenue_sections";
exports.customerFeedbackApiEndPoint = "bx_block_content_management/landing_pages/customer_feedback";
exports.blogListingApiEndPoint = "bx_block_posts/posts";
// Customizable Area End