import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    holidayValue: any;
    month: number;
    handleDeleteHoliday: (id: string) => void
    handleEditHoliday: (id: string) => void
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isShowToogle: boolean
    openId: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class MonthViewController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
        this.state = {
            isShowToogle: true,
            openId: ""
        }
    }
    handleClickToogle() {
        this.setState({ isShowToogle: !this.state.isShowToogle })
    }
    handleGetLabelMonth() {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return monthNames[this.props.month];
    }
    formatDate(inputDate: any) {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.toLocaleString('en', { month: 'short' });
        const formattedDate = `${day} ${month}`;

        return formattedDate;
    }
    getDayOfWeek(inputDate: any) {
        const date = new Date(inputDate);
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayIndex = date.getDay();

        return dayNames[dayIndex];
    }

    handleCloseAction = () => {
        this.setState({openId: ""})
    }

    handleToggleAction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { name } = event.currentTarget;
        this.setState(prevState => ({
            ...prevState,
            openId: prevState.openId === name ? "" : name
        }));
    }
    // Customizable Area End
}