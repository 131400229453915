import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";

interface myProps {
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999999,
  },
  svg: {
    width: "6em",
    transformOrigin: "center",
    animation: "rotate4 2s linear infinite",
  },
  circle: {
    r: "20",
    cy: "50",
    cx: "50",
    fill: "none",
    stroke: "#0099FF",
    strokeWidth: "3",
    strokeDasharray: "1, 200",
    strokeDashoffset: "0",
    strokeLinecap: "round",
    animation: "dash4 1.5s ease-in-out infinite",
    "@media (max-width:600px)": {
      r: "16",
      strokeWidth: "3",
    }
  },
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.root}
      open={props.loading}
    >
      <svg className={classes.svg} viewBox="25 25 50 50">
        <circle className={classes.circle} />
      </svg>
    </Backdrop>
  );
}
